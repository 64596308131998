import React from 'react';
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'
import {StaticData} from './StaticData'

export class ContactTable extends AuthenticatedTable {

  constructor(props) {
    super(props, 'contact', '/Contacts', 'Contacts')
  }


  // Overrinding getValue to handle optional partnerId
  getValues (first=0, rows=0, sort=null, filter=null, filters=null) {
    if ( this.props.partnerId ) {
      filters = this.defaultFilters (filters, {partner_id: {value : this.props.partnerId}})
    }
    const contacts = super.getValues (first, rows, sort, filter, filters)
    return contacts
  }


  // Overrinding handleCreate to handle optional partnerId
  handleCreate () {
    if ( this.props.partnerId ) {
      super.handleCreate ('/' + this.props.partnerId)
    } else {
      super.handleCreate ()
    }
  }


  doRender() {

    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        onOpen={this.handleOpen}
        onDelete={this.handleDelete}
        onCreate={this.handleCreate}
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column field="name" header="Nom" sortable={true} style={{width:'19%'}} />
        <Column field="partner" header="Partenaire" sortable={true} style={{width:'19%'}} />
        <Column field="phone" header="Téléphone" sortable={true} style={{width:'15%'}} />
        <Column field="email" header="Courriel" sortable={true} style={{width:'15%'}} />
        <Column field="zip" header="CP" sortable={true} style={{width:'5%'}} />
        <Column field="city" header="Ville" sortable={true} style={{width:'18%'}} />
        <Column
          field="type"
          header="type"
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={StaticData.contact_types}
          body = {(rawData, column) => LazyDataTable.listTemplate(rawData, column, StaticData.contact_types)}
          sortable={true}
          style={{width:'8%'}}
        />
      </LazyDataTable>
    )
  }
}

