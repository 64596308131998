import React, {Component} from 'react'
import {Dropdown} from 'primereact/dropdown'
import {Calendar} from 'primereact/calendar'

/*
 * Custom Date Chooser component used by DataTables filters
 */
export class DateChooser extends Component {

  constructor(props) {
    super(props)
    this.state = {
      mode: props.value!==undefined?props.value.mode:'=',
      value: props.value!==undefined?props.value.value:null,
      selectionMode: 'single'
    }
    this.modes = [
      {label: "Exactement à cette date", value:'='},
      {label: "Jusqu'à cette date", value:'<'},
      {label: 'Depuis cette date', value:'>'},
      {label: 'Entre ces deux dates', value:'-'},
    ]
    this.locale = {
      firstDayOfWeek: 1,
      today: "aujourd'hui",
      clear: "Effacer",
      dayNames: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
      dayNamesShort: ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
      dayNamesMin: ["D", "L", "Ma", "Me", "J", "V", "S"],
      monthNames: ["janvier", "fevrier", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
      monthNamesShort: ["jan", "fev", "mar", "avr", "mai", "juin", "juil", "aou", "sep", "oct", "nov", "dec"]
    }
    this.footerTemplate = this.footerTemplate.bind(this)
    this.setMode = this.setMode.bind(this)
    this.setValue = this.setValue.bind(this)
  }

  footerTemplate () {
    return (
        <Dropdown
          style={{width: '100%'}}
          value={this.state.mode}
          options={this.modes}
          onChange={this.setMode}
        />
    )
  }

  setMode (event) {
    const selectionMode = (event.value==='-')?'range':'single'
    this.setState (
      {
        mode: event.value,
        selectionMode: selectionMode
      }
    )
  }

  setValue (event) {
    this.setState ({value: event.value})
    if (this.props.onChange) {
      this.props.onChange(this.state.mode, event.value)
    }
  }

  render() {
    return (
      <Calendar
        style={{width:'100%'}}
        selectionMode={this.state.selectionMode}
        showButtonBar={true}
        footerTemplate={this.footerTemplate}
        value={this.state.value}
        onChange={this.setValue}
        appendTo={document.body}
        locale={this.locale}
        monthNavigator={true}
        yearNavigator={true}
        yearRange="1900:2100"
        dateFormat="dd/mm/yy"
      />
    )
  }
}
