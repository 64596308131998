import React from 'react';
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

export class GroupTable extends AuthenticatedTable {

  constructor(props) {
    super(props, 'group', '/Groupes', 'Groups')
  }

  doRender() {

    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        onOpen={this.handleOpen}
        onDelete={this.handleDelete}
        onCreate={this.handleCreate}
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column field="name" header="Nom" sortable={true} />
      </LazyDataTable>
    )
  }
}

