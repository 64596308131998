import {Gpec} from '../gpec/Gpec'
import {Partners} from '../partners/Partners'
import {Products} from '../products/Products'
import {QueryBuilders} from '../queryBuilders/QueryBuilders'
import {Data} from '../data/Data.js'


export class MenuItems {

  static async registerAppComponents () {

    const show_gpec = this.state.profile.gpec || this.state.profile.groups.includes ('CVSI / RH')

    // Main GPEC board (Employees)
    await this.appRegister (
      {
        menu: '/Emplois et compétences',      // Item menu path
        icon: 'fas fa-fw fa-hands-helping',   // Item menu icon
        route: '/GPEC/Employés',              // Route activating this item
        component: Gpec,                      // Javascript component
        show: () => show_gpec,
        // This is the default page
        default: show_gpec,
      }
    )

    // Query Builder
    await this.appRegister (
      {
        route: '/Requetteur/GpecEmployees',   // Route activating this item
        component: QueryBuilders,             // Javascript component
      }
    )

    // Trainings
    await this.appRegister (
      {
        route: '/GPEC/Formations',
        component: Gpec,
      }
    )

    // Trainings
    await this.appRegister (
      {
        route: '/GPEC/Entretiens',
        component: Gpec,
      }
    )

    // Salary increases
    await this.appRegister (
      {
        route: '/GPEC/Evolution salariale',
        component: Gpec,
      }
    )

    // Professionnal evolution
    await this.appRegister (
      {
        route: '/GPEC/Evolution professionnelle',
        component: Gpec,
      }
    )


    //----------------- PARTNERS -----------------//

    // Partners and contacts
    await this.appRegister (
      {
        menu: "/Carnet d'addresses",
        icon: 'pi pi-fw pi-users',
      },
    )

    // Partners
    await this.appRegister (
      {
        menu: "/Carnet d'addresses/Partenaires",
        icon: 'pi pi-fw pi-angle-right',
        route: '/Partenaires',
        component: Partners,
        // This is the default page if there is no gpec
        default: !show_gpec,
      }
    )

    // Groups
    await this.appRegister (
      {
        menu: "/Carnet d'addresses/Groupes",
        icon: 'pi pi-fw pi-angle-right',
        route: '/Groupes',
        component: Partners,
      }
    )

    // Groups
    await this.appRegister (
      {
        menu: "/Carnet d'addresses/Contacts",
        icon: 'pi pi-fw pi-angle-right',
        route: '/Contacts',
        component: Partners,
      }
    )

    //----------------- PRODUCTS -----------------//

    // Groups
    await this.appRegister (
      {
        menu: "/Produits",
        icon: 'fa fa-fw fa-boxes',
        route: '/Produits',
        component: Products,
      }
    )

    //----------------- DATA -----------------//

    // Data management
    await this.appRegister (
      {
        menu: "/Gestion des données",
        icon: 'fa fa-fw fa-database',
        show: () => (
             this.state.profile.groups.includes ('CVSI / PowerUsers')
          || this.state.profile.groups.includes ('CVSI / RH')
        )
      }
    )

    // Partners and contacts ---------------------------------------------------- //
    await this.appRegister (
      {
        menu: "/Gestion des données/Carnet d'addresses",
        icon: 'pi pi-fw pi-users',
        show: () => this.state.profile.groups.includes ('CVSI / PowerUsers')
      }
    )

    // Partners
    await this.appRegister (
      {
        menu: "/Gestion des données/Carnet d'addresses/Partenaires",
        icon: 'pi pi-fw pi-angle-right',
        route: '/Données/Partenaires',
        component: Data,
        access: () => this.state.profile.groups.includes ('CVSI / PowerUsers')
      }
    )

    // Partners
    await this.appRegister (
      {
        menu: "/Gestion des données/Carnet d'addresses/Groupes",
        icon: 'pi pi-fw pi-angle-right',
        route: '/Données/Groupes',
        component: Data,
        access: () => this.state.profile.groups.includes ('CVSI / PowerUsers')
      }
    )

    // Partners
    await this.appRegister (
      {
        menu: "/Gestion des données/Carnet d'addresses/Contacts",
        icon: 'pi pi-fw pi-angle-right',
        route: '/Données/Contacts',
        component: Data,
        access: () => this.state.profile.groups.includes ('CVSI / PowerUsers')
      }
    )

    // GPEC --------------------------------------------------------------------- //
    await this.appRegister (
      {
        menu: '/Gestion des données/GPEC',
        icon: 'fas fa-fw fa-hands-helping',
      }
    )

    // GPEC employees
    await this.appRegister (
      {
        menu: '/Gestion des données/GPEC/Employés',
        icon: 'pi pi-fw pi-angle-right',
        route: '/Données/Employés',
        component: Data,
        access: () => (
             this.state.profile.groups.includes ('CVSI / PowerUsers')
          || this.state.profile.groups.includes ('CVSI / RH')
        )
      }
    )

    // Positions
    await this.appRegister (
      {
        menu: '/Gestion des données/GPEC/Postes',
        icon: 'pi pi-fw pi-angle-right',
        route: '/Données/Postes',
        component: Data,
        access: () => (
             this.state.profile.groups.includes ('CVSI / PowerUsers')
          || this.state.profile.groups.includes ('CVSI / RH')
        )
      }
    )

    // Training
    await this.appRegister (
      {
        menu: '/Gestion des données/GPEC/Formations',
        icon: 'pi pi-fw pi-angle-right',
        route: '/Données/Formations',
        component: Data,
        access: () => (
             this.state.profile.groups.includes ('CVSI / PowerUsers')
          || this.state.profile.groups.includes ('CVSI / RH')
        )
      }
    )

    // Actions
    await this.appRegister (
      {
        menu: '/Gestion des données/GPEC/Actions',
        icon: 'pi pi-fw pi-angle-right',
        route: '/Données/Actions',
        component: Data,
        access: () => (
             this.state.profile.groups.includes ('CVSI / PowerUsers')
          || this.state.profile.groups.includes ('CVSI / RH')
        )
      }
    )

    // Actions
    await this.appRegister (
      {
        menu: "/Gestion des données/GPEC/Accompagnements",
        icon: 'pi pi-fw pi-angle-right',
        route: '/Données/Accompagnements',
        component: Data,
        access: () => (
             this.state.profile.groups.includes ('CVSI / PowerUsers')
          || this.state.profile.groups.includes ('CVSI / RH')
        )
      }
    )

    // Levels
    await this.appRegister (
      {
        menu: '/Gestion des données/GPEC/Niveaux',
        icon: 'pi pi-fw pi-angle-right',
        route: '/Données/Niveaux',
        component: Data,
        access: () => (
             this.state.profile.groups.includes ('CVSI / PowerUsers')
          || this.state.profile.groups.includes ('CVSI / RH')
        )
      }
    )

    // Degrees
    await this.appRegister (
      {
        menu: '/Gestion des données/GPEC/Diplômes',
        icon: 'pi pi-fw pi-angle-right',
        route: '/Données/Diplômes',
        component: Data,
        access: () => (
             this.state.profile.groups.includes ('CVSI / PowerUsers')
          || this.state.profile.groups.includes ('CVSI / RH')
        )
      }
    )

    return true

  }
}

