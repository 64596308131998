import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {InputText} from 'primereact/inputtext'
import '../../components/Forms.css'

class ContactAdmin extends AuthenticatedForm {

  constructor (props) {
    super (props, 'contactAdmin', '/Données/Contacts', 'Contacts',

      {
        contactAdmin: {
          id: props.match.params.id,
          name: "",
          web_id: "",
        },
      }
    )
  }


  doRender () {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Fiche contact (Gestion technique)</h1>
                  <FormContainer
                    values={this.state.contactAdmin}
                    onEdit={this.handleEdit}
                    onUpdate={this.handleUpdate}
                    onExit={this.handleExit}
                    setValues={this.setValues}
                    getValues={this.getValues}
                    browser={this.state.browser}
                    onBrowse={this.handleBrowse}
                  >

                  <div className="form-new-line p-grid">

                    <div className="p-grid p-col-12 p-md-6">
                      <div className="p-col-12">
                        <label htmlFor="name">Nom</label>
                      </div>
                      <div className="p-col-12">
                        <InputText
                          className="form-input"
                          autoComplete="off"
                          disabled={true}
                          id="name"
                          value={this.state.contactAdmin.name}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                    <div className="p-grid p-col-12 p-md-5">
                      <div className="p-col-12">
                        <label htmlFor="web_id">Identifiant Web (sites E-Commerce)</label>
                      </div>
                      <div className="p-col-12">
                        <InputText
                          className="form-input"
                          autoComplete="off"
                          disabled={!this.state.editMode}
                          id="web_id"
                          value={this.state.contactAdmin.web_id}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                  </div>

                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactAdmin
