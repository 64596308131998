import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import ProductList from './ProductList.js'
import ProductForm from './ProductForm.js'

export class Products extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Produits' component={ProductList} />
        <Route path='/Produits/:id' component={ProductForm} />
      </Switch>
    )
  }
}
