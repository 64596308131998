import React from 'react';
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

export class GpecEmployeeTable extends AuthenticatedTable {

  constructor(props) {
    super(props, 'gpecEmployee', '/GPEC/Employés', 'GpecEmployees',
      {
        role_list: [],
      }
    )
  }

  async getExternalValues () {
    const roles = await this.getOptionList (null, 'GpecPositions')

    this.setState (
      {
        role_list: roles.values.map (r => {return {label: r.name, value: r.id, ...r}}),
      }
    )
  }

  setCustomFilters (filters) {
    if ( ! this.state.profile.groups.includes ('CVSI / RH') ) {
      return this.defaultFilters (filters, {employee_id: {value : this.state.profile.id}})
    }
  }



  doRender () {

    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        onOpen={this.handleOpen}
        onDelete={this.state.profile.groups.includes ('CVSI / RH') ? this.handleDelete : null}
        onCreate={this.state.profile.groups.includes ('CVSI / RH') ? this.handleCreate : null}
        onQueryBuilder={this.state.profile.groups.includes ('CVSI / RH') ? this.handleQueryBuilder : null}
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column field="name" header="Nom" sortable={true} style={{width:'35%'}} />
        <Column
          field="role"
          header="Poste"
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.role_list}
          sortable={true}
          style={{width:'10%'}}
        />
        <Column field="date_from"
          header="Entré le"
          filterMethod={LazyDataTable.dateFilterTZ}
          sortable={true}
          style={{width:'13%'}}
          body={LazyDataTable.dateTemplate}
        />
      </LazyDataTable>
    )
  }

}

