import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import GpecEmployeeList from './GpecEmployeeList.js'
import GpecEmployeeForm from './GpecEmployeeForm.js'
import GpecTrainingForm from './GpecTrainingForm.js'
import GpecMeetingForm from './GpecMeetingForm.js'
import GpecSalaryIncreaseForm from './GpecSalaryIncreaseForm.js'
import GpecProfessionalEvolutionForm from './GpecProfessionalEvolutionForm.js'

export class Gpec extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/GPEC/Employés' component={GpecEmployeeList} />
        <Route path='/GPEC/Employés/:id' component={GpecEmployeeForm} />
        <Route path='/GPEC/Formations/:id/:state?/:employee_id?/:employee_name?' component={GpecTrainingForm} />
        <Route path='/GPEC/Entretiens/:id/:employee_id?/:employee_name?' component={GpecMeetingForm} />
        <Route path='/GPEC/Evolution salariale/:id/:employee_id?/:employee_name?' component={GpecSalaryIncreaseForm} />
        <Route path='/GPEC/Evolution professionnelle/:id/:employee_id?/:employee_name?' component={GpecProfessionalEvolutionForm} />
      </Switch>
    )
  }
}
