import React from 'react'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import classNames from 'classnames'

export class AppInlineProfile extends AuthenticatedComponent {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      expanded: false,
      profile: {},
      profileVersion: null,
      warning: false,
      warningMessage: ""
    }

    this.handleMenu = this.handleMenu.bind(this)
    this.handleLock = this.handleLock.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  handleMenu(event) {
    event && event.preventDefault()
    this.setState({expanded: !this.state.expanded})
  }

  handleLock(event = null) {
    event && event.preventDefault()
    this.handleMenu ()
    this.context.onTokenTimeout ()
  }

  handleLogout(event) {
    if (this.context.lockNavigation) {
      this.props.onWarning (this.context.lockNavigationMessage)
    } else {
      this.context.logout()
      event && event.stopPropagation()
    }
  }


  doRender() {
    return  (
      <div className="profile">
        <button className="p-link profile-link" onClick={this.handleMenu}>
          <span className="username">{this.state.profile.username}</span>
          <i className="pi pi-fw pi-cog"/>
        </button>
        <ul className={classNames({'profile-expanded': this.state.expanded})}>
          <li>
            <button className="p-link profile-link" onClick={this.handleLock}>
              <i className="pi pi-fw pi-lock"/><span>Vérrouiller la session</span>
            </button>
          </li>
          <li>
            <button className="p-link profile-link" onClick={this.handleLogout}>
              <i className="pi pi-fw pi-power-off"/><span>Déconnexion</span>
            </button>
          </li>
        </ul>
      </div>
    )
  }
}
