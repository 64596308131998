import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {GpecTrainingTable} from './GpecTrainingTable'
import {GpecMeetingTable} from './GpecMeetingTable'
import {GpecSalaryIncreaseTable} from './GpecSalaryIncreaseTable'
import {GpecProfessionalEvolutionTable} from './GpecProfessionalEvolutionTable'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {Display} from '../../components/Display'
import {Attachments} from '../../components/Attachments'
import {TabView,TabPanel} from 'primereact/tabview'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {Dropdown} from 'primereact/dropdown'
import {Calendar} from 'primereact/calendar'
import '../../components/Forms.css'

class GpecEmployeeForm extends AuthenticatedForm {

  constructor (props) {
    super(props, 'gpecEmployee', '/GPEC/Employés', 'GpecEmployees',
      {
        gpecEmployee: {
          id: props.match.params.id,
          name: "",
          first_name: "",
          last_name: "",
          date_from: new Date (),
          user_id: 0,
          user: "",
          role_id: 0,
          role: "",
          salary: "",
          hours: "",
          hourly_salary_rate: "",
          coefficient: "",
          position: "",
          done_trainings_comments: "",
          meetings_comments: "",
          pending_trainings_comments: "",
          scheduled_trainings_comments: "",
          salary_increases_comments: "",
          professional_evolution_comments: "",
          miscellaneous: "",


        },

        mandatoryFields: {
          first_name: {test: FormContainer.checkNotEmpty, state: false},
          last_name: {test: FormContainer.checkNotEmpty, state: false},
          salary: {test: t => FormContainer.checkEuro (t, true), state: false},
          hours: {test: t => FormContainer.checkNumber (t, true), state: false},
          coefficient: {test: t => FormContainer.checkInteger (t, true), state: false},
        },

        role_list : [],
        user_list: [],
      }
    )
    this.handleHourlySalaryRate = this.handleHourlySalaryRate.bind(this)
  }

  /*
   * Adjust server values
   * - Dates are encoded as an ISO 8601 string then converted to date object
   * - Salaries are transmited as real numbers without any currency format
   */
  cleanUpValues (values) {
    if ( values.date_from ) {
      values.date_from = new Date (values.date_from)
    }

    const salaryFormatter = new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 0, style: 'currency', currency: 'EUR' })
    const salary = parseFloat(String(values.salary).replace(',','.').replace(/[\s€]/g,''))
    values.salary = salaryFormatter.format(isNaN(salary)?0.0:salary)
    const hourlyFormatter = new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'EUR' })
    const hourly_salary_rate = parseFloat(String(values.hourly_salary_rate).replace(',','.').replace(/[\s€]/g,''))
    values.hourly_salary_rate = hourlyFormatter.format(isNaN(hourly_salary_rate)?0.0:hourly_salary_rate)
  }


  /*
   * Get external values :
   *  - Position list
   */

  async getExternalValues () {
    const roles = await this.apiCall ('GpecPositions', 'list')
    const users = await this.apiCall ('ResUsers', 'list')
    await this.setState (
      {
        role_list: roles.values,
        user_list: users.values,
      }
    )
  }

  handleHourlySalaryRate (e) {
    const f = e.target.id
    const v = e.target.value
    this.handleInputText (e)

    const salary = String( f === 'salary' ? v : this.state.gpecEmployee.salary).replace(',','.').replace(/[\s€]/g,'')
    const hours = String( f === 'hours' ? v : this.state.gpecEmployee.hours).replace(',','.').replace(/[\s€]/g,'')

    this.setState (
      prevState => {
        if ( hours && parseFloat (hours) && salary && !isNaN (hours) && !isNaN (salary) ) {
          const formatter = new Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'EUR' })
          prevState.gpecEmployee.hourly_salary_rate = formatter.format (salary / hours)
        } else {
          prevState.gpecEmployee.hourly_salary_rate = ""
        }
        return (
          {gpecSalaryIncrease: prevState.gpecEmployee}
        )
      }
    )
  }


  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Fiche Employé {this.state.gpecEmployee.first_name} {this.state.gpecEmployee.last_name}</h1>
                  <FormContainer
                    values={this.state.gpecEmployee}
                    locked={!this.state.profile.groups.includes('CVSI / RH')}
                    mandatoryFields={this.state.mandatoryFields}
                    onEdit={this.handleEdit}
                    onUpdate={this.handleUpdate}
                    onExit={this.handleExit}
                    setValues={this.setValues}
                    getValues={this.getValues}
                    browser={this.state.browser}
                    onBrowse={this.handleBrowse}
                  >
                    <div className="p-grid">

                      <TabView>

                        <TabPanel header="Informations" rightIcon="pi pi-info">

                          {/*
                             -----------------------------------------------------------------------

                                                  MAIN INFORMATIONS PANEL
                                                     DISPLAY ONLY MODE

                             ------------------------------------------------------------------------
                          */}

                          <Display if={!this.state.editMode && this.state.gpecEmployee.id !== '0'}>
                            <div className="p-col-12 p-grid">
                              <div className="p-col-12 p-md-3 form-text-align-md-center">
                                <b>Entré le :</b> {this.state.gpecEmployee.date_from && this.state.gpecEmployee.date_from.toLocaleDateString("fr-FR")}
                              </div>
                              <div className="p-col-12 p-md-3 form-text-align-md-center">
                                <b>Poste :</b> {this.state.gpecEmployee.role}
                              </div>
                              <div className="p-col-12 p-md-3 form-text-align-md-center">
                                <b>salaire :</b> {this.state.gpecEmployee.salary}
                                <b> / heures :</b> {this.state.gpecEmployee.hours} ({this.state.gpecEmployee.hourly_salary_rate} / heure)
                              </div>
                              <div className="p-col-12 p-md-3 form-text-align-md-center">
                                <b>coefficient :</b> {this.state.gpecEmployee.coefficient}<b> /  position :</b> {this.state.gpecEmployee.position}
                              </div>
                            </div>

                            <div className="p-col-12 p-grid p-align-start">

                              <div className="p-grid p-col-12 p-md-6">
                                <div className="p-col-12">
                                  Formations effectuées
                                </div>
                                <div className="p-col-12">
                                  <GpecTrainingTable
                                    employeeId={this.state.gpecEmployee.id}
                                    employeeName={this.state.gpecEmployee.name}
                                    state='done'
                                    locked={this.state.editMode}
                                    getContext={this.getContext}
                                  />
                                </div>
                              </div>

                              <div className="p-grid p-col-12 p-md-6">
                                <div className="p-col-12">
                                  Entretiens
                                </div>
                                <div className="p-col-12">
                                  <GpecMeetingTable
                                    employeeId={this.state.gpecEmployee.id}
                                    employeeName={this.state.gpecEmployee.name}
                                    locked={this.state.editMode}
                                    getContext={this.getContext}
                                  />
                                </div>
                              </div>

                              <div className="p-grid p-col-12 p-md-6">
                                <div className="p-col-12">
                                  Formations à prévoir
                                </div>
                                <div className="p-col-12">
                                  <GpecTrainingTable
                                    employeeId={this.state.gpecEmployee.id}
                                    employeeName={this.state.gpecEmployee.name}
                                    state='pending'
                                    locked={this.state.editMode}
                                    getContext={this.getContext}
                                  />
                                </div>
                              </div>

                              <div className="p-grid p-col-12 p-md-6">
                                <div className="p-col-12">
                                  Formations planifiées
                                </div>
                                <div className="p-col-12">
                                  <GpecTrainingTable
                                    employeeId={this.state.gpecEmployee.id}
                                    employeeName={this.state.gpecEmployee.name}
                                    state='scheduled'
                                    locked={this.state.editMode}
                                    getContext={this.getContext}
                                  />
                                </div>
                              </div>

                              <div className="p-grid p-col-12 p-md-6">
                                <div className="p-col-12">
                                  Evolution salariale
                                </div>
                                <div className="p-col-12">
                                  <GpecSalaryIncreaseTable
                                    employeeId={this.state.gpecEmployee.id}
                                    employeeName={this.state.gpecEmployee.name}
                                    locked={this.state.editMode}
                                    getContext={this.getContext}
                                  />
                                </div>
                              </div>

                              <div className="p-grid p-col-12 p-md-6">
                                <div className="p-col-12">
                                  Evolution Professionnelle
                                </div>
                                <div className="p-col-12">
                                  <GpecProfessionalEvolutionTable
                                    employeeId={this.state.gpecEmployee.id}
                                    employeeName={this.state.gpecEmployee.name}
                                    locked={this.state.editMode}
                                    getContext={this.getContext}
                                  />
                                </div>
                              </div>

                            </div>

                          </Display>

                          {/*
                             -----------------------------------------------------------------------

                                                  MAIN INFORMATIONS PANEL
                                                     MODIFICATION MODE

                             ------------------------------------------------------------------------
                          */}

                          <Display if={this.state.editMode}>

                            <div className="p-col-12 p-grid p-align-start">

                              <div className="p-grid p-col-12 p-md-8">
                                <div className="p-col-12">
                                  <MandatoryLabel htmlFor="first_name" isMissing={this.state.mandatoryFields.first_name.state}>Prénom</MandatoryLabel>
                                </div>
                                <div className="p-col-12 p-md-6">
                                  <InputText
                                    className="form-input"
                                    id="first_name"
                                    autoFocus={true}
                                    value={this.state.gpecEmployee.first_name}
                                    onChange={this.handleInputText}
                                    onBlur={this.cleanUpFirstName}
                                  />
                                </div>
                              </div>

                              <div className="p-grid p-col-12 p-md-8">
                                <div className="p-col-12">
                                  <MandatoryLabel htmlFor="last_name" isMissing={this.state.mandatoryFields.last_name.state}>Nom</MandatoryLabel>
                                </div>
                                <div className="p-col-12 p-md-6">
                                  <InputText
                                    className="form-input"
                                    id="last_name"
                                    value={this.state.gpecEmployee.last_name}
                                    onChange={this.handleInputText}
                                    onBlur={this.cleanUpLastName}
                                  />
                                </div>
                              </div>

                              <div className="p-grid p-col-12 p-md-8">
                                <div className="p-col-12">
                                  <label htmlFor="user">Utilisateur ERP</label>
                                </div>
                                <div className="p-col-12 p-md-6">
                                  <Dropdown
                                    className="form-input"
                                    disabled={!this.state.editMode}
                                    id="user"
                                    options={this.state.user_list.map (u => ({label: u.name, value: u.id}))}
                                    value={this.state.gpecEmployee.user_id}
                                    onChange={this.handleExternalSelect}
                                    autoWidth={false}
                                  />
                                </div>
                              </div>

                              <div className="p-grid p-col-12 p-md-5">
                                <div className="p-col-12">
                                  <label htmlFor="role">Poste</label>
                                </div>
                                <div className="p-col-12">
                                  <Dropdown
                                    className="form-input"
                                    disabled={!this.state.editMode}
                                    id="role"
                                    options={this.state.role_list.map (p => ({label: p.name, value: p.id}))}
                                    value={this.state.gpecEmployee.role_id}
                                    onChange={this.handleExternalSelect}
                                    autoWidth={false}
                                  />
                                </div>
                              </div>

                              <div className="p-grid p-col-12 p-md-5">
                                <div className="p-col-12">
                                  <label htmlFor="date_from">Date d'entrée</label>
                                </div>
                                <div className="p-col-12">
                                  <Calendar
                                    className="form-input"
                                    disabled={!this.state.editMode}
                                    id="date_from"
                                    value={this.state.gpecEmployee.date_from}
                                    onChange={this.handleCalendar}
                                    placeHolder=""
                                    showIcon={true}
                                    showButtonBar={true}
                                    clearButtonStyleClass="form-hidden"
                                    readOnlyInput={true}
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange="1900:2100"
                                    dateFormat="dd/mm/yy"
                                    locale={FormContainer.localDate('fr')}
                                  ></Calendar>
                                </div>
                              </div>

                              <div className="p-col-12 p-grid p-md-5">
                                <div className="p-col-12">
                                  <MandatoryLabel
                                    htmlFor="coefficient"
                                    isMissing={this.state.mandatoryFields.coefficient.state}
                                    reminder=""
                                  >
                                    Coefficient
                                  </MandatoryLabel>
                                </div>
                                <div className="p-col-12">
                                  <InputText
                                    className="form-input"
                                    id="coefficient"
                                    disabled={!this.state.editMode}
                                    value={this.state.gpecEmployee.coefficient}
                                    onChange={this.handleInputText}
                                    onBlur={this.cleanUpNumber}
                                  />
                                </div>
                              </div>

                              <div className="p-col-12 p-grid p-md-5">
                                <div className="p-col-12">
                                  <label htmlFor="position">Position</label>
                                </div>
                                <div className="p-col-12">
                                  <InputText
                                    className="form-input"
                                    id="position"
                                    disabled={!this.state.editMode}
                                    value={this.state.gpecEmployee.position}
                                    onChange={this.handleInputText}
                                  />
                                </div>
                              </div>

                              <div className="p-col-12 p-grid p-md-4">
                                <div className="p-col-12">
                                  <MandatoryLabel
                                    htmlFor="salary"
                                    isMissing={this.state.mandatoryFields.salary.state}
                                    reminder=""
                                  >
                                    Salaire
                                  </MandatoryLabel>
                                </div>
                                <div className="p-col-12">
                                  <InputText
                                    className="form-input"
                                    id="salary"
                                    disabled={!this.state.editMode}
                                    value={this.state.gpecEmployee.salary}
                                    onChange={this.handleHourlySalaryRate}
                                    onBlur={this.cleanUpEuroSalary}
                                  />
                                </div>
                              </div>

                              <div className="p-col-12 p-grid p-md-4">
                                <div className="p-col-12">
                                  <MandatoryLabel
                                    htmlFor="hours"
                                    isMissing={this.state.mandatoryFields.hours.state}
                                    reminder=""
                                  >
                                    Nombre d'heures
                                  </MandatoryLabel>
                                </div>
                                <div className="p-col-12">
                                  <InputText
                                    className="form-input"
                                    id="hours"
                                    disabled={!this.state.editMode}
                                    value={this.state.gpecEmployee.hours}
                                    onChange={this.handleHourlySalaryRate}
                                    onBlur={this.cleanUpNumber}
                                  />
                                </div>
                              </div>

                              <div className="p-col-12 p-grid p-md-4">
                                <div className="p-col-12">
                                  <label htmlFor="hourly_salary_rate">
                                    Taux horaire
                                  </label>
                                </div>
                                <div className="p-col-12">
                                  <InputText
                                    className="form-input"
                                    id="hourly_salary_rate"
                                    disabled={true}
                                    value={this.state.gpecEmployee.hourly_salary_rate}
                                    onChange={this.handleInputText}
                                  />
                                </div>
                              </div>

                            </div>
                          </Display>

                          <div className="p-grid p-col-12">
                            <div className="p-col-12">
                              <label htmlFor="miscellaneous">Informations diverses</label>
                            </div>
                            <div className="p-col-12">
                              <InputTextarea
                                className="form-input"
                                rows={8}
                                autoComplete="off"
                                disabled={!this.state.editMode}
                                id="miscellaneous"
                                value={this.state.gpecEmployee.miscellaneous}
                                onChange={this.handleInputText}
                              />
                            </div>
                          </div>

                        </TabPanel>

                        <TabPanel header="Commentaires" rightIcon="fas fa-edit">

                          {/*
                             -----------------------------------------------------------------------

                                                      COMMENTS PANEL

                             ------------------------------------------------------------------------
                          */}

                          <div className="p-col-12 p-grid p-align-start">

                            <div className="p-grid p-col-6">
                              <div className="p-col-12">
                                <label htmlFor="done_trainings_comments">Formations effectuées</label>
                              </div>
                              <div className="p-col-12">
                                <InputTextarea
                                  className="form-input"
                                  rows={8}
                                  autoComplete="off"
                                  disabled={!this.state.editMode}
                                  id="done_trainings_comments"
                                  value={this.state.gpecEmployee.done_trainings_comments}
                                  onChange={this.handleInputText}
                                />
                              </div>
                            </div>

                            <div className="p-grid p-col-6">
                              <div className="p-col-12">
                                <label htmlFor="meetings_comments">Entretiens</label>
                              </div>
                              <div className="p-col-12">
                                <InputTextarea
                                  className="form-input"
                                  rows={8}
                                  autoComplete="off"
                                  disabled={!this.state.editMode}
                                  id="meetings_comments"
                                  value={this.state.gpecEmployee.meetings_comments}
                                  onChange={this.handleInputText}
                                />
                              </div>
                            </div>

                            <div className="p-grid p-col-6">
                              <div className="p-col-12">
                                <label htmlFor="pending_trainings_comments">Formations à prévoir</label>
                              </div>
                              <div className="p-col-12">
                                <InputTextarea
                                  className="form-input"
                                  rows={8}
                                  autoComplete="off"
                                  disabled={!this.state.editMode}
                                  id="pending_trainings_comments"
                                  value={this.state.gpecEmployee.pending_trainings_comments}
                                  onChange={this.handleInputText}
                                />
                              </div>
                            </div>

                            <div className="p-grid p-col-6">
                              <div className="p-col-12">
                                <label htmlFor="scheduled_trainings_comments">Formations planifiées</label>
                              </div>
                              <div className="p-col-12">
                                <InputTextarea
                                  className="form-input"
                                  rows={8}
                                  autoComplete="off"
                                  disabled={!this.state.editMode}
                                  id="scheduled_trainings_comments"
                                  value={this.state.gpecEmployee.scheduled_trainings_comments}
                                  onChange={this.handleInputText}
                                />
                              </div>
                            </div>

                            <div className="p-grid p-col-6">
                              <div className="p-col-12">
                                <label htmlFor="salary_increases_comments">Evolution salariale</label>
                              </div>
                              <div className="p-col-12">
                                <InputTextarea
                                  className="form-input"
                                  rows={8}
                                  autoComplete="off"
                                  disabled={!this.state.editMode}
                                  id="salary_increases_comments"
                                  value={this.state.gpecEmployee.salary_increases_comments}
                                  onChange={this.handleInputText}
                                />
                              </div>
                            </div>

                            <div className="p-grid p-col-6">
                              <div className="p-col-12">
                                <label htmlFor="professional_evolution_comments">Evolution Professionnelle</label>
                              </div>
                              <div className="p-col-12">
                                <InputTextarea
                                  className="form-input"
                                  rows={8}
                                  autoComplete="off"
                                  disabled={!this.state.editMode}
                                  id="professional_evolution_comments"
                                  value={this.state.gpecEmployee.professional_evolution_comments}
                                  onChange={this.handleInputText}
                                />
                              </div>
                            </div>

                          </div>

                        </TabPanel>

                        <TabPanel header="Pièces jointes" rightIcon="pi pi-paperclip">

                          {/*
                             -----------------------------------------------------------------------

                                                     ATTACHMENTS PANEL

                             ------------------------------------------------------------------------
                          */}

                          <div className="p-col-12 p-grid p-align-start">

                            <div className="p-grid p-col-6">
                              <div className="p-col-12">
                                <label htmlFor="attachments">Formations :</label>
                              </div>
                              <div className="p-col-12">
                                <Attachments
                                  id="attachments"
                                  parentService="GpecEmployees"
                                  parentOptions="Formation"
                                  rows={5}
                                  locked={this.state.editMode}
                                  readOnly={!this.state.profile.groups.includes('CVSI / RH')}
                                  getValues={this.getAttachmentsValuesFromObject}
                                  getVersions={this.getAttachmentVersions}
                                  setVersions={this.setAttachmentVersions}
                                  delete={this.deleteAttachment}
                                  doUpload={this.fileUpload}
                                  doDownload={this.fileDownload}
                                  watch={this.state.profile}
                                >
                                </Attachments>
                              </div>
                            </div>

                            <div className="p-grid p-col-6">
                              <div className="p-col-12">
                                <label htmlFor="attachments">Entretiens :</label>
                              </div>
                              <div className="p-col-12">
                                <Attachments
                                  id="attachments"
                                  parentService="GpecEmployees"
                                  parentOptions="Entretien"
                                  rows={5}
                                  locked={this.state.editMode}
                                  readOnly={!this.state.profile.groups.includes('CVSI / RH')}
                                  getValues={this.getAttachmentsValuesFromObject}
                                  getVersions={this.getAttachmentVersions}
                                  setVersions={this.setAttachmentVersions}
                                  delete={this.deleteAttachment}
                                  doUpload={this.fileUpload}
                                  doDownload={this.fileDownload}
                                  watch={this.state.profile}
                                >
                                </Attachments>
                              </div>
                            </div>

                            <div className="p-grid p-col-6">
                              <div className="p-col-12">
                                <label htmlFor="attachments">Evolution salariale :</label>
                              </div>
                              <div className="p-col-12">
                                <Attachments
                                  id="attachments"
                                  parentService="GpecEmployees"
                                  parentOptions="Evolution salariale"
                                  rows={5}
                                  locked={this.state.editMode}
                                  readOnly={!this.state.profile.groups.includes('CVSI / RH')}
                                  getValues={this.getAttachmentsValuesFromObject}
                                  getVersions={this.getAttachmentVersions}
                                  setVersions={this.setAttachmentVersions}
                                  delete={this.deleteAttachment}
                                  doUpload={this.fileUpload}
                                  doDownload={this.fileDownload}
                                  watch={this.state.profile}
                                >
                                </Attachments>
                              </div>
                            </div>

                            <div className="p-grid p-col-6">
                              <div className="p-col-12">
                                <label htmlFor="attachments">Evolution Professionnelle :</label>
                              </div>
                              <div className="p-col-12">
                                <Attachments
                                  id="attachments"
                                  parentService="GpecEmployees"
                                  parentOptions="Evolution Professionnelle"
                                  rows={5}
                                  locked={this.state.editMode}
                                  readOnly={!this.state.profile.groups.includes('CVSI / RH')}
                                  getValues={this.getAttachmentsValuesFromObject}
                                  getVersions={this.getAttachmentVersions}
                                  setVersions={this.setAttachmentVersions}
                                  delete={this.deleteAttachment}
                                  doUpload={this.fileUpload}
                                  doDownload={this.fileDownload}
                                  watch={this.state.profile}
                                >
                                </Attachments>
                              </div>
                            </div>

                            <div className="p-grid p-col-12">
                              <div className="p-col-12">
                                <label htmlFor="attachments">Documents de l'employé :</label>
                              </div>
                              <div className="p-col-12">
                                <Attachments
                                  id="attachments"
                                  parentService="GpecEmployees"
                                  parentOptions="Documents"
                                  locked={this.state.editMode}
                                  readOnly={!this.state.profile.groups.includes('CVSI / RH')}
                                  getValues={this.getAttachmentsValuesFromObject}
                                  getVersions={this.getAttachmentVersions}
                                  setVersions={this.setAttachmentVersions}
                                  delete={this.deleteAttachment}
                                  doUpload={this.fileUpload}
                                  doDownload={this.fileDownload}
                                  watch={this.state.profile}
                                >
                                </Attachments>
                              </div>
                            </div>

                          </div>

                        </TabPanel>

                      </TabView>

                    </div>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GpecEmployeeForm
