import React, {Component} from 'react'
import {CSVLink} from "react-csv"

export class CSVDownloader extends Component {

  /*
   * CSVDownloader exports data to a CSV file to be downloaded
   *
   * It uses CSVLink on which asyncOnClick seems to be broken
   * so it rely on states and props changes to trigger download
   * after data are ready
   *
   * Each time props.data changes, an export sequance is initiated
   * by clicking the link via it's reference
   *
   * Two state's transitoin are used to let component be updated internally
   */

  constructor(props) {
    super(props)
    this.state = {
      trigger: false
    }
  }


  componentDidUpdate (prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      // Has new data
      this.setState ({trigger: true})
    }
    if ( this.state.trigger ) {
      // Click it to start the download
      this.setState ({trigger: false}, () => {this.refs.csv.link.click()})
    }
  }

  /*
   * Main rendering
   */
  render() {
    return (
      <CSVLink
        ref="csv"
        separator={this.state.separator || ";"}
        enclosingCharacter={this.state.enclosingCharacter || '"'}
        filename={this.props.filename || "export.csv"}
        data={this.props.data}
        headers={this.props.headers}
        style={{display:'none'}}
      >
      </CSVLink>
    )
  }

}
