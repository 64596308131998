import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {ContactTable} from './ContactTable'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {TabView,TabPanel} from 'primereact/tabview'
import {Attachments} from '../../components/Attachments'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {Checkbox} from 'primereact/checkbox'
import {AutoComplete} from 'primereact/autocomplete'
import {Dropdown} from 'primereact/dropdown'
import '../../components/Forms.css'

class PartnerForm extends AuthenticatedForm {

  constructor (props) {
    super (props, 'partner', '/Partenaires/', 'Partners',

      {
        activeTab: 'Informations',

        partner: {
          id: props.match.params.id,
          name: "",
          ref: "",
          customer: 'f',
          supplier: 'f',
          group_id: 0,
          group: "",
          seller_id: 0,
          seller: "",
          team_id: 0,
          team: "",
          sales_pricelist_id: 0,
          purchases_pricelist_id: 0,
          customer_account_id: 389,
          customer_account: "411100000 Clients - Ventes de biens ou de prestations de services",
          supplier_account_id: 368,
          supplier_account: "401100000 Fournisseurs - Achats de biens et prestations de services",
          fiscal_position_id: "",
          vat_subjected: 'f',
          vat: "",
          payment_term_id: 0,
          hide_iban: 'f',
          no_ub: 'f',
        },

        mainContact: null,

        autoCompleteFields: {
          group: {
            service: 'Groups',
            suggestions: null,
            data: null,
          },
          seller: {
            service: 'Sellers',
            suggestions: null,
            data: null,
          },
          customer_account: {
            service: 'Accounts',
            filters: {type : {value : 'receivable'}},
            key: 'fullCodeName',
            suggestions: null,
            data: null,
          },
          supplier_account: {
            service: 'Accounts',
            filters: {type : {value : 'payable'}},
            key: 'fullCodeName',
            suggestions: null,
            data: null,
          },
        },

        mandatoryFields: {
          name: {test: FormContainer.checkNotEmpty, state: false},
          customer_account: {test: FormContainer.checkNotEmpty, state: false},
          supplier_account: {test: FormContainer.checkNotEmpty, state: false},
        },

        sales_pricelist : [],
        purchases_pricelist : [],
        country : [],
        team : [],
        fiscal_position : [],
        payment_term : [],
      }

    )

  }


  /*
   * Form data management
   */

  async getExternalValues () {
    /* Get the first default contacts for this partner */
    const filters = {
      partner_id : {value : this.state.partner.id},
      type : {value : ['default']},
    }
    const contacts = await this.apiCall ( 'Contacts', 'list', 0, 1, false, false, filters)
    if ( contacts.totalRecords > 0 ) {
      const mainContact = await this.apiCall ('Contacts', 'get', contacts.values[0].id)
      const country = await this.apiCall ('Countries', 'list', 0, Number.MAX_SAFE_INTEGER, false, false, false)
      await this.setState (
        {
          mainContact: mainContact,
          country: country.values,
        }
      )
      if (this.state.mainContact !==undefined) {
        await this.handleExternalSelect (
          {
            value: this.state.mainContact.country_id,
            target: {id: 'country'},
          }
        )
      }
    }

    const team = await this.apiCall ('SellersTeams', 'list')
    const sales_pricelist = await this.apiCall ('PriceLists', 'list', 0, Number.MAX_SAFE_INTEGER, false, false, {type:{value: 'sale'}})
    const purchases_pricelist = await this.apiCall ('PriceLists', 'list', 0, Number.MAX_SAFE_INTEGER, false, false, {type:{value: 'purchase'}})
    const fiscal_position = await this.apiCall ('FiscalPositions', 'list')
    const payment_term = await this.apiCall ('PaymentTerms', 'list')
    await this.setState (
        {
          team: team.values,
          sales_pricelist: sales_pricelist.values,
          purchases_pricelist: purchases_pricelist.values,
          fiscal_position: fiscal_position.values,
          payment_term: payment_term.values,
        }
    )
  }


  renderMainContact () {
    if (this.state.mainContact !== null && ! this.state.editMode) {
      return (
        <div className="p-grid p-col-12">

            <div className="p-grid p-col-12 p-justify-between">

              <div className="p-grid p-col-12">
                <div className="p-col-12">
                  <label htmlFor="address">Addresse</label>
                </div>
                <div className="p-col-12">
                  <InputTextarea
                    className="form-input"
                    rows={3}
                    autoResize={true}
                    disabled={true}
                    id="address"
                    value={this.state.mainContact.address}
                    onChange={this.handleInputText}
                  />
                </div>
              </div>

            </div>


            <div className="p-grid p-col-12 p-justify-between">

              <div className="p-grid p-col-12 p-md-2">
                <div className="p-col-12">
                  <label htmlFor="zip">Code postal</label>
                </div>
                <div className="p-col-12">
                  <InputText
                    className="form-input"
                    autoComplete="off"
                    disabled={true}
                    id="zip"
                    value={this.state.mainContact.zip}
                    onChange={this.handleInputText}
                  />
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-5">
                <div className="p-col-12">
                  <label htmlFor="city">Ville</label>
                </div>
                <div className="p-col-12">
                  <InputText
                    className="form-input"
                    autoComplete="off"
                    disabled={true}
                    id="city"
                    value={this.state.mainContact.city}
                    onChange={this.handleInputText}
                  />
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-5">
                <div className="p-col-12">
                  <label htmlFor="country">Pays</label>
                </div>
                <div className="p-col-12">
                  <Dropdown
                    className="form-input"
                    disabled={true}
                    id="country"
                    options={this.state.country.map (s => ({label: s.name, value: s.id}))}
                    value={this.state.mainContact.country_id}
                    onChange={this.handleInputText}
                    autoWidth={false}
                  />
                </div>
              </div>

            </div>

            <div className="p-grid p-col-12 p-justify-between">

              <div className="p-grid p-col-12 p-md-2">
                <div className="p-col-12">
                  <label htmlFor="phone">Téléphone</label>
                </div>
                <div className="p-col-12">
                  <InputText
                    className="form-input"
                    disabled={true}
                    id="phone"
                    value={this.state.mainContact.phone}
                    onChange={this.handleInputText}
                  />
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-2">
                <div className="p-col-12">
                  <label htmlFor="mobile">Mobile</label>
                </div>
                <div className="p-col-12">
                  <InputText
                    className="form-input"
                    disabled={true}
                    id="mobile"
                    value={this.state.mainContact.mobile}
                    onChange={this.handleInputText}
                  />
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-2">
                <div className="p-col-12">
                  <label htmlFor="fax">Fax </label>
                </div>
                <div className="p-col-12">
                  <InputText
                    className="form-input"
                    disabled={true}
                    id="fax"
                    value={this.state.mainContact.fax}
                    onChange={this.handleInputText}
                  />
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-6">
                <div className="p-col-12">
                  <label htmlFor="email">Courriel</label>
                </div>
                <div className="p-col-12">
                  <InputText
                    className="form-input"
                    disabled={true}
                    id="email"
                    value={this.state.mainContact.email}
                    onChange={this.handleInputText}
                  />
                </div>
              </div>

            </div>

        </div >
      )
    }
  }

  renderInfos () {
    return (
        <TabPanel key="1" header="Informations" rightIcon="pi pi-info">
          <div className="form-new-line p-grid">

            <div className="p-grid p-col-12 p-md-8">
              <div className="p-col-12">
                <MandatoryLabel htmlFor="name" isMissing={this.state.mandatoryFields.name.state}>Nom</MandatoryLabel>
              </div>
              <div className="p-col-12">
                <InputText
                  className="form-input"
                  autoComplete="off"
                  disabled={!this.state.editMode}
                  id="name"
                  value={this.state.partner.name}
                  onChange={this.handleInputText}
                  onBlur={this.cleanUpLastName}
                />
              </div>
            </div>

            <div className="p-col-12 p-md-6">
              <Checkbox
              className="form-input"
              disabled={!this.state.editMode}
              inputId="customer"
              value="customer"
              onChange={this.handleCheckBox}
              checked={this.state.partner.customer === 't'}
              />
              <label htmlFor="customer" className="p-checkbox-label">Client</label>
            </div>

            <div className="p-col-12 p-md-6">
              <Checkbox
              className="form-input"
              disabled={!this.state.editMode}
              inputId="supplier"
              value="supplier"
              onChange={this.handleCheckBox}
              checked={this.state.partner.supplier === 't'}
              />
              <label htmlFor="supplier" className="p-checkbox-label">Fournisseur</label>
            </div>

            <div className="p-grid p-col-12 p-md-8">
              <div className="p-col-12">
                <label htmlFor="ref">Référence client</label>
              </div>
              <div className="p-col-12">
                <InputText
                  className="form-input"
                  autoComplete="off"
                  disabled={!this.state.editMode}
                  id="ref"
                  value={this.state.partner.ref}
                  onChange={this.handleInputText}
                />
              </div>
            </div>

            <div className="p-grid p-col-12 p-md-8">
              <div className="p-col-12">
                <label htmlFor="group">Groupe</label>
              </div>
              <div className="p-col-12">
                <AutoComplete
                  className="form-input"
                  placeholder="Entrez quelques lettres du nom du groupe que vous cherchez ..."
                  autoComplete="off"
                  disabled={!this.state.editMode}
                  inputId="group"
                  id="group"
                  value={this.state.partner.group}
                  onChange={this.handleInputText}
                  suggestions={this.state.autoCompleteFields.group.suggestions}
                  completeMethod={FormContainer.getSuggestions.bind (this, 'group')}
                  onFocus={this.saveAutoComplete}
                  onBlur={this.cleanUpAutoComplete}
                  onSelect={this.cleanUpAutoComplete}
                />
              </div>
            </div>

            {this.renderMainContact ()}

          </div>

        </TabPanel>
    )
  }

  renderContacts () {
    return (
      <TabPanel key="2" header="Contacts" rightIcon="pi pi-users">
        <ContactTable partnerId={this.state.partner.id} locked={this.state.editMode} getContext={this.getContext}/>
      </TabPanel>
    )
  }

  renderSales () {
    return (
      <TabPanel key="3" header="Ventes" rightIcon="fas fa-money-bill-alt">
        <div className="form-new-line p-grid">

          <div className="p-grid p-col-12 p-md-8">
            <div className="p-col-12">
              <label htmlFor="team">Equipe Commerciale</label>
            </div>
            <div className="p-col-12">
              <Dropdown
                className="form-input"
                disabled={!this.state.editMode}
                id="team"
                options={this.state.team.map (s => ({label: s.name, value: s.id}))}
                value={this.state.partner.team_id}
                onChange={this.handleExternalSelect}
                showClear={true}
                autoWidth={false}
              />
            </div>
          </div>

          <div className="p-grid p-col-12 p-md-8">
            <div className="p-col-12">
              <label htmlFor="seller">Commercial</label>
            </div>
            <div className="p-col-12">
              <AutoComplete
                className="form-input"
                placeholder="Entrez quelques lettres du nom du commercial que vous cherchez ..."
                autoComplete="off"
                disabled={!this.state.editMode}
                inputId="seller"
                id="seller"
                value={this.state.partner.seller}
                onChange={this.handleInputText}
                suggestions={this.state.autoCompleteFields.seller.suggestions}
                completeMethod={FormContainer.getSuggestions.bind (this, 'seller')}
                onFocus={this.saveAutoComplete}
                onBlur={this.cleanUpAutoComplete}
                onSelect={this.cleanUpAutoComplete}
              />
            </div>
          </div>

          <div className="p-grid p-col-12 p-md-8">
            <div className="p-col-12">
              <label htmlFor="sales_pricelist">Liste de prix</label>
            </div>
            <div className="p-col-12">
              <Dropdown
                className="form-input"
                disabled={!this.state.editMode}
                id="sales_pricelist"
                options={this.state.sales_pricelist.map (s => ({label: s.name, value: s.id}))}
                value={this.state.partner.sales_pricelist_id}
                onChange={this.handleExternalSelect}
                autoWidth={false}
              />
            </div>
          </div>

        </div>
      </TabPanel>
    )
  }

  renderPurchase () {
    return (
      <TabPanel key="4" header="Achats" rightIcon="fa fa-shopping-cart">
        <div className="form-new-line p-grid">

          <div className="p-grid p-col-12 p-md-8">
            <div className="p-col-12">
              <label htmlFor="purchases_pricelist">Liste de prix</label>
            </div>
            <div className="p-col-12">
              <Dropdown
                className="form-input"
                disabled={!this.state.editMode}
                id="purchases_pricelist"
                options={this.state.purchases_pricelist.map (s => ({label: s.name, value: s.id}))}
                value={this.state.partner.purchases_pricelist_id}
                onChange={this.handleExternalSelect}
                autoWidth={false}
              />
            </div>
          </div>

        </div>
      </TabPanel>
    )
  }

  renderAccounting () {
    return (
      <TabPanel key="5" header="Comptabilité" rightIcon="fa fa-shopping-cart">
        <div className="form-new-line p-grid">

          <div className="p-grid p-col-12 p-md-8">
            <div className="p-col-12">
              <MandatoryLabel htmlFor="customer_account" isMissing={this.state.mandatoryFields.customer_account.state}>Compte client</MandatoryLabel>
            </div>
            <div className="p-col-12">
              <AutoComplete
                className="form-input"
                placeholder="Entrez quelques lettres du nom du compte que vous cherchez ..."
                autoComplete="off"
                disabled={!this.state.editMode}
                inputId="customer_account"
                id="customer_account"
                value={this.state.partner.customer_account}
                onChange={this.handleInputText}
                suggestions={this.state.autoCompleteFields.customer_account.suggestions}
                completeMethod={FormContainer.getSuggestions.bind (this, 'customer_account')}
                onFocus={this.saveAutoComplete}
                onBlur={this.cleanUpAutoComplete}
                onSelect={this.cleanUpAutoComplete}
              />
            </div>
          </div>

          <div className="p-grid p-col-12 p-md-8">
            <div className="p-col-12">
              <MandatoryLabel htmlFor="supplier_account" isMissing={this.state.mandatoryFields.supplier_account.state}>Compte fournisseur</MandatoryLabel>
            </div>
            <div className="p-col-12">
              <AutoComplete
                className="form-input"
                placeholder="Entrez quelques lettres du nom du compte que vous cherchez ..."
                autoComplete="off"
                disabled={!this.state.editMode}
                inputId="supplier_account"
                id="supplier_account"
                value={this.state.partner.supplier_account}
                onChange={this.handleInputText}
                suggestions={this.state.autoCompleteFields.supplier_account.suggestions}
                completeMethod={FormContainer.getSuggestions.bind (this, 'supplier_account')}
                onFocus={this.saveAutoComplete}
                onBlur={this.cleanUpAutoComplete}
                onSelect={this.cleanUpAutoComplete}
              />
            </div>
          </div>

          <div className="p-grid p-col-12 p-md-8">
            <div className="p-col-12">
              <label htmlFor="fiscal_position">Domaine fiscal</label>
            </div>
            <div className="p-col-12">
              <Dropdown
                className="form-input"
                disabled={!this.state.editMode}
                id="fiscal_position"
                options={this.state.fiscal_position.map (s => ({label: s.name, value: s.id}))}
                value={this.state.partner.fiscal_position_id}
                onChange={this.handleExternalSelect}
                autoWidth={false}
              />
            </div>
          </div>

          <div className="p-grid p-col-12 p-md-8">
            <div className="p-col-12">
              <Checkbox
              className="form-input"
              disabled={!this.state.editMode}
              inputId="vat_subjected"
              value="vat_subjected"
              onChange={this.handleCheckBox}
              checked={this.state.partner.vat_subjected === 't'}
              />
              <label htmlFor="vat_subjected" className="p-checkbox-label">Déclarer la TVA</label>
            </div>
          </div>

          {this.state.partner.vat_subjected === 't' &&
          <div className="p-grid p-col-12 p-md-8">
            <div className="p-col-12">
              <label htmlFor="vat">Numéro de TVA</label>
            </div>
            <div className="p-col-12">
              <InputText
                className="form-input"
                autoComplete="off"
                disabled={!this.state.editMode}
                id="vat"
                value={this.state.partner.vat}
                onChange={this.handleInputText}
                onBlur={this.cleanUpLastName}
              />
            </div>
          </div>
          }

          <div className="p-grid p-col-12 p-md-8">
            <div className="p-col-12">
              <label htmlFor="payment_term">Conditions de règlement</label>
            </div>
            <div className="p-col-12">
              <Dropdown
                className="form-input"
                disabled={!this.state.editMode}
                id="payment_term"
                options={this.state.payment_term.map (s => ({label: s.name, value: s.id}))}
                value={this.state.partner.payment_term_id}
                onChange={this.handleExternalSelect}
                showClear={true}
                autoWidth={false}
              />
            </div>
          </div>

          <div className="p-grid p-col-12 p-md-8">
            <div className="p-col-12">
              <Checkbox
              className="form-input"
              disabled={!this.state.editMode}
              inputId="hide_iban"
              value="hide_iban"
              onChange={this.handleCheckBox}
              checked={this.state.partner.hide_iban === 't'}
              />
              <label htmlFor="hide_iban" className="p-checkbox-label">Masquer les coordonnées bancaires</label>
            </div>
          </div>

          <div className="p-grid p-col-12 p-md-8">
            <div className="p-col-12">
              <Checkbox
              className="form-input"
              disabled={!this.state.editMode}
              inputId="no_ub"
              value="no_ub"
              onChange={this.handleCheckBox}
              checked={this.state.partner.no_ub === 't'}
              />
              <label htmlFor="no_ub" className="p-checkbox-label">Ne pas calculer les UBs</label>
            </div>
          </div>

        </div>
      </TabPanel>
    )
  }

  renderAttachments () {
    return (
      <TabPanel key='6' header="Pièces jointes" rightIcon="pi pi-paperclip">
        <div className="p-col-12 p-grid p-align-start">
          <div className="p-col-12">
            <Attachments
              id="attachments"
              parentService="Partners"
              locked={this.state.editMode}
              getValues={this.getAttachmentsValuesFromObject}
              getVersions={this.getAttachmentVersions}
              setVersions={this.setAttachmentVersions}
              delete={this.deleteAttachment}
              doUpload={this.fileUpload}
              doDownload={this.fileDownload}
              watch={this.state.profile}
            >
            </Attachments>
          </div>
        </div>
      </TabPanel>
    )
  }


  doRender() {

    const userProfile = this.context.getProfile ()
    if ( userProfile === undefined || ! userProfile ) {
      return (<div></div>)
    }

    let activeIndex = 0
    const tabPanels = []
    tabPanels.push (this.renderInfos ())
    if (this.state.activeTab === 'Informations') activeIndex = tabPanels.length - 1
    tabPanels.push (this.renderContacts ())
    if (this.state.activeTab === 'Contacts') activeIndex = tabPanels.length - 1
    if ( userProfile.groups.includes ('CVSI / Commerciaux') && this.state.partner.customer === 't' ) {
      tabPanels.push (this.renderSales ())
      if (this.state.activeTab === 'Ventes') activeIndex = tabPanels.length - 1
    } else {
      if (this.state.activeTab === 'Ventes') activeIndex = 0
    }
    if ( userProfile.groups.includes ('CVSI / Acheteurs') && this.state.partner.supplier === 't' ) {
      tabPanels.push (this.renderPurchase ())
      if (this.state.activeTab === 'Achats') activeIndex = tabPanels.length - 1
    } else {
      if (this.state.activeTab === 'Achats') activeIndex = 0
    }
    if ( userProfile.groups.includes ('CVSI / Comptables') ) {
      tabPanels.push (this.renderAccounting ())
      if (this.state.activeTab === 'Comptabilité') activeIndex = tabPanels.length - 1
    } else {
      if (this.state.activeTab === 'Comptabilité') activeIndex = 0
    }
    tabPanels.push (this.renderAttachments ())
    if (this.state.activeTab === 'Pièces jointes') activeIndex = tabPanels.length - 1

    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Fiche partenaire ({this.state.partner.name})</h1>
                  <FormContainer
                    values={this.state.partner}
                    mandatoryFields={this.state.mandatoryFields}
                    onEdit={this.handleEdit}
                    onUpdate={this.handleUpdate}
                    onExit={this.handleExit}
                    setValues={this.setValues}
                    getValues={this.getValues}
                    browser={this.state.browser}
                    onBrowse={this.handleBrowse}
                  >

                  <TabView
                    activeIndex={activeIndex}
                    onTabChange={this.handleTabChange}
                  >

                    {tabPanels}


                  </TabView>

                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PartnerForm
