import React, {Component} from 'react'
import {DataFiles} from '../helpers/DataFiles'
import {DataView} from 'primereact/dataview'
import {Dialog} from 'primereact/dialog'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {SplitButton} from 'primereact/splitbutton'
import {ProgressBar} from 'primereact/progressbar'
import './Attachments.css'

export class Attachments extends Component {

  constructor(props) {
    super(props)

    this.state = {
      dataTableValue: [],

      searchMode: false,

      rowPerPageOptions: [],
      isLoading: true,

      filter: '',
      sort: { sortField: null, sortOrder: null },

      totalRecords: 0,
      first: 0,
      rows: props.rows ?? 10,

      uploading: false,
      warningDirectoryUpload: false,
      warningDuplicatedFiles: false,
      confirmFilesUpload: false,
      confirmTrash: false,
      uploadCompleted: false,
      uploadProgress: 0,

      downloading: false,
      downloadProgress: 0,

      showAttachment: false,
      showDetails: false,
    }

    this.newAttachments = []
    this.attachmentVersions = []

    this.fileInputRef = React.createRef()
    this.contentRef = React.createRef()
    this.openFileDialog = this.openFileDialog.bind(this)

    this.handleFilesAdded = this.handleFilesAdded.bind(this)
    this.handleDrop = this.handleDrop.bind(this)

    this.handleUploadProgress = this.handleUploadProgress.bind(this)
    this.handleUploadRequest = this.handleUploadRequest.bind(this)
    this.cancelUpload = this.cancelUpload.bind(this)
    this.startUploads = this.startUploads.bind(this)

    this.trashAttachment = this.trashAttachment.bind(this)

    this.showDetails = this.showDetails.bind(this)
    this.showAttachment = this.showAttachment.bind(this)
    this.handleDownloadProgress = this.handleDownloadProgress.bind(this)
    this.handleDownloadRequest = this.handleDownloadRequest.bind(this)
    this.cancelDownload = this.cancelDownload.bind(this)
    this.xhrHandler = []

    this.defaultRowPerPageOptions = [this.state.rows,this.state.rows*2,this.state.rows*3,this.state.rows*5,
                                     this.state.rows*10,this.state.rows*50,this.state.rows*100]
    this.handlePaginator = this.handlePaginator.bind(this)

    this.handleSort = this.handleSort.bind(this)

    this.filtersTimer = null
    this.filterTimeout = 500
    this.handleFilter = this.handleFilter.bind(this)
    this.handleSearchMode = this.handleSearchMode.bind(this)

    this.itemTemplate = this.itemTemplate.bind(this)

  }


  /*
   * Data managment methods
   * ==================================================================
   */

  /*
   * LoadValues : call parent loader function to get a slice of data
   * Set parameters according to paginator sort and filters
   * Compute values for data ranges and total items
   */
  async loadValues () {
    this.setState({isLoading: true})
    let minRows = this.defaultRowPerPageOptions[0]
    if (this.state.rows > minRows) {
      minRows = this.state.rows
    }
    const items = await this.props.getValues (
      this.state.first,
      minRows,
      this.state.sort,
      this.state.searchMode?this.state.filter:'',
      this.props.parentId,
      this.props.parentService,
      this.props.parentOptions
    )
    if (this.state.showDetails) {
      try {
        this.attachmentVersions = await this.props.getVersions (this.state.showDetails)
      } catch (e) {
        console.log ("getVersions returs error : ", e)
        this.attachmentVersions = []
      }
    }

    let rowPerPageOptions = []
    rowPerPageOptions = this.defaultRowPerPageOptions.filter ((v, i, a) => { return v < items.totalRecords })
    if ( ! rowPerPageOptions.length ) {
      rowPerPageOptions.push (Number (items.totalRecords))
      minRows = Number (items.totalRecords)
    }

    await this.setState (
      {
        isLoading: false,
        //FIXME selection: [],
        rowPerPageOptions: rowPerPageOptions,
        totalRecords: Number (items.totalRecords),
        rows: minRows,
        dataTableValue: items.values
      }
    )
  }

  /*
   * Update Values when paginator changes
   */
  async handlePaginator (event) {
    await this.setState (
      {
        first: event.first,
        rows: event.rows
      }
    )
    this.loadValues ()
  }

  /*
   * Update values according to sort parameters
   */
  async handleSort (event) {
    await this.setState (
      {
        sort : {
          sortField: event.sortField,
          sortOrder: event.sortOrder
        }
      }
    )
    this.loadValues ()
  }

  /*
   * Toggle search tool on and off
   */
  async handleSearchMode (event) {
    await this.setState(
      prevState => {
        return {
          searchMode: !prevState.searchMode
        }
      }
    )
    this.loadValues ()
  }

  /*
   * Update values according to search inputs
   * Wait a litle before getting values to let time
   * to user to type more inputs (then changing the filter)
   */
  async handleFilter (event) {
    if (this.filtersTimer) {
      clearTimeout (this.filtersTimer)
      this.filtersTimer = null
    }
    await this.setState (
      {
        filter: event.target.value
      }
    )
    this.filtersTimer = setTimeout(
      () => {
        this.loadValues ()
      },
      this.filterTimeout
    )
  }

  /*
   * Life cycle methods
   * ==================================================================
   */

  /*
   * Load values at init
   */
  componentDidMount() {
    // Add content className
    if ( this.contentRef.current ) {
      const content = this.contentRef.current.querySelector(".p-dataview-content")
      if ( content ) {
        content.classList.add('stretch-box-content')
      }
    }
    this.loadValues ()
  }

  /*
   * Clear timers on exit
   */
  componentWillUnmount() {
    if (this.filtersTimer) {
      clearTimeout (this.filtersTimer)
      this.filtersTimer = null
    }
  }

  /*
   * Reload values when needed by component update
   */
  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.watch !== this.props.watch) {
      this.loadValues ()
    }
    if (this.state.first > this.state.totalRecords) {
      await this.setState ({first: 0})
      this.loadValues ()
    }
  }


  /*
   * File selection handling
   * ==================================================================
   */

  /*
   * Drag over the Attachment component (do nothing, just wait...)
   */
  handleDragOver (event) {
    event.preventDefault()
  }

  /*
   * Customise original attachment to fit our needs
   */
  customAttachment (attachment) {
    attachment.state = 'original'
    attachment.error = false
    attachment.xhrHandle = null
    attachment.realName = attachment.name
    attachment.progress = 0
    attachment.inputTimer = null
    return attachment
  }

  /*
   * A set of files are dropped into the component ...
   * Check there are no directories then pass to sanity check
   * otherwise, show a warning and cancel the operation
   */
  handleDrop (event) {
    event.preventDefault()
    if (this.props.locked || this.props.readOnly) {
        return false
    }
    const dirs = []
    if (event.dataTransfer.items) {
      for (let i=0; i<event.dataTransfer.items.length; i++) {
        const item = event.dataTransfer.items[i]
        const entry = item.webkitGetAsEntry()
        const attachment = item.getAsFile()
        if (entry && entry.isFile) {
          this.newAttachments.push(this.customAttachment(attachment))
        } else {
          dirs.push (attachment)
        }
      }
    }
    if (dirs.length) {
      this.setState (
        {
          warningDirectoryUpload: true,
        }
      )
      this.newAttachments = []
      return false
    }
    this.validateAttachments ()
  }

  /*
   * Open the file selector dialog when the upload icon is clicked
   */
  openFileDialog() {
    if (this.props.locked) {
        return false
    }
    this.fileInputRef.current.click()
  }

  /*
   * Get list of files to upload ans pass them to sanity check
   */
  handleFilesAdded(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      const attachment = event.target.files.item(i)
      this.newAttachments.push(this.customAttachment(attachment))
    }
    this.validateAttachments ()
  }

  /*
   * Validate uploads
   * Check if some attachments already exist before
   * showing confirmation dialog
   */

  async validateAttachments () {
    let duplicatedFiles = false
    for (let i=0; i<this.newAttachments.length; i++) {
      const file = this.newAttachments[i]
      if ( file.state !== 'replace' ) {

        // Reset values before check
        if ( file.state === 'verify' ) {
          file.state = 'rename'
        }

        // First check for possible duplicates into new Attachments
        for (let j=0; j<this.newAttachments.length; j++) {
          const currentFile = this.newAttachments[j]
          if ( i !== j && file.realName === currentFile.realName ) {
            file.state = 'verify'
            duplicatedFiles = true
          }
        }

        // Next, look into already known list (to speed up detection)
        if ( file.state !== 'verify' ) {
          for (let j=0; j<this.state.dataTableValue.length; j++) {
            const currentFile = this.state.dataTableValue[j]
            if ( file.realName === currentFile.name ) {
              file.state = 'verify'
              duplicatedFiles = true
            }
          }
        }

        // If no duplicate are found chek existing attachments
        // This is really time consuming, so do it only if
        // - A filter has been set hiding some items
        // - Not all the attachments are present in the known list
        if ( file.state !== 'verify' &&
            (
                 (this.state.searchMode && this.state.filter)
              || (this.state.dataTableValue.length < this.state.totalRecords)
            )
          ) {
          const possibleDuplicates = await this.props.getValues (0, Number.MAX_SAFE_INTEGER, null,
            file.realName,
            this.props.parentId,
            this.props.parentService,
            this.props.parentOptions
          )
          for (let j=0; j<possibleDuplicates.totalRecords; j++) {
            const currentFile = possibleDuplicates.values[j]
            if ( file.realName === currentFile.name ) {
              file.state = 'verify'
              duplicatedFiles = true
              break
            }
          }
        }
      }
    }

    this.setState (
      {
        warningDuplicatedFiles: duplicatedFiles,
        confirmFilesUpload: true
      }
    )
  }

  /*
   * Set an attachment state to 'replace'
   * then recheck all attachments
   */
  setReplace (attachment) {
    attachment.state = 'replace'
    // One can replace an attachment with a renamed file ;-)
    // attachment.realName = attachment.name
    this.validateAttachments ()
  }

  /*
   * Set an attachment state to 'rename'
   * then recheck all attachments
   */
  setRename (attachment) {
    attachment.state = 'rename'
    this.validateAttachments ()
  }

  /*
   * Reset original attachment name and state
   * then recheck all attachments
   */
  setOriginal (attachment) {
    attachment.state = 'original'
    attachment.realName = attachment.name
    this.validateAttachments ()
  }

  /*
   * InputText event handler :
   * Modify attachment real name
   * removing bad symbols
   * then recheck all attachments
   */
  handleUpdateName (event, attachment) {
    if (attachment.inputTimer) {
      clearTimeout (attachment.inputTimer)
      attachment.inputTimer = null
    }

    const truncate = require("truncate-utf8-bytes")
    const illegalRe = /[/?<>\\:*|":]/g
    /* eslint-disable no-control-regex */
    const controlRe = /[\x00-\x1f\x80-\x9f]/g
    /* eslint-enable no-control-regex */
    const reservedRe = /^\.+$/

    const value =  event.target.value
                        .replace(illegalRe, '')
                        .replace(controlRe, '')
                        .replace(reservedRe, '')

    attachment.realName = truncate(value, 255)
    attachment.state = 'rename'
    attachment.inputTimer = setTimeout (
      () => {
        this.validateAttachments ()
      },
      this.filterTimeout
    )
    this.forceUpdate ()
  }


  /*
   * File upload handling methods
   * ==================================================================
   */

  /*
   * Cancel incomming and ingoing upload
   */
  cancelUpload () {
    for (let i=0; i<this.newAttachments.length; i++) {
      const attachment = this.newAttachments[i]

      if (attachment.inputTimer) {
        clearTimeout (attachment.inputTimer)
        attachment.inputTimer = null
      }

      try {
        if ( attachment.xhrHandle ) {
          attachment.xhrHandle.abort ()
          attachment.xhrHandle = null
        }
      } catch (e) {
        // Never append until now ...
        console.log ("Unhandled error while aborting xhr upload : ", e)
      }
    }
    this.newAttachments = []
    this.setState(
      {
        warningDirectoryUpload: false,
        warningDuplicatedFiles: false,
        confirmFilesUpload: false,
        uploading: false,
        uploadProgress: 0,
        uploadCompleted: false,
      }
    )
    this.loadValues ()
  }

  /*
   * Start uploading all attachments
   */
  async startUploads () {
    const uploads = []
    this.setState({uploading: true})
    for (let i=0; i<this.newAttachments.length; i++) {
      const attachment = this.newAttachments[i]

      if (attachment.inputTimer) {
        clearTimeout (attachment.inputTimer)
        attachment.inputTimer = null
      }
      uploads.push (this.doUpload (attachment))
    }
    try {
      await Promise.all(uploads)
      this.setState({uploadCompleted: true})
    } catch (e) {
      // Error is already showed by individual attachment
      this.setState({uploadCompleted: true})
    }
  }

  /*
   * Manage upload of on attachment
   */
  async doUpload (attachment) {
    try {
      const res = await this.props.doUpload (
        attachment,
        xhr => this.handleUploadRequest(xhr, attachment),
        event => this.handleUploadProgress(event, attachment),
        this.props.parentId,
        this.props.parentService,
        this.props.parentOptions
      )
      this.uploadCompleted (attachment)
      return res
    } catch (error) {
      this.uploadError (error, attachment)
      throw (error)
    }
  }

  /*
   * Report individual attachments upload errors
   */
  uploadError (error, attachment) {
    switch (error) {
      case "File size exeeded":
        attachment.error = "Fichier trop lourd"
        break
      case "Write failure":
        attachment.error = "Erreur lors de l'écriture"
        break
      case "Unsupported file type":
        attachment.error = "Type de fichier non pris en charge"
        break
      default:
        attachment.error = "Echec de la transmission"
    }
    this.forceUpdate ()
  }

  /*
   * Report individual attachment upload completed
   */
  uploadCompleted (attachment) {
    attachment.progress = 100
    this.forceUpdate ()
  }

  /*
   * Set an handle to the request (needed to abort)
   */
  handleUploadRequest (hxr, attachment) {
    attachment.xhrHandle = hxr
  }

  /*
   * Update progression percentage while uploading file
   */
  handleUploadProgress (event, attachment) {
    if (event.lengthComputable) {
      // Ratio will end above 100 letting us start waiting mode ...
      attachment.progress = (event.loaded / event.total) * 101
      // Updating state triggers component rendering
      this.setState(
        prevState => {
          return {
            uploadProgress : prevState.uploadProgress + 1
          }
        }
      )
    }
  }

  /*
   * Download handling
   * ==================================================================
   */

  /*
   * Download attachment
   */
  async downloadAttachment (attachment) {

    let file = []

    this.setState ({downloading : true})

    try {
      const rawData = await this.props.doDownload (
        attachment.filePathName,
        xhr=>this.handleDownloadRequest (xhr),
        event=>this.handleDownloadProgress (event)
      )
      file = DataFiles.b64ToFile(rawData, attachment.name, attachment.mimeType)
    } catch (e) {
      console.log ("Error while downloading : ", e)
      alert ("Erreur lors du téléchargement.")
    }

    this.setState ({downloading : false, downloadProgress : 0})
    this.xhrHandler = []

    return file
  }

  /*
   * Display Attachment in a popup
   */
  async showAttachment (event, attachment) {

    const file = await this.downloadAttachment (attachment)

    // IE doesn't allow using a file object directly
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, attachment.name)
      return
    }


    // Display Images and PDF using internal viewer into new window
    if (
         attachment.mimeType.match (/^image\//)
      || attachment.mimeType.match (/\/pdf$/)
    ) {
      const newWindow = window.open('', '', "width=0,height=0,location=no,location=no,toolbar=no,menubar=no")
      if (newWindow) {
        const title = newWindow.document.createElement('title')
        const link = newWindow.document.createElement('a')
        title.appendChild(newWindow.document.createTextNode(attachment.name))
        newWindow.document.head.appendChild(title)
        link.href = window.URL.createObjectURL(file)
        newWindow.document.body.appendChild(link)
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}))
      }

      // Download and/or open others with external apps
    } else {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(file)
      link.target = '_blank'
      link.download = attachment.name
      document.body.appendChild(link)
      link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}))
      link.remove()
      window.URL.revokeObjectURL(link.href)
    }
  }


  /*
   * Cancel downloads
   */
  cancelDownload () {
    try {
      if ( this.xhrHandler ) {
        this.xhrHandler.abort ()
        this.xhrHandler = []
      }
    } catch (e) {
      // Never append until now ...
      console.log ("Unhandled error while aborting xhr upload : ", e)
    }
    this.setState ({downloading : false, downloadProgress : 0})
  }

  handleDownloadProgress (event) {
    if (event.lengthComputable) {
      this.setState( { downloadProgress : (event.loaded / event.total) * 100 } )
    }
  }

  handleDownloadRequest (xhr) {
    this.xhrHandler = xhr
  }


 /*
  * Rendering methods
  * ==================================================================
  */

 /*
  * Render the no directory upload allowed warning dialog
  */
  renderWarningDirectoryUpload () {
    const dialogFooter = (
      <div>
          <Button
            label="Annuler"
            icon="pi pi-times-circle"
            className="p-button-danger"
            onClick={
              () => {
                this.setState({warningDirectoryUpload:false})
              }
            }
          />
      </div>
    )
    return (
      <Dialog
        header="Opération non prise en charge."
        visible={this.state.warningDirectoryUpload}
        modal={true}
        footer={dialogFooter}
        closable={false}
        onHide={() => this.setState({warningDirectoryUpload:false})}
      >
        <p>L'opération ne peut pas aboutir car certains des éléments<br/>
        que vous essayez de téléverser sont des dossiers.</p>
        <p>Veuillez recommencer en ne sélectionnant que des fichiers.</p>
      </Dialog>
    )
  }

  /*
   * Render Upload confirmation panel
   * If there are duplicated files, ask for a resolution
   * Then ask for confirmation or cancellation before
   * starting the actual upload
   */
  renderUploadConfirmation () {
    const dialogFooter = (
      <div>
          <Button
            label="Envoyer"
            style={{display: this.state.uploading?"none":"inline-block"}}
            disabled={this.state.warningDuplicatedFiles}
            icon="pi pi-cloud-upload"
            className={this.state.warningDuplicatedFiles?"p-button-secondary":"p-button-info"}
            onClick={this.startUploads}
          />
          <Button
            label={this.state.uploadCompleted?"fermer":"Annuler"}
            icon="pi pi-times-circle"
            className={this.state.uploadCompleted?"p-button-info":"p-button-danger"}
            onClick={this.cancelUpload}
          />
      </div>
    )
    let message = "Pièces jointes à transmettre:"
    if (this.state.arningDuplicatedFiles) {
        message += "<br/>"
        message += "<b>(certains éléments nécessitent une confirmation)</b>"
    }
    return (
      <Dialog
        className="attachments-confirm-panel"
        header="Transmissoin des pièces jointes..."
        visible={this.state.confirmFilesUpload}
        modal={true}
        footer={dialogFooter}
        closable={false}
        onHide={this.cancelUpload}
      >
        <p>{message}</p>
        <div className="p-grid">
          {
            this.newAttachments.map (
              (file, index) => {
                let classState = "attachments-input"
                let action = []
                let button = null
                let infos = null

                switch (file.state) {
                  case 'verify':
                    classState += " attachments-label-missing-field attachments-label-target-missing-field"
                    action = [{label: 'Remplacer', icon: 'pi pi-refresh', command: (e) => this.setReplace (file)}]
                    button = <SplitButton disabled={this.state.uploading} label="Renommer"
                                          icon="pi pi-tag" onClick={(e) => this.setRename (file)} model={action}/>
                    break

                  case 'replace':
                    action = [{label: 'Renommer', icon: 'pi pi-tag', command: (e) => this.setRename (file)}]
                    button = <SplitButton disabled={this.state.uploading} label="Remplacer"
                                          icon="pi pi-refresh" onClick={(e) => this.setReplace (file)} model={action}/>
                    break

                  case 'rename':
                    action = [{label: 'Original', icon: 'pi pi-circle-off', command: (e) => this.setOriginal (file)}]
                    button = <SplitButton disabled={this.state.uploading} label="Renommer"
                                          icon="pi pi-tag" onClick={(e) => this.setRename (file)} model={action}/>
                    break

                  default:
                    action = [{label: 'Renommer', icon: 'pi pi-tag', command: (e) => this.setRename (file)}]
                    button = <SplitButton disabled={this.state.uploading} label="Original"
                                          icon="pi pi-circle-off" onClick={(e) => this.setOriginal (file)} model={action}/>
                    break
                }

                if (!file.error) {
                    if ( file.progress > 100 ) {
                      // Wait at the end of the download for server to confirm upload
                      infos = <ProgressBar mode="indeterminate" />
                    } else {
                      infos = <ProgressBar value={file.progress.toFixed(0)} />
                    }
                } else {
                    infos = <Button label={file.error} icon="pi pi-exclamation-triangle" className="p-button-danger  attachments-error"/>
                }

                return  (
                  <div key={index} className="p-grid p-col-12 attachments-item">
                    <div className="p-col-12 p-md-9">
                      <InputText
                        className={classState}
                        disabled={file.state === 'replace' || file.state === 'original' || this.state.uploading}
                        value={file.realName}
                        onChange={(e) => this.handleUpdateName (e, file)}
                        size="10"
                      />
                    </div>
                    <div className="p-col-12 p-md-3">
                      {button}
                    </div>
                    <div className="p-col-12">
                      {infos}
                    </div>
                  </div>
                )
              }
            )
          }
        </div>
      </Dialog>
    )
  }

  /*
   * Display a progress bar while downloading documents
   */
  renderDownload () {
    const dialogFooter =  (
      <div>
          <Button
            label={"Annuler"}
            disabled={this.state.downloadProgress === 100}
            icon="pi pi-times-circle"
            className={"p-button-danger attachments-download-cancel"}
            onClick={this.cancelDownload}
          />
      </div>
    )
    return (
      <Dialog
        header="Téléchargement en cours ..."
        footer={dialogFooter}
        className="attachments-download-panel"
        visible={this.state.downloading}
        modal={true}
        onHide={()=>{}}
        closable={false}
      >
        <ProgressBar value={this.state.downloadProgress.toFixed(0)} />
      </Dialog>
     )
  }

  /*
   * Render the search bar if searchMode is actived
   */
  renderSearchBar () {
    if (this.state.searchMode) {
      return (
        <div className="p-grid p-col-12">
            <InputText type="search" value={this.state.filter} onChange={this.handleFilter} placeholder="Recherche globale" size="50"/>
        </div>
      )
    }
  }

  renderHeader () {
    return (
      <div className="p-grid">
        <input
          style={{display: "none"}}
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          onChange={this.handleFilesAdded}
        />
        <div className="p-col-12">
          <p style ={{float: 'left'}}>
            <i className="pi pi-search attachments-cursor-pointer" style={{margin:'4px 4px 0 0'}} onClick={this.handleSearchMode}></i>
          </p>
          {!this.props.readOnly &&
            <p style ={{float: 'left'}}>
              <i className="pi pi-download attachments-cursor-pointer" style={{margin:'4px 4px 0 0'}} onClick={this.openFileDialog}></i>
            </p>
          }
           <p style ={{float: 'right'}}>{this.state.first + 1} - {this.state.first + this.state.dataTableValue.length} / {this.state.totalRecords}</p>
        </div>
        {this.renderSearchBar ()}
      </div>
    )
  }


 /*
  * Render trash confirmation dialog
  */
  renderTrashConfirm () {
    const dialogFooter = (
      <div>
          <Button
            label="Annuler"
            icon="pi pi-times-circle"
            className="p-button-info"
            onClick={
              () => {
                this.setState({confirmTrash:false})
              }
            }
          />
          <Button
            label="Effacer"
            icon="pi pi-times-trash"
            className="p-button-danger"
            onClick={this.trashAttachment}
          />
      </div>
    )
    return (
      <Dialog
        header="Effacer une pièce jointe."
        visible={this.state.confirmTrash !== false}
        modal={true}
        footer={dialogFooter}
        closable={false}
        onHide={() => this.setState({confirmTrash:false})}
      >
        <p>Voulez-vous vraiment effacer cette pièce jointe ?</p>
        <p>Cette opératoin détruira toutes les versions de ce document.</p>
      </Dialog>
    )
  }

  /*
   * Display trash confirmation dialog
   */
  trashConfirm (event, item) {
    this.setState ({confirmTrash: item.id})
  }

  async trashAttachment () {
    try {
      await this.props.delete (this.state.confirmTrash)
    } catch (e) {
        console.log ("delete returs error : ", e)
    }
    await this.loadValues ()
    await this.setState ({confirmTrash:false})
  }

  /*
   * Set / Unset attachment details display
   */
  async showDetails (event, attachment) {
    const showDetails = (this.state.showDetails!==attachment.id)?attachment.id:false
    if (showDetails) {
      try {
        this.attachmentVersions = await this.props.getVersions (showDetails)
      } catch (e) {
        console.log ("getVersions returs error : ", e)
        this.attachmentVersions = []
      }
    }
    await this.setState ({showDetails:showDetails})
  }

  /*
   * Set curent attacment version
   */
  async setCurrent (event, item) {
    try {
      await this.props.setVersions (item)
    } catch (e) {
      console.log ("setCurrent returs error : ", e)
      this.attachmentVersions = []
    }
    this.loadValues ()
  }

  /*
   * Display the curent main Item informations
   */
  mainItem(item) {
    return (
        <div key={item.version} className="p-grid">
          <div className="p-col-12 p-md-1 attachments-actions-container">
            <Button
              icon="pi pi-search"
              className="p-button-info"
              onClick={e => {this.showAttachment (e,item)}}
              disabled={this.props.locked}>
            </Button>
            <Button
              icon="fas fa-wrench"
              className="p-button-info"
              onClick={e => {this.showDetails (e,item)}}
              disabled={this.props.locked}>
            </Button>
          </div>
          <div className="p-col-12 p-md-10">
            <div className="p-col-12">
              <b>{item.name}</b>
            </div>
            <div className="p-col-12">
              version {item.version} modifiée le {new Date (item.date).toLocaleString ('fr-FR')}
            </div>
          </div>
          <div className="p-col-12 p-md-1 attachments-actions-container">
            { item.id === this.state.showDetails &&
            <Button
              icon="pi pi-trash"
              className="p-button-danger"
              onClick={e => {this.trashConfirm (e,item)}}
              disabled={this.props.locked || this.props.readOnly}>
            </Button>
            }
          </div>
        </div>
    )
  }
  /*
   * Display a unique Item informations
   */
  oneItem(item) {
    return (
        <div key={item.version} className="p-grid">
          <div className="p-col-12 p-md-1 attachments-actions-container">
            <Button
              icon="pi pi-search"
              className="p-button-info"
              onClick={e => {this.showAttachment (e,item)}}
              disabled={this.props.locked}>
            </Button>
            <Button
              icon="pi pi-star-o"
              className="p-button-success"
              onClick={e => {this.setCurrent (e,item)}}
              disabled={this.props.locked}>
            </Button>
          </div>
          <div className="p-col-12 p-md-10">
            <div className="p-col-12">
              {item.name}
            </div>
            <div className="p-col-12">
              version {item.version} modifiée le {new Date (item.date).toLocaleString ('fr-FR')}
            </div>
          </div>
        </div>
    )
  }

  /*
   * Rendering item template
   * Used to display each items in the DataView component
   */
  itemTemplate(dataTableValue, layout) {
    if ( ! dataTableValue ) {
      /* FIXME
       * Lazy Loading is buggy on DataView component,
       * so we have to ignore null values
       */
      return null
    }

    const items = []
    items.push (this.mainItem (dataTableValue))

    if (dataTableValue.id === this.state.showDetails) {
      for (let i=0; i<this.attachmentVersions.length; i++) {
        const item = this.attachmentVersions[i]
        if (item.version !== dataTableValue.version) {
            items.push (this.oneItem (item))
        }
      }
    }

    return (
      <div className="p-col-12 attachments-item">
            {items}
      </div>
    )
  }

  /*
   * DataView component rendering
   */
  render() {
    return (
      <div
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
        ref={this.contentRef}
        className="stretch-box-item"
      >
        <DataView

          header={this.renderHeader()}
          emptyMessage='Aucune entrée trouvée.'

          value={this.state.dataTableValue}

          paginator={true}
          paginatorPosition='both'
          rowsPerPageOptions={this.state.rowPerPageOptions}
          totalRecords={this.state.totalRecords}

          first={this.state.first}
          rows={this.state.rows}

          lazy={true}
          onPage={this.handlePaginator}
          loading={this.state.isLoading}

          /* TODO
          onSort={this.handleSort}
          */
          sortField={this.state.sort.sortField}
          sortOrder={this.state.sort.sortOrder}

          itemTemplate={this.itemTemplate}

        />
        {this.renderWarningDirectoryUpload()}
        {this.renderUploadConfirmation()}
        {this.renderDownload()}
        {this.renderTrashConfirm()}
      </div>
    )
  }
}
