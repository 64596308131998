import React from 'react';
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

export class PartnerTable extends AuthenticatedTable {

  constructor(props) {
    super(props, 'partner', '/Partenaires/', 'Partners')
  }


  // Overrinding getValue to handle optional groupId
  getValues (first=0, rows=0, sort=null, filter=null, filters=null) {
    if ( this.props.groupId ) {
      filters = this.defaultFilters (filters, {group_id: {value : this.props.groupId}})
    }
    const partners = super.getValues (first, rows, sort, filter, filters)
    return partners
  }

  doRender () {

    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        onOpen={this.handleOpen}
        onDelete={this.props.groupId===undefined?this.handleDelete:undefined}
        onCreate={this.props.groupId===undefined?this.handleCreate:undefined}
        onRemove={this.props.onRemove}
        onAdd={this.props.onAdd}
        addItemGet={this.props.addItemGet}
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column field="name" header="Nom" sortable={true} style={{width:'35%'}} />
        <Column field="customer"
          header="Client"
          sortable={true}
          filterMethod={LazyDataTable.optionsFilter}
          filterParams={LazyDataTable.booleanDefaultOptions}
          body={(rawData, column) => {return LazyDataTable.booleanTemplate(rawData, column)}}
          style={{textAlign:'center', width:'15%'}}
        />
        <Column field="supplier"
          header="Fournisseur"
          sortable={true}
          filterMethod={LazyDataTable.optionsFilter}
          filterParams={LazyDataTable.booleanDefaultOptions}
          body={(rawData, column) => {return LazyDataTable.booleanTemplate(rawData, column)}}
          style={{textAlign:'center', width:'15%'}}
        />
        <Column field="group" header="Groupe" sortable={true} style={{width:'35%'}} />
      </LazyDataTable>
    )
  }

}

