import React from 'react'
import {AuthenticatedComponent} from './AuthenticatedComponent'
import {AuthenticatedTable} from './AuthenticatedTable'
import {LazyDataTable} from './LazyDataTable'
import {Display} from './Display'
import {DataTable} from 'primereact/datatable'
import {Tree} from 'primereact/tree'
import {Column} from 'primereact/column'
import {TabView,TabPanel} from 'primereact/tabview'
import {Dropdown} from 'primereact/dropdown'
import {Button} from 'primereact/button'
import {RadioButton} from 'primereact/radiobutton'
import {InputText} from 'primereact/inputtext'
import {Calendar} from 'primereact/calendar'
import './Forms.css'



/*
 * GROUP FILTER OBJECT
 * ====================================================================
 */

class GroupFilter extends AuthenticatedComponent {

  doRender () {

    const node = this.props.value
    const andId = 'AND' + node.key
    const orId = 'OR' + node.key

    return (
      <div className="p-fluid p-grid group-filter">
        <div className="p-col-12">
          <b>{'Groupe de critères ' + node.key}</b>
        </div>
        <div className="p-col-12 p-lg-7 p-grid">
          <div className="p-field-radiobutton p-mt-2 p-mr-2">
            <RadioButton
              inputId={andId}
              value="AND"
              name="mode"
              onChange={(e) => this.props.onUpdate (node.key, e.value)}
              checked={node.data.mode === 'AND'}
            />
            <label htmlFor={andId}>Doit satisfaire toutes les conditions</label>
          </div>
          <div className="p-field-radiobutton p-mt-2 p-mr-2">
            <RadioButton
              inputId={orId}
              value="OR"
              name="mode"
              onChange={(e) => this.props.onUpdate (node.key, e.value)}
              checked={node.data.mode === 'OR'}
            />
            <label htmlFor={orId}>Doit satisfaire au moins une condition</label>
          </div>
        </div>
        <div className="p-grid p-col-11 p-lg-4">
          <div className="p-mt-2 p-mr-2">
            <Button
              type="button"
              icon="fas fa-plus-circle"
              label="Ajouter un critère"
              onClick={() => this.props.onAddItem (node.key)}
            />
          </div>
          <div className="p-mt-2 p-mr-2">
            <Button
              type="button"
              icon="fas fa-folder-plus"
              label="Ajouter un groupe"
              onClick={() => this.props.onAddGroup (node.key)}
            />
          </div>
        </div>

        <Display if={node.key.length !== 1 && node.children.length === 0}>
          <div className="p-col-1 p-ml-auto">
            <Button
              type="button"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => this.props.onDelete (node.key)}
            />
          </div>
        </Display>

      </div>
    )
  }
}




/*
 * ITEM FILTER OBJECT
 * ====================================================================
 */

class ItemFilter extends AuthenticatedComponent {
  constructor (props) {
    super(props)

    this.defaultDate = new Date ()

    this.criteria = {
      text: [
        {
          label: 'Contient',
          id: 'like',
          default: '',
        },
        {
          label: 'Ne contient pas',
          id: 'unlike',
          default: '',
        },
        {
          label: 'Est exactement',
          id: 'equal',
          default: '',
        },
        {
          label: 'Est différent de',
          id: 'different',
          default: '',
        },
        {
          label: "Existe",
          id: 'not null',
          default: null,
        },
        {
          label: "N'existe pas",
          id: 'null',
          default: null,
        },
      ],
      numeric: [
        {
          label: 'Est égal à',
          id: 'equal',
          default: '0',
        },
        {
          label: 'Est différent de',
          id: 'different',
          default: '0',
        },
        {
          label: 'Est plus petit que',
          id: 'less than',
          default: '0',
        },
        {
          label: 'Est plus grand que',
          id: 'greater than',
          default: '0',
        },
        {
          label: "Existe",
          id: 'not null',
          default: null,
        },
        {
          label: "N'existe pas",
          id: 'null',
          default: null,
        },
      ],
      date: [
        {
          label: 'est avant',
          id: 'before',
          default: this.defaultDate,
        },
        {
          label: 'est après',
          id: 'after',
          default: this.defaultDate,
        },
        {
          label: 'Est le',
          id: 'equal',
          default: this.defaultDate,
        },
        {
          label: "N'est pas le",
          id: 'different',
          default: this.defaultDate,
        },
        {
          label: "Existe",
          id: 'not null',
          default: null,
        },
        {
          label: "N'existe pas",
          id: 'null',
          default: null,
        },
      ],
    }

    this.handleFieldSelect = this.handleFieldSelect.bind(this)
    this.handleCriterionSelect = this.handleCriterionSelect.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
    this.handleNumberChange = this.handleNumberChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.getPattern = this.getPattern.bind(this)
  }

  handleFieldSelect (e) {
    const field = this.props.fields.reduce ((r, s) => ( s === e.value ? s : r), {value: null})
    const criterion = this.criteria[field.type][0]
    const pattern = criterion.default
    this.props.onUpdate (this.props.value.key, field, criterion, pattern)
  }

  handleCriterionSelect (e) {
    const criteria = this.criteria[this.props.value.data.field.type]
    let criterion = null
    for (let i=0; i<criteria.length; i++) {
      if ( criteria[i].id === e.value.id ) {
        criterion = criteria[i]
        break
      }
    }
    const pattern = (this.props.value.data.pattern === null || criterion.default === null)
      ? criterion.default
      : this.props.value.data.pattern
    this.props.onUpdate (this.props.value.key, this.props.value.data.field, criterion, pattern)
  }

  handleTextChange (e) {
    const pattern = e.target.value
    this.props.onUpdate (this.props.value.key, this.props.value.data.field, this.props.value.data.criterion, pattern)
  }

  handleDateChange (e) {
    const pattern = e.value
    this.props.onUpdate (this.props.value.key, this.props.value.data.field, this.props.value.data.criterion, pattern)
  }

  handleNumberChange (e) {
    if ( e.target.value.match (/^-?([0-9]+(,[0-9]*)?)?$/) ) {
      const pattern = e.target.value
      this.props.onUpdate (this.props.value.key, this.props.value.data.field, this.props.value.data.criterion, pattern)
    }
  }

  getPattern () {
    if ( !this.props.value.data.field ) {
      return null
    }
    if ( this.props.value.data.pattern === null ) {
      return (<div></div>)
    }
    switch ( this.props.value.data.field.type ) {
      case 'numeric':
        return (
          <InputText
            className="form-input"
            id="patternT"
            autoFocus={true}
            value={this.props.value.data.pattern}
            onChange={this.handleNumberChange}
          />
        )

      case 'date':
        return (
          <Calendar
            className="form-input"
            id="patternD"
            appendTo={document.body}
            autoFocus={true}
            value={this.props.value.data.pattern}
            onChange={this.handleDateChange}
            monthNavigator={true}
            yearNavigator={true}
            yearRange="1900:2100"
            dateFormat="dd/mm/yy"
            locale= {
              {
                firstDayOfWeek: 1,
                today: "aujourd'hui",
                clear: "Effacer",
                dayNames: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
                dayNamesShort: ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
                dayNamesMin: ["D", "L", "Ma", "Me", "J", "V", "S"],
                monthNames: ["janvier", "fevrier", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
                monthNamesShort: ["jan", "fev", "mar", "avr", "mai", "juin", "juil", "aou", "sep", "oct", "nov", "dec"]
              }
            }
          />
        )

      default:
        return (
          <InputText
            className="form-input"
            id="pattern"
            autoFocus={true}
            value={this.props.value.data.pattern}
            onChange={this.handleTextChange}
          />
        )
    }
  }

  doRender () {

    const option = this.props.value.data.field
      ? this.criteria[this.props.value.data.field.type].map ( c => { return {id: c.id, label: c.label} } )
      : []
    const value = this.props.value.data.criterion
      ? {id : this.props.value.data.criterion.id, label: this.props.value.data.criterion.label}
      : null
    const node = this.props.value

    return (
      <div className="p-fluid p-grid item-filter">

        <div className="p-col-4">
          <Dropdown
            className="form-input"
            placeholder="Choisissez un champ"
            appendTo={document.body}
            id="filterField"
            options={this.props.fields}
            value={this.props.value.data.field}
            onChange={this.handleFieldSelect}
            autoWidth={false}
          />
        </div>

        <Display if={this.props.value.data.field}>
          <div className="p-col-3">
            <Dropdown
              className="form-input"
              appendTo={document.body}
              id="filterCriterion"
              options={option}
              value={value}
              onChange={this.handleCriterionSelect}
              autoWidth={false}
            />
          </div>

          <div className="p-col-3">
            {this.getPattern ()}
          </div>

        </Display>

        <div className="p-col-1 p-ml-auto">
          <Button
            type="button"
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => this.props.onDelete (node.key)}
          />
        </div>

      </div>
    )
  }
}





/*
 * QUERRY BUILDER OBJECT
 * ====================================================================
 */

export class QueryBuilder extends AuthenticatedTable {

  constructor (props) {
    super(props, 'queryBuilder', props.match.path.replace (/\/:.*$/, ''), props.match.params.service,

      {
        queryBuilder: {
          field: null,
          child: null,
          fields: [],
        },

        csvHeaders: [],

        field_list: [],
        child_list: [],

        filterField_list: [],
        filters: [
          {
            key: '0',
            data: {
              type: 'group',
              mode: 'AND',
            },
            children: [],
            className: 'group',
          }
        ],
        filterExpanded: {'0': true},

        selected_fields : [],
      }
    )

    // Object management
    this.getQueryBuilderValues = this.getQueryBuilderValues.bind(this)

    // Fields selection
    this.handleFieldSelect = this.handleFieldSelect.bind(this)
    this.handleAddField = this.handleAddField.bind(this)
    this.handleRemoveField = this.handleRemoveField.bind(this)
    this.handleChildSelect = this.handleChildSelect.bind(this)
    this.handleAddChild = this.handleAddChild.bind(this)
    this.removeTemplate = this.removeTemplate.bind(this)

    // Data filtering
    this.handleFilterGroupUpdate = this.handleFilterGroupUpdate.bind(this)
    this.handleFilterGroupAddItem = this.handleFilterGroupAddItem.bind(this)
    this.handleFilterGroupAddGroup = this.handleFilterGroupAddGroup.bind(this)
    this.handleFilterItemUpdate = this.handleFilterItemUpdate.bind(this)
    this.handleFilterItemDelete = this.handleFilterItemDelete.bind(this)
    this.filterTemplate = this.filterTemplate.bind(this)

    // Result rendering
    this.buildResults = this.buildResults.bind(this)

  }


  /*
   * QueryBuilder Object managment
   */

  getQueryBuilderValues (first=0, rows=0, sort=null, filter=null, filters=null) {
    filters = this.defaultFilters (
      filters, {
        _fields: this.state.selected_fields,
        _filters: this.state.filters[0],
      }
    )
    return this.getValues (first, rows, sort, filter, filters, true, 'queryBuilderList' )
  }

  async getExternalValues () {
    const queryBuilderValues = await this.apiCall (this.serviceName, 'queryBuilderGet')
    const field_list = queryBuilderValues.fields.sort ((a,b) => a.label < b.label ? -1 : 1)
    const child_list = queryBuilderValues.relations.sort ((a,b) => a.label < b.label ? -1 : 1)
    this.setState (
      {
        field_list: field_list,
        child_list: child_list,
      }
    )
  }


  /*
   * Fields selection Managment
   */

  handleFieldSelect (e) {
    let newValue = {value: null}
    if ( e.value !== null ) {
      newValue = this.state.field_list.reduce ((r, s) => ( s === e.value ? s : r), {value: null})
    }
    this.setState (
      prevState => {
        prevState.queryBuilder.field = newValue
        return {
          queryBuilder: prevState.queryBuilder
        }
      }
    )
  }

  handleAddField () {
    const index = this.state.field_list.indexOf (this.state.queryBuilder.field)
    if ( index !== -1 ) {
      this.setState (
        prevState => {
          prevState.selected_fields.push (prevState.field_list[index])
          if ( ! prevState.filterField_list.includes (prevState.field_list[index]) ) {
            prevState.filterField_list.push (prevState.field_list[index])
          }
          prevState.field_list.splice(index, 1)
          prevState.queryBuilder.field = null
          return {
            selected_fields: prevState.selected_fields,
            field_list: prevState.field_list.sort ((a,b) => a.label < b.label ? -1 : 1),
            filterField_list: prevState.filterField_list.sort ((a,b) => a.label < b.label ? -1 : 1),
            queryBuilder: prevState.queryBuilder,
            csvHeaders: this.buildCsvHeaders (),
          }
        }
      )
    }
  }

  handleRemoveField (field=null) {
    const index = this.state.selected_fields.indexOf (field)
    if ( index !== -1 ) {
      this.setState (
        prevState => {
          prevState.field_list.push (field)
          if ( prevState.filterField_list.includes (field) ) {
            prevState.filterField_list.splice(prevState.filterField_list.indexOf (field), 1)
            this.removeFieldFromFilterTree (field, prevState.filters)
            this.renumFilterTree (prevState.filters[0], 0, '', prevState.filterExpanded)
          }
          prevState.selected_fields.splice(index, 1)
          return {
            selected_fields: prevState.selected_fields,
            field_list: prevState.field_list.sort ((a,b) => a.label < b.label ? -1 : 1),
            filterField_list: prevState.filterField_list.sort ((a,b) => a.label < b.label ? -1 : 1),
            filters: prevState.filters,
            filterExpanded: prevState.filterExpanded,
            csvHeaders: this.buildCsvHeaders (),
          }
        }
      )
    }
  }

  handleChildSelect (e) {
    let newValue = {value: null}
    if ( e.value !== null ) {
      newValue = this.state.child_list.reduce ((r, s) => ( s === e.value ? s : r), {value: null})
    }
    this.setState (
      prevState => {
        prevState.queryBuilder.child = newValue
        return {
          queryBuilder: prevState.queryBuilder
        }
      }
    )
  }

  async handleAddChild () {
    const index = this.state.child_list.indexOf (this.state.queryBuilder.child)
    if ( index !== -1 ) {

      const queryBuilderValues = await this.apiCall (this.state.queryBuilder.child.id, 'queryBuilderGet')
      const fields = queryBuilderValues.fields
        ? queryBuilderValues.fields.map (
            v => {
              return {
                ...v,
                label : this.state.queryBuilder.child.label + '.' + v.label,
                id : this.state.queryBuilder.child.id + '/' + v.id
              }
            }
          )
        : []
      const relations = queryBuilderValues.relations
        ? queryBuilderValues.relations.map (
            v => {
              return {
                ...v,
                label : this.state.queryBuilder.child.label + '.' + v.label,
                id : this.state.queryBuilder.child.id + '/' + v.id
              }
            }
          )
        : []

      this.setState (
        prevState => {
          prevState.field_list.push (...fields)
          prevState.child_list.splice(index, 1)
          prevState.child_list.push (...relations)
          prevState.queryBuilder.child = null
          return {
            field_list: prevState.field_list.sort ((a,b) => a.label < b.label ? -1 : 1),
            child_list: prevState.child_list.sort ((a,b) => a.label < b.label ? -1 : 1),
            queryBuilder: prevState.queryBuilder,
          }
        }
      )
    }
  }

  removeTemplate(field) {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        className="p-button-danger"
        style={{width: '1.5em', height: '1.5em'}}
        onClick={() => this.handleRemoveField (field)}
      />
    )
  }




  /*
   * Data filtering managment
   */

  searchFilterTree (level, key) {
    let result = null
    for (let i=0; !result && i<level.length; i++) {
      if ( level[i].key === key ) {
        return level[i]
      }
      if ( level[i].children ) {
        result =  this.searchFilterTree ( level[i].children, key )
      }
    }
    return result
  }

  renumFilterTree (item, index, rootKey, filterExpanded) {
    if ( rootKey ) {
      rootKey += '-'
    }
    const newKey = rootKey  + String (index)
    if ( item.data.type === 'group' ) {
      if ( filterExpanded[item.key] ) {
        delete filterExpanded[item.key]
      }
      filterExpanded[newKey] = true
      for (let i=0; i<item.children.length; i++) {
        this.renumFilterTree (item.children[i], i, newKey, filterExpanded)
      }
    }
    item.key = newKey
  }

  removeFieldFromFilterTree (field, filters) {
    const removedIndexes = []
    for (let i=0; i<filters.length; i++) {
      if ( filters[i].children ) {
        this.removeFieldFromFilterTree (field, filters[i].children)
        if ( !filters[i].children.length && filters[i].key !== '0' ) {
          removedIndexes.unshift (i)
        }
      } else if ( filters[i].data.field === field ) {
        removedIndexes.unshift (i)
      }
    }
    for (let i=0; i<removedIndexes.length; i++) {
      filters.splice (removedIndexes[i], 1)
    }
  }

  handleFilterGroupUpdate (nodeKey, mode) {
    this.setState (
      prevState => {
        const group = this.searchFilterTree (prevState.filters, nodeKey)
        if ( group ) {
          group.data.mode = mode
        }
        return  {filters:prevState.filters}
      }
    )
  }

  handleFilterGroupAddItem (nodeKey) {
    this.setState (
      prevState => {
        const group = this.searchFilterTree (prevState.filters, nodeKey)
        if ( group ) {
          const key = nodeKey + '-' + String (group.children.length)
          group.children.push (
            {
              key: key,
              data: {
                type: 'item',
                field: null,
                criterion: null,
                pattern: '',
              }
            }
          )
        }
        return  {filters:prevState.filters}
      }
    )
  }

  handleFilterGroupAddGroup (nodeKey) {
    this.setState (
      prevState => {
        const group = this.searchFilterTree (prevState.filters, nodeKey)
        if ( group ) {
          const key = nodeKey + '-' + String (group.children.length)
          group.children.push (
            {
              key: key,
              data: {
                type: 'group',
                mode: 'AND',
              },
              children: [],
              className: 'group'
            }
          )
          prevState.filterExpanded[key] = true
        }
        return  {
          filters:prevState.filters,
          filterExpanded:prevState.filterExpanded,
        }
      }
    )
  }

  handleFilterItemUpdate (nodeKey, field, criterion, pattern) {
    this.setState (
      prevState => {
        const item = this.searchFilterTree (prevState.filters, nodeKey)
        if ( item ) {
          item.data.field = field
          item.data.criterion = criterion
          item.data.pattern = pattern
        }
        return  {filters:prevState.filters}
      }
    )
  }

  handleFilterItemDelete (nodeKey) {
    const delimiterIndex = nodeKey.lastIndexOf('-')
    if ( delimiterIndex !== -1 ) {
      const groupKey = nodeKey.substr (0, delimiterIndex)
      this.setState (
        prevState => {
          const group = this.searchFilterTree (prevState.filters, groupKey)
          let itemIndex = -1
          for (let i=0; i<group.children.length; i++) {
            if ( group.children[i].key === nodeKey ) {
              itemIndex = i
              group.children[i].key += '-DELETED'
              continue
            }
            if ( itemIndex !== -1 ) {
              this.renumFilterTree (group.children[i], i-1, groupKey, prevState.filterExpanded)
            }
          }
          if ( itemIndex !== -1 ) {
            group.children.splice(itemIndex, 1);
          }
          return (
            {
              filters: prevState.filters,
              filterExpanded: prevState.filterExpanded,
            }
          )
        }
      )
    }
  }


  filterTemplate (node) {
    if ( node.data.type === 'group' ) {
      return (
        <GroupFilter
          value={node}
          onUpdate={this.handleFilterGroupUpdate}
          onAddItem={this.handleFilterGroupAddItem}
          onAddGroup={this.handleFilterGroupAddGroup}
          onDelete={this.handleFilterItemDelete}
        />
      )
    } else if ( node.data.type === 'item' ) {
      return (
        <ItemFilter
          value={node}
          fields={this.state.filterField_list}
          onUpdate={this.handleFilterItemUpdate}
          onDelete={this.handleFilterItemDelete}
        />
      )
    }
  }



  /*
   * Result data managment
   */

  buildResults () {
    const results = []

    for ( let i=0; i<this.state.selected_fields.length; i++) {
      results.push (
        <Column
          key={this.state.selected_fields[i].id}
          field={this.state.selected_fields[i].id}
          sortable={true}
          header={this.state.selected_fields[i].label}
        />
      )
    }
    if ( !results.length ) {
      results.push (<Column key="id" field="id" header="Aucun champ sélectionné" style={{width:'100'}} />)
    }
    return results
  }

  async getExportValues (...args) {
    const data = await this.getQueryBuilderValues (0, Number.MAX_SAFE_INTEGER, ...args)
    return data.values
  }

  buildCsvHeaders () {
    const csvHeaders = [] ;
    for (let i=0; i<this.state.selected_fields.length; i++) {
      csvHeaders.push (
        {
          label: this.state.selected_fields[i].label,
          key: this.state.selected_fields[i].id
        }
      )
    }
    return csvHeaders
  }



  /*
   * Global rendering
   */

  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">

              <div className="card card-w-title">

                <h1>
                  <div className="p-grid p-justify-between">
                    <div className="p-col-12 p-md-5">
                      Extraction de données
                    </div>
                    <div className="p-col-12 p-md-2">
                      <Button
                        label="Fermer"
                        icon="pi pi-times"
                        onClick={() => this.context.upRoute ()}
                      />
                    </div>
                  </div>
                </h1>

                <div className="form-new-line  p-grid">

                  <TabView className="p-col-12">

                    <TabPanel header="Séléction des champs" rightIcon="fas fa-bars">

                      {/*
                         -----------------------------------------------------------------------

                                                Fields selection panel

                         ------------------------------------------------------------------------
                      */}

                      <Display if={this.state.field_list.length}>
                        <div className="p-grid p-col-12">
                          <div className="p-col-12">
                            <label htmlFor="field">Champs exportables</label>
                          </div>
                          <div className="p-col-12 p-md-5">
                            <Dropdown
                              className="form-input"
                              placeholder="Choisissez un champ"
                              appendTo={document.body}
                              id="field"
                              options={this.state.field_list}
                              value={this.state.queryBuilder.field}
                              onChange={this.handleFieldSelect}
                              autoWidth={false}
                            />
                          </div>
                          <Display if={this.state.queryBuilder.field}>
                            <div className="p-col-12 p-md-2">
                              <Button
                                label="Ajouter"
                                icon="pi pi-plus"
                                onClick={this.handleAddField}
                              />
                            </div>
                          </Display>
                        </div>
                      </Display>

                      <Display if={this.state.child_list.length}>
                        <div className="p-grid p-col-12">
                          <div className="p-col-12">
                            <label htmlFor="field">Champs supplémentaires</label>
                          </div>
                          <div className="p-col-12 p-md-5">
                            <Dropdown
                              className="form-input"
                              placeholder="Choisissez une liste de champs"
                              appendTo={document.body}
                              id="field"
                              options={this.state.child_list}
                              value={this.state.queryBuilder.child}
                              onChange={this.handleChildSelect}
                              autoWidth={false}
                            />
                          </div>
                          <Display if={this.state.queryBuilder.child}>
                            <div className="p-col-12 p-md-2">
                              <Button
                                label="Ajouter"
                                icon="pi pi-plus"
                                onClick={this.handleAddChild}
                              />
                            </div>
                          </Display>
                        </div>
                      </Display>

                      <div className="p-grid p-col-12">
                        <div className="p-col-12">
                          <DataTable
                            value={this.state.selected_fields}
                            emptyMessage='Aucun champ sélectionné.'
                          >
                            <Column field="label" header="Champ"></Column>
                            <Column field="id" header="Id"></Column>
                            <Column field="type" header="type"></Column>
                            <Column
                              body={(field) => this.removeTemplate (field)}
                              headerStyle={{width: '8em', textAlign: 'center'}}
                              bodyStyle={{textAlign: 'center', overflow: 'visible'}}
                            />
                          </DataTable>
                        </div>
                      </div>

                    </TabPanel>

                    <TabPanel header="Filtres" rightIcon="fas fa-filter">

                      {/*
                         -----------------------------------------------------------------------

                                                   Filter panel

                         ------------------------------------------------------------------------
                      */}

                      <Tree
                        value={this.state.filters}
                        nodeTemplate={this.filterTemplate}
                        expandedKeys={this.state.filterExpanded}
                        onToggle={()=>{}}
                      />

                    </TabPanel>

                    <TabPanel header="Résultats" rightIcon="fas fa-table">

                      {/*
                         -----------------------------------------------------------------------

                                                Data display panel

                         ------------------------------------------------------------------------
                      */}


                      <div className="p-grid p-col-12">
                        <div className="p-col-12">
                          <LazyDataTable
                            ref={this.setRef}
                            locked={this.props.locked}
                            getValues={this.getQueryBuilderValues}

                            onExport={this.getExportValues}
                            exportFilename="Extraction.csv"
                            exportHeaders={this.state.csvHeaders}

                            watch={this.state.profile}
                          >
                            {this.buildResults ()}
                          </LazyDataTable>
                        </div>
                      </div>

                    </TabPanel>
                  </TabView>
                </div>
              </div>
          </div>
        </div>
      </div>
    )
  }
}

