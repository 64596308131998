export class StaticData {

  /*
   *  Collection of static variables not stored into database
   */


  /*
   * Contacts
   */

  /* Types of contact */
  static contact_types = [
    {label: "Par défaut", value: "default"},
    {label: "Facturation", value: "invoice"},
    {label: "Livraison", value: "delivery"},
    {label: "Contact", value: "contact"},
    {label: "Autre", value: "other"},
  ]

  /* Types of contact */
  static contact_titles = [
    {label: "Monsieur", value: "5"},
    {label: "Madame", value: "3"},
    {label: "Mademoiselle", value: "4"},
  ]

}

