import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {PartnerTable} from './PartnerTable'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputText} from 'primereact/inputtext'
import '../../components/Forms.css'

class GroupForm extends AuthenticatedForm {

  constructor (props) {
    super (props, 'group', '/Groupes', 'Groups',
      {
        group: {
          id: props.match.params.id,
          name: "",
          customer: false,
          supplier: true,
          group_id: 0,
          group: "",
          is_parent: "true",
        },

        mandatoryFields: {
          name: {test: FormContainer.checkNotEmpty, state: false}
        },
      }
    )

    this.getNewPartnersList = this.getNewPartnersList.bind(this)

  }


  async getNewPartnersList (template) {
    const maxValues = 5
    const filters = {
      name : {value : template},
      group_id : null
    }
    const partners = await this.apiCall ('Partners', 'list', 0, maxValues, false, false, filters)
    return partners
  }


  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Fiche Groupe</h1>
                  <FormContainer
                    values={this.state.group}
                    mandatoryFields={this.state.mandatoryFields}
                    onEdit={this.handleEdit}
                    onUpdate={this.handleUpdate}
                    onExit={this.handleExit}
                    setValues={this.setValues}
                    getValues={this.getValues}
                    browser={this.state.browser}
                    onBrowse={this.handleBrowse}
                  >
                    <div className="form-new-line p-grid">

                      <div className="p-grid p-col-12 p-md-8">
                        <div className="p-col-12">
                          <MandatoryLabel htmlFor="name" isMissing={this.state.mandatoryFields.name.state}>Nom</MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            autoComplete="off"
                            disabled={!this.state.editMode}
                            id="name"
                            value={this.state.group.name}
                            onChange={this.handleInputText}
                            onBlur={this.cleanUpLastName}
                          />
                        </div>
                      </div>

                      <div className="p-grid p-col-12">
                        <div className="p-col-12">
                          <label htmlFor="partners">Partenaires membres</label>
                        </div>
                        <div className="p-col-12">
                          <PartnerTable
                            id="partners"
                            addItemGet={this.getNewPartnersList}
                            onAdd={this.handleAdd}
                            onRemove={this.handleRemove}
                            groupId={this.state.group.id}
                            locked={this.state.editMode}
                            getContext={this.getContext}
                          />
                        </div>
                      </div>

                    </div>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GroupForm
