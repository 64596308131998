import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputText} from 'primereact/inputtext'
import {Calendar} from 'primereact/calendar'
import '../../components/Forms.css'

class GpecSalaryIncreaseForm extends AuthenticatedForm {

  constructor (props) {
    super(props, 'gpecSalaryIncrease', '/GPEC/Evolution salariale', 'GpecEmployeesSalaryIncreases',
      {
        gpecSalaryIncrease: {
          id: props.match.params.id,
          employee_id: (props.match.params.employee_id ? props.match.params.employee_id : ""),
          employee: (props.match.params.employee_name ? props.match.params.employee_name : ""),
          increase_percent: "",
          former_salary: "0",
          former_hours: "",
          former_hourly_salary_rate: "",
          former_coefficient: "",
          former_position: "",
          current_salary: "",
          current_hours: "",
          current_hourly_salary_rate: "",
          current_coefficient: "",
          current_position: "",

          date: new Date (),
        },

        mandatoryFields: {
          former_salary: {test: FormContainer.checkEuro, state: false},
          current_salary: {test: FormContainer.checkEuro, state: false},
          former_coefficient: {test: t => FormContainer.checkInteger (t, true), state: false},
          current_coefficient: {test: t => FormContainer.checkInteger (t, true), state: false},
          former_hours: {test: t => FormContainer.checkNumber (t, true), state: false},
          current_hours: {test: t => FormContainer.checkNumber (t, true), state: false},
        },

      }
    )

    this.handleHourlySalaryRate = this.handleHourlySalaryRate.bind(this)
  }


  /*
   * Get external values :
   *  - Position list
   */

  async getExternalValues () {
    if ( this.state.gpecSalaryIncrease.id === '0' ) {
      const defaults = await this.getRecord ({sortField: 'date', sortOrder: -1}, null, {employee_id: {value : this.state.gpecSalaryIncrease.employee_id}})
      if ( defaults ) {
        this.setState (
          prevState => {
            prevState.gpecSalaryIncrease.former_salary = defaults.current_salary
            prevState.gpecSalaryIncrease.former_hours = defaults.current_hours
            prevState.gpecSalaryIncrease.former_hourly_salary_rate = defaults.current_hourly_salary_rate
            prevState.gpecSalaryIncrease.former_coefficient = defaults.current_coefficient
            prevState.gpecSalaryIncrease.former_position = defaults.current_position
            return ( {gpecSalaryIncrease: prevState.gpecSalaryIncrease} )
          }
        )
      }
    }
  }

  /*
   * Adjust server values
   * - Dates are encoded as an ISO 8601 string then converted to date object
   * - Salaries are transmited as real numbers without any currency format
   */
  cleanUpValues (values) {
    if ( values.date ) {
      values.date = new Date (values.date)
    }
    if ( values.increase_percent && !isNaN (values.increase_percent) ) {
        const formatter = new Intl.NumberFormat('fr-FR', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
        values.increase_percent = formatter.format(values.increase_percent / 100)
    } else {
      values.increase_percent = ""
    }
  }



  handleHourlySalaryRate (e) {
    const f = e.target.id
    const v = e.target.value
    this.handleInputText (e)

    const former_salary = String( f === 'former_salary' ? v : this.state.gpecSalaryIncrease.former_salary).replace(',','.').replace(/[\s€]/g,'')
    const former_hours = String( f === 'former_hours' ? v : this.state.gpecSalaryIncrease.former_hours).replace(',','.').replace(/[\s€]/g,'')
    const current_salary = String( f === 'current_salary' ? v : this.state.gpecSalaryIncrease.current_salary).replace(',','.').replace(/[\s€]/g,'')
    const current_hours = String( f === 'current_hours' ? v : this.state.gpecSalaryIncrease.current_hours).replace(',','.').replace(/[\s€]/g,'')

    this.setState (
      prevState => {
        if ( former_salary && parseFloat (former_salary) && current_salary && !isNaN (former_salary) && !isNaN (current_salary) ) {
          const formatter = new Intl.NumberFormat('fr-FR', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
          prevState.gpecSalaryIncrease.increase_percent = formatter.format((current_salary - former_salary) / former_salary)
        } else {
          prevState.gpecSalaryIncrease.increase_percent = ""
        }
        if ( former_hours && parseFloat (former_hours) && former_salary && !isNaN (former_hours) && !isNaN (former_salary) ) {
          const formatter = new Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'EUR' })
          prevState.gpecSalaryIncrease.former_hourly_salary_rate = formatter.format (former_salary / former_hours)
        } else {
          prevState.gpecSalaryIncrease.former_hourly_salary_rate = ""
        }
        if ( current_hours && parseFloat (current_hours) && current_salary && !isNaN (current_hours) && !isNaN (current_salary) ) {
          const formatter = new Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'EUR' })
          prevState.gpecSalaryIncrease.current_hourly_salary_rate = formatter.format (current_salary / current_hours)
        } else {
          prevState.gpecSalaryIncrease.current_hourly_salary_rate = ""
        }
        return (
          {gpecSalaryIncrease: prevState.gpecSalaryIncrease}
        )
      }
    )
  }

  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Evolution salariale de {this.state.gpecSalaryIncrease.employee}</h1>
                <FormContainer
                  values={this.state.gpecSalaryIncrease}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <div className="p-grid">

                    <div className="p-col-12 p-grid">
                      <div className="p-col-12 p-grid p-md-2">
                        <div className="p-col-12">
                          <MandatoryLabel
                            htmlFor="former_coefficient"
                            isMissing={this.state.mandatoryFields.former_coefficient.state}
                            reminder=""
                          >
                            Ancien coefficient
                          </MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            id="former_coefficient"
                            disabled={!this.state.editMode}
                            value={this.state.gpecSalaryIncrease.former_coefficient}
                            onChange={this.handleInputText}
                            onBlur={this.cleanUpNumber}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-md-3">
                        <div className="p-col-12">
                          <label htmlFor="former_position">Ancienne position</label>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            id="former_position"
                            disabled={!this.state.editMode}
                            value={this.state.gpecSalaryIncrease.former_position}
                            onChange={this.handleInputText}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-md-2">
                        <div className="p-col-12">
                          <MandatoryLabel
                            htmlFor="current_coefficient"
                            isMissing={this.state.mandatoryFields.current_coefficient.state}
                            reminder=""
                          >
                            Nouveau coefficient
                          </MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            id="current_coefficient"
                            disabled={!this.state.editMode}
                            value={this.state.gpecSalaryIncrease.current_coefficient}
                            onChange={this.handleInputText}
                            onBlur={this.cleanUpNumber}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-md-3">
                        <div className="p-col-12">
                          <label htmlFor="current_position">Nouvelle position</label>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            id="current_position"
                            disabled={!this.state.editMode}
                            value={this.state.gpecSalaryIncrease.current_position}
                            onChange={this.handleInputText}
                          />
                        </div>
                      </div>
                    </div>


                    <div className="p-col-12 p-grid">
                      <div className="p-grid p-col-12 p-md-3">
                        <div className="p-col-12">
                          <MandatoryLabel
                            htmlFor="former_salary"
                            isMissing={this.state.mandatoryFields.former_salary.state}
                          >
                            Ancien salaire
                          </MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="former_salary"
                            value={this.state.gpecSalaryIncrease.former_salary}
                            onChange={this.handleHourlySalaryRate}
                            onBlur={this.cleanUpEuro}
                          />
                        </div>
                      </div>
                      <div className="p-grid p-col-12 p-md-3">
                        <div className="p-col-12">
                          <MandatoryLabel
                            htmlFor="current_salary"
                            isMissing={this.state.mandatoryFields.current_salary.state}
                          >
                            Nouveau salaire
                          </MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="current_salary"
                            value={this.state.gpecSalaryIncrease.current_salary}
                            onChange={this.handleHourlySalaryRate}
                            onBlur={this.cleanUpEuro}
                          />
                        </div>
                      </div>

                      <div className="p-grid p-col-12 p-md-2">
                        <div className="p-col-12">
                          <label htmlFor="increase_percent">Pourcentage</label>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            disabled={true}
                            id="increase_percent"
                            value={this.state.gpecSalaryIncrease.increase_percent}
                          />
                        </div>
                      </div>
                    </div>


                    <div className="p-col-12 p-grid">
                      <div className="p-col-12 p-grid p-md-3">
                        <div className="p-col-12">
                          <MandatoryLabel
                            htmlFor="former_hours"
                            isMissing={this.state.mandatoryFields.former_hours.state}
                            reminder=""
                          >
                            Ancien nombre d'heures travaillées
                          </MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            id="former_hours"
                            disabled={!this.state.editMode}
                            value={this.state.gpecSalaryIncrease.former_hours}
                            onChange={this.handleHourlySalaryRate}
                            onBlur={this.cleanUpNumber}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-md-2">
                        <div className="p-col-12">
                          <label htmlFor="former_hourly_salary_rate">
                            Ancien Taux horaire
                          </label>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            id="former_hourly_salary_rate"
                            disabled={true}
                            value={this.state.gpecSalaryIncrease.former_hourly_salary_rate}
                            onChange={this.handleInputText}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-md-3">
                        <div className="p-col-12">
                          <MandatoryLabel
                            htmlFor="current_hours"
                            isMissing={this.state.mandatoryFields.current_hours.state}
                            reminder=""
                          >
                            Nouveau nombre d'heures travaillées
                          </MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            id="current_hours"
                            disabled={!this.state.editMode}
                            value={this.state.gpecSalaryIncrease.current_hours}
                            onChange={this.handleHourlySalaryRate}
                            onBlur={this.cleanUpNumber}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-md-2">
                        <div className="p-col-12">
                          <label htmlFor="current_hourly_salary_rate">
                            Nouveau Taux horaire
                          </label>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            id="current_hourly_salary_rate"
                            disabled={true}
                            value={this.state.gpecSalaryIncrease.current_hourly_salary_rate}
                            onChange={this.handleInputText}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="p-grid p-col-12 p-md-5">
                      <div className="p-col-12">
                        <label htmlFor="date">Date de l'évolution</label>
                      </div>
                      <div className="p-col-12">
                        <Calendar
                          className="form-input"
                          disabled={!this.state.editMode}
                          id="date"
                          value={this.state.gpecSalaryIncrease.date}
                          onChange={this.handleCalendar}
                          placeHolder=""
                          showIcon={true}
                          showButtonBar={true}
                          clearButtonStyleClass="form-hidden"
                          readOnlyInput={true}
                          monthNavigator={true}
                          yearNavigator={true}
                          yearRange="1900:2100"
                          dateFormat="dd/mm/yy"
                          locale={FormContainer.localDate('fr')}
                        ></Calendar>
                      </div>
                    </div>

                  </div>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GpecSalaryIncreaseForm
