import React, { Component } from 'react'
import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'
import {AuthContext} from '../../helpers/AuthContext'

class Login extends Component {

  constructor(props){
    super(props)
    this.state = {
      username : '',
      password : '',
      alert : false,
      message : '',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  static contextType = AuthContext

  async handleFormSubmit(event){
    event.preventDefault()
    try {
      await this.context.apiCall ('Login', 'login', this.state.username, this.state.password)
      this.props.history.replace('/')
    } catch(err) {
      this.setState (
        {
          alert : true,
          message : err,
        }
      )
    }
  }

  handleChange(event){
    this.setState({[event.target.name]: event.target.value})
  }

  componentDidMount(){
    if(this.context.loggedIn()) {
      this.props.history.replace('/')
    }
  }

  render_alert() {
    const dialogFooter = (
      <div>
          <Button onClick={() => this.setState({alert:false})} label="Ok" className="p-button" />
      </div>
    )
    return (
      <Dialog
        header="Echec de l'authentification."
        visible={this.state.alert}
        modal={true}
        footer={dialogFooter}
        closable={false}
        onHide={() => this.setState({alert:false})}
      >
        <p>{this.state.message}</p>
      </Dialog>
    )
  }

  render() {
    return (
      <div className="login-center">
        <div className="login-card">
          <h1>Identification</h1>
          <form onSubmit={this.handleFormSubmit}>
            <input
              className="login-form-item"
              placeholder="Identifiant ERP ..."
              name="username"
              type="text"
              onChange={this.handleChange}
            />
            <input
              className="login-form-item"
              placeholder="Mot de passe ..."
              name="password"
              type="password"
              onChange={this.handleChange}
            />
            <input
              className="login-form-submit"
              value="CONNEXION"
              type="submit"
            />
          </form>
        </div>
        {this.render_alert()}
      </div>
    )
  }
}

export default Login
