import React from 'react';
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import {PartnerTable} from './PartnerTable'

class PartnerList extends AuthenticatedComponent {

  doRender() {
    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Partenaires</h1>

              <div className="p-grid">
                <div className="p-col-12">
                  <PartnerTable />
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default PartnerList
