import React from 'react';
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

class ContactData extends AuthenticatedTable {

  constructor(props) {
    super(props, 'contactData', '/Données/Contacts/', 'Contacts')
  }


  doRender() {

    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Contacts</h1>

              <div className="p-grid">
                <div className="p-col-12">
                  <LazyDataTable
                    ref={this.setRef}
                    locked={this.props.locked}
                    onOpen={this.handleOpen}
                    onDisable={this.handleDelete}
                    onEnable={this.handleRestore}
                    getValues={this.getAdminValues}
                    watch={this.state.profile}
                  >
                    <Column field="name" header="Nom" sortable={true} style={{width:'20%'}} />
                    <Column field="partner" header="Partenaire" sortable={true} style={{width:'20%'}} />
                    <Column field="creation_date"
                      header="Créé le"
                      filterMethod={LazyDataTable.dateFilterTZ}
                      sortable={true}
                      style={{width:'13%'}}
                      body={LazyDataTable.dateTemplate}
                    />
                    <Column field="creation_user" header="Par" sortable={true} style={{width:'13%'}} />
                    <Column field="modification_date"
                      header="Modifié le"
                      filterMethod={LazyDataTable.dateFilterTZ}
                      sortable={true}
                      style={{width:'13%'}}
                      body={LazyDataTable.dateTemplate}
                    />
                    <Column field="modification_user" header="Par" sortable={true} style={{width:'13%'}} />
                    <Column field="active"
                      header="Actif"
                      sortable={true}
                      filterMethod={LazyDataTable.optionsFilter}
                      filterParams={LazyDataTable.booleanDefaultOptions}
                      body={(rawData, column) => {return LazyDataTable.booleanTemplate(rawData, column)}}
                      style={{textAlign:'center', width:'8%'}}
                    />
                  </LazyDataTable>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default ContactData
