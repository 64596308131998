import React from 'react';
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

export class GpecTrainingTable extends AuthenticatedTable {

  constructor(props) {
    super(props, 'gpecTraining', '/GPEC/Formations', 'GpecEmployeesTrainings',
      {
        training_list: [],
      }
    )
  }

  async getExternalValues () {
    const trainings = await this.getOptionList (null, 'GpecTrainings')

    this.setState (
      {
        training_list: trainings.values.map (p => {return {label: p.name, value: p.id, ...p}}),
      }
    )
  }

  // Overrinding getValue to handle employeeId and state props
  getValues (first=0, rows=0, sort=null, filter=null, filters=null) {
    if ( this.props.employeeId ) {
      filters = this.defaultFilters (filters, {employee_id: {value : this.props.employeeId}})
    }
    if ( this.props.state ) {
      filters = this.defaultFilters (filters, {state: {value : this.props.state}})
    }
    return super.getValues (first, rows, sort, filter, filters)
  }


  // Overrinding handleCreate to handle employeeId employeeName and state props
  handleCreate () {
    super.handleCreate (this.addParameters (this.props.state, this.props.employeeId, this.props.employeeName))
  }

  // Overrinding handleOpen to handle state props
  handleOpen (event, browser=null) {
    super.handleOpen (event, browser, this.addParameters (this.props.state))
  }

  doRender () {

    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        maxRowPerPage={3}
        //RowPerPageOptions={[3,9,12,15,18,21]}
        noSearch={true}
        onOpen={this.handleOpen}
        //onDelete={this.handleDelete}
        onCreate={this.handleCreate}
        //onRemove={this.props.onRemove}
        //onAdd={this.props.onAdd}
        //addItemGet={this.props.addItemGet}
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column
          field="training"
          header="Formation"
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.training_list}
          sortable={true}
          style={{width:'60%'}}
        />

        <Column field="date"
          if={this.props.state === 'pending'}
          header="A prévoir avant le"
          filterMethod={LazyDataTable.dateFilterTZ}
          sortable={true}
          style={{width:'20%'}}
          body={LazyDataTable.dateTemplate}
        />

        <Column field="date"
          if={this.props.state === 'scheduled'}
          header="Planifiée le"
          filterMethod={LazyDataTable.dateFilterTZ}
          sortable={true}
          style={{width:'20%'}}
          body={LazyDataTable.dateTemplate}
        />

        <Column field="date"
          if={this.props.state === 'done'}
          header="Validée le"
          filterMethod={LazyDataTable.dateFilterTZ}
          sortable={true}
          style={{width:'20%'}}
          body={LazyDataTable.dateTemplate}
        />
        <Column field="date_validity"
          if={this.props.state === 'done'}
          header="Exipre le"
          filterMethod={LazyDataTable.dateFilterTZ}
          sortable={true}
          style={{width:'20%'}}
          body={LazyDataTable.dateTemplate}
        />
      </LazyDataTable>
    )
  }

}

