import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {Display} from '../../components/Display'
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown'
import {Calendar} from 'primereact/calendar'
import '../../components/Forms.css'
import {StaticData} from './StaticData'

class GpecTrainingForm extends AuthenticatedForm {

  constructor (props) {
    super(props, 'gpecTraining', '/GPEC/Formations', 'GpecEmployeesTrainings',
      {
        gpecTraining: {
          id: props.match.params.id,
          employee_id: (props.match.params.employee_id ? props.match.params.employee_id : ""),
          employee: (props.match.params.employee_name ? props.match.params.employee_name : ""),
          training_id: 0,
          training: "",
          state: (props.match.params.state ? props.match.params.state : ""),
          internal_hours: "0",
          external_hours: "0",
          date_pending: "",
          date_scheduled: "",
          date_done: "",
          date_validity: "",
        },

        mandatoryFields: {
          training: {test: FormContainer.checkNotEmpty, state: false},
          state: {test: FormContainer.checkNotEmpty, state: false},
          date_pending: {test: t => FormContainer.checkNotEmpty (t) || this.state.gpecTraining.state !== 'pending' , state: false},
          date_scheduled: {test: t => FormContainer.checkNotEmpty (t) || this.state.gpecTraining.state !== 'scheduled' , state: false},
          date_done: {test: t => FormContainer.checkNotEmpty (t) || this.state.gpecTraining.state !== 'done' , state: false},
        },

        training_list : [],
        state : StaticData.training_states,
      }
    )
  }


  /*
   * Get external values :
   *  - Position list
   */

  async getExternalValues () {
    const trainings = await this.apiCall ('GpecTrainings', 'list')
    await this.setState ({training_list: trainings.values})
  }

  /*
   * Adjust server values
   * - Dates are encoded as an ISO 8601 string then converted to date object
   * - Salaries are transmited as real numbers without any currency format
   */
  cleanUpValues (values) {
    if ( values.date_pending ) {
      values.date_pending = new Date (values.date_pending)
    }
    if ( values.date_scheduled ) {
      values.date_scheduled = new Date (values.date_scheduled)
    }
    if ( values.date_done ) {
      values.date_done = new Date (values.date_done)
    }
    if ( values.date_validity ) {
      values.date_validity = new Date (values.date_validity)
    }
  }


  doRender() {
    let title = ''
    switch (this.state.gpecTraining.state) {
      case 'pending' :
        title = 'Formation à prévoir pour'
        break
      case 'scheduled' :
        title = 'Formation prévue pour'
        break
      case 'done' :
        title = 'Formation effectuée par'
        break
      default :
        title = 'Formation de'
    }
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>{title} {this.state.gpecTraining.employee}</h1>
                  <FormContainer
                    values={this.state.gpecTraining}
                    mandatoryFields={this.state.mandatoryFields}
                    onEdit={this.handleEdit}
                    onUpdate={this.handleUpdate}
                    onExit={this.handleExit}
                    setValues={this.setValues}
                    getValues={this.getValues}
                    browser={this.state.browser}
                    onBrowse={this.handleBrowse}
                  >
                    <div className="p-grid">

                      <div className="p-grid p-col-12 p-md-5">
                        <div className="p-col-12">
                          <MandatoryLabel htmlFor="training" isMissing={this.state.mandatoryFields.training.state}>Libellé</MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <Dropdown
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="training"
                            options={this.state.training_list.map (p => ({label: p.name, value: p.id}))}
                            value={this.state.gpecTraining.training_id}
                            onChange={this.handleExternalSelect}
                            autoWidth={false}
                          />
                        </div>
                      </div>

                      <div className="p-grid p-col-12 p-md-6">
                        <div className="p-col-12">
                          <MandatoryLabel htmlFor="state" isMissing={this.state.mandatoryFields.state.state}>Etat actuel</MandatoryLabel>
                        </div>
                        <div className="p-col-4">
                          <Dropdown
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="state"
                            options={this.state.state}
                            value={this.state.gpecTraining.state}
                            onChange={this.handleSelect}
                            autoWidth={false}
                          />
                        </div>
                      </div>

                      <Display if={this.state.gpecTraining.state === 'pending'}>
                        <div className="p-grid p-col-12 p-md-5">
                          <div className="p-col-12">
                            <MandatoryLabel
                              htmlFor="date_pending"
                              isMissing={this.state.mandatoryFields.date_pending.state}
                              reminder="*"
                            >
                              Date cible
                            </MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <Calendar
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="date_pending"
                              value={this.state.gpecTraining.date_pending}
                              onChange={this.handleCalendar}
                              placeHolder=""
                              showIcon={true}
                              showButtonBar={true}
                              clearButtonStyleClass="form-hidden"
                              readOnlyInput={true}
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange="1900:2100"
                              dateFormat="dd/mm/yy"
                              locale={FormContainer.localDate('fr')}
                            ></Calendar>
                          </div>
                        </div>
                      </Display>

                      <Display if={this.state.gpecTraining.state === 'scheduled'}>
                        <div className="p-grid p-col-12 p-md-5">
                          <div className="p-col-12">
                            <MandatoryLabel
                              htmlFor="date_scheduled"
                              isMissing={this.state.mandatoryFields.date_scheduled.state}
                              reminder="*"
                            >
                              Planifiée le
                            </MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <Calendar
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="date_scheduled"
                              value={this.state.gpecTraining.date_scheduled}
                              onChange={this.handleCalendar}
                              placeHolder=""
                              showIcon={true}
                              showButtonBar={true}
                              clearButtonStyleClass="form-hidden"
                              readOnlyInput={true}
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange="1900:2100"
                              dateFormat="dd/mm/yy"
                              locale={FormContainer.localDate('fr')}
                            ></Calendar>
                          </div>
                        </div>
                      </Display>

                      <Display if={this.state.gpecTraining.state === 'done'}>
                        <div className="p-grid p-col-12 p-md-5">
                          <div className="p-col-12">
                            <MandatoryLabel
                              htmlFor="date_done"
                              isMissing={this.state.mandatoryFields.date_done.state}
                              reminder="*"
                            >
                              Effectuée le
                            </MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <Calendar
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="date_done"
                              value={this.state.gpecTraining.date_done}
                              onChange={this.handleCalendar}
                              placeHolder=""
                              showIcon={true}
                              showButtonBar={true}
                              clearButtonStyleClass="form-hidden"
                              readOnlyInput={true}
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange="1900:2100"
                              dateFormat="dd/mm/yy"
                              locale={FormContainer.localDate('fr')}
                            ></Calendar>
                          </div>
                        </div>

                        <div className="p-grid p-col-12 p-md-2">
                          <div className="p-col-12">
                            <label htmlFor="internal_hours">Nombre d'heures internes</label>
                          </div>
                          <div className="p-col-12">
                            <InputText
                              className="form-input"
                              id="internal_hours"
                              value={this.state.gpecTraining.internal_hours}
                              onChange={this.handleInputText}
                              onBlur={this.cleanUpNumber}
                            />
                          </div>
                        </div>

                        <div className="p-grid p-col-12 p-md-2">
                          <div className="p-col-12">
                            <label htmlFor="external_hours">Nombre d'heures externes</label>
                          </div>
                          <div className="p-col-12">
                            <InputText
                              className="form-input"
                              id="external_hours"
                              value={this.state.gpecTraining.external_hours}
                              onChange={this.handleInputText}
                              onBlur={this.cleanUpNumber}
                            />
                          </div>
                        </div>

                        <div className="p-grid p-col-12 p-md-5">
                          <div className="p-col-12">
                            <label htmlFor="date_validity">Valide jusqu'au</label>
                          </div>
                          <div className="p-col-12">
                            <Calendar
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="date_validity"
                              value={this.state.gpecTraining.date_validity}
                              onChange={this.handleCalendar}
                              placeHolder=""
                              showIcon={true}
                              showButtonBar={true}
                              clearButtonStyleClass="form-hidden"
                              readOnlyInput={true}
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange="1900:2100"
                              dateFormat="dd/mm/yy"
                              locale={FormContainer.localDate('fr')}
                            ></Calendar>
                          </div>
                        </div>
                      </Display>

                    </div>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GpecTrainingForm
