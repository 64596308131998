export class StaticData {

  /*
   *  Collection of static variables not stored into database
   */


  /*
   * Training states
   */

  static training_states = [
    {label: "Programmée", value: "scheduled"},
    {label: "A prévoir", value: "pending"},
    {label: "Annulée", value: "canceled"},
    {label: "Terminée", value: "done"},
  ]


  /*
   * Meeting types
   */

  static meeting_types = [
    {label: "Entretien pro", value: "professional"},
    {label: "Entretien annuel", value: "annual"},
  ]

  static meeting_next = {
    "professional": 2,
    "annual": 1,
  }

  /*
   * Professionnal evolution state
   */
  static professional_evolution_states = [
    {label : 'En cours', value : 'pending'},
    {label : 'Réalisé', value : 'done'},
  ]

  static professional_evolution_state_options = [
    ...StaticData.professional_evolution_states,
    {label : 'Tous', value : ''}
  ]

}

