import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import AuthService from './services/AuthService'
import {AuthContext} from './helpers/AuthContext'
import Login from './modules/login/Login'

import { Switch, Route, HashRouter as Router } from 'react-router-dom'

// Import configuration
const config = require('./config.json')

// Instanciate Auth service
const auth = new AuthService(config)

/* Main application start entry */
ReactDOM.render(
  <Router >
    <AuthContext.Provider value={auth} >
      <Switch>
        <Route path="/login" component={Login} />
        <Route component={App} />
      </Switch>
    </AuthContext.Provider>
  </Router>,
  document.getElementById('root')
)
