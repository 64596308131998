import React from 'react';
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column';

class ProductList extends AuthenticatedTable {

  constructor(props) {
    super(props, 'product', '/Produits', 'Products')

  }

  doRender() {
    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Gestion des produits</h1>

              <div className="p-grid">
                <div className="p-col-12">
                  <LazyDataTable
                    getValues={this.getValues}
                  >
                    <Column field="default_code" header="Référence" sortable={true} style={{width:'20%'}} />
                    <Column field="name" header="Description" sortable={true} style={{width:'50%'}}/>
                    <Column field="list_price" header="Prix de vente" sortable={true} style={{width:'20%'}}/>
                    <Column
                      field="sale_ok"
                      header="Vendu"
                      sortable={true}
                      //body={(rawData, column) => {return LazyDataTable.booleanTemplate(rawData, column)}}
                      body={LazyDataTable.booleanTemplate}
                      style={{textAlign:'center', width:'10%'}}
                    />
                  </LazyDataTable>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default ProductList
