export class DataFiles {

  /*
   * Convert Base64 raw data to a blob object
   */

  static b64ToBlob (b64Data, contentType='', sliceSize=512) {

    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, {type: contentType})
    return blob
  }

  /*
   * Convert Base64 raw data to a file object
   */

  static b64ToFile (b64Data, name, contentType='', sliceSize=512) {

    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const file = new File(byteArrays, name, {lastModified: new Date(), type: contentType})
    return file
  }


  /*
   * Returns an Url of a file image
   * if maxWidth and maxHeight are not null, the image is resized
   */
  static fileToUrl (file=null, maxWidth=0, maxHeight=0) {

    const url = window.URL.createObjectURL(file)
    if ( ! maxWidth && ! maxHeight ) {
      return url
    }

    if ( ! maxWidth ) {
      maxWidth = maxHeight
    }
    if ( maxHeight ) {
      maxHeight = maxWidth
    }

    return new Promise (

      (resolve, reject) => {

        const canva = document.createElement('canvas')
        const graphicContext = canva.getContext('2d')
        const image = new window.Image()

        image.onload = () => {

          if (image.width > image.height) {
            if (image.width > maxWidth) {
              image.height *= maxWidth / image.width
              image.width = maxWidth
            }
          } else {
            if (image.height > maxHeight) {
              image.width *= maxHeight / image.height;
              image.height = maxHeight;
            }
          }

          canva.width = image.width
          canva.height = image.height

          graphicContext.drawImage(image, 0, 0, canva.width, canva.height)
          const result = graphicContext.canvas.toDataURL(file.type)
          if ( result ) {
            resolve (result)
          } else {
            reject (null)
          }
        }
        image.src = url
      }
    )
  }

  /*
   * Returns a File object from a b64 encoded Url
   */
  static b64UrlToFile (url, filename) {

    const arr = url.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt (n);
    }

    //return new File ( [u8arr], filename, {type:mime})
    const file =  new Blob ( [u8arr], {type:mime})
    file.name = filename
    return file
  }

}
