import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown'
import {Calendar} from 'primereact/calendar'
import '../../components/Forms.css'
import {StaticData} from './StaticData'

class GpecProfessionalEvolutionForm extends AuthenticatedForm {

  constructor (props) {
    super(props, 'gpecProfessionalEvolution', '/GPEC/Evolution professionnelle', 'GpecEmployeesProfessionalEvolutions',
      {
        gpecProfessionalEvolution: {
          id: props.match.params.id,
          employee_id: (props.match.params.employee_id ? props.match.params.employee_id : ""),
          employee: (props.match.params.employee_name ? props.match.params.employee_name : ""),
          evolution: "",
          assistance_id: 0,
          assistance: "",
          hours: "0",
          former_level_id: 0,
          former_level: "",
          former_degree_id: 0,
          former_degree: "",
          current_level_id: 0,
          current_level: "",
          current_degree_id: 0,
          current_degree: "",
          state: "pending",
          date: "",
        },

        mandatoryFields: {
          evolution: {test: FormContainer.checkNotEmpty, state: false},
          state: {test: FormContainer.checkNotEmpty, state: false},
        },

        assistance_list : [],
        former_level_list: [],
        former_degree_list: [],
        current_level_list: [],
        current_degree_list: [],
        state : StaticData.professional_evolution_states,
      }
    )
  }

  /*
   * Get external values :
   *  - Position list
   */

  async getExternalValues () {

    const assistances = await this.apiCall ('GpecAssistances', 'list')
    const levels = await this.apiCall ('GpecLevels', 'list')
    const degrees = await this.apiCall ('GpecDegrees', 'list')

    let defaults = null
    if ( this.state.gpecProfessionalEvolution.id === '0' ) {
      defaults = await this.getRecord ({sortField: 'date', sortOrder: -1}, null, {employee_id: {value : this.state.gpecProfessionalEvolution.employee_id}})
    }

    await this.setState (
      prevState => {

        if (defaults) {
          if ( defaults.state === 'done' ) {
            prevState.gpecProfessionalEvolution.former_level_id = defaults.current_level_id
            prevState.gpecProfessionalEvolution.former_level = defaults.current_level
            prevState.gpecProfessionalEvolution.former_degree_id = defaults.current_degree_id
            prevState.gpecProfessionalEvolution.former_degree = defaults.current_degree
            prevState.gpecProfessionalEvolution.current_level_id = defaults.current_level_id
            prevState.gpecProfessionalEvolution.current_level = defaults.current_level
            prevState.gpecProfessionalEvolution.current_degree_id = defaults.current_degree_id
            prevState.gpecProfessionalEvolution.current_degree = defaults.current_degree
          } else {
            prevState.gpecProfessionalEvolution.former_level_id = defaults.former_level_id
            prevState.gpecProfessionalEvolution.former_level = defaults.former_level
            prevState.gpecProfessionalEvolution.former_degree_id = defaults.former_degree_id
            prevState.gpecProfessionalEvolution.former_degree = defaults.former_degree
            prevState.gpecProfessionalEvolution.current_level_id = defaults.former_level_id
            prevState.gpecProfessionalEvolution.current_level = defaults.former_level
            prevState.gpecProfessionalEvolution.current_degree_id = defaults.former_degree_id
            prevState.gpecProfessionalEvolution.current_degree = defaults.former_degree
          }
        }

        return (
          {
            assistance_list: assistances.values,
            former_level_list: levels.values,
            current_level_list: levels.values,
            former_degree_list: degrees.values,
            current_degree_list: degrees.values,
            gpecProfessionalEvolution : prevState.gpecProfessionalEvolution,
          }
        )
      }
    )

  }

  /*
   * Adjust server values
   * - Dates are encoded as an ISO 8601 string then converted to date object
   */
  cleanUpValues (values) {
    if ( values.date ) {
      values.date = new Date (values.date)
    }
  }


  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Evolution professionnelle de {this.state.gpecProfessionalEvolution.employee}</h1>
                  <FormContainer
                    values={this.state.gpecProfessionalEvolution}
                    mandatoryFields={this.state.mandatoryFields}
                    onEdit={this.handleEdit}
                    onUpdate={this.handleUpdate}
                    onExit={this.handleExit}
                    setValues={this.setValues}
                    getValues={this.getValues}
                    browser={this.state.browser}
                    onBrowse={this.handleBrowse}
                  >
                    <div className="p-grid">

                      <div className="p-grid p-col-12">
                        <div className="p-grid p-col-12 p-md-6">
                          <div className="p-col-12">
                            <MandatoryLabel htmlFor="evolution" isMissing={this.state.mandatoryFields.evolution.state}>Description</MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <InputText
                              className="form-input"
                              autoComplete="off"
                              disabled={!this.state.editMode}
                              id="evolution"
                              value={this.state.gpecProfessionalEvolution.evolution}
                              onChange={this.handleInputText}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="p-grid p-col-12">
                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <label htmlFor="assistance">Accompagnement</label>
                          </div>
                          <div className="p-col-12">
                            <Dropdown
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="assistance"
                              options={this.state.assistance_list.map (a => ({label: a.name, value: a.id}))}
                              value={this.state.gpecProfessionalEvolution.assistance_id}
                              onChange={this.handleExternalSelect}
                              autoWidth={false}
                            />
                          </div>
                        </div>

                        <div className="p-grid p-col-12 p-md-2">
                          <div className="p-col-12">
                            <label htmlFor="hours">Nombre d'heures</label>
                          </div>
                          <div className="p-col-12">
                            <InputText
                              className="form-input"
                              id="hours"
                              value={this.state.gpecProfessionalEvolution.hours}
                              onChange={this.handleInputText}
                              onBlur={this.cleanUpNumber}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="p-grid p-col-12">
                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <MandatoryLabel htmlFor="state" isMissing={this.state.mandatoryFields.state.state}>Etat</MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <Dropdown
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="state"
                              options={this.state.state}
                              value={this.state.gpecProfessionalEvolution.state}
                              onChange={this.handleSelect}
                              autoWidth={false}
                            />
                          </div>
                        </div>

                        <div className="p-grid p-col-12 p-md-2">
                          <div className="p-col-12">
                            <label htmlFor="date"> { this.state.gpecProfessionalEvolution.state === 'done' ? "Date de réalisation" : "Date cible" } </label>
                          </div>
                          <div className="p-col-12">
                            <Calendar
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="date"
                              value={this.state.gpecProfessionalEvolution.date}
                              onChange={this.handleCalendar}
                              placeHolder=""
                              showIcon={true}
                              showButtonBar={true}
                              clearButtonStyleClass="form-hidden"
                              readOnlyInput={true}
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange="1900:2100"
                              dateFormat="dd/mm/yy"
                              locale={FormContainer.localDate('fr')}
                            ></Calendar>
                          </div>
                        </div>

                      </div>

                      <div className="p-grid p-col-12">
                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <label htmlFor="former_level">Niveau à l'entréé</label>
                          </div>
                          <div className="p-col-12">
                            <Dropdown
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="former_level"
                              options={this.state.former_level_list.map (l => ({label: l.name, value: l.id}))}
                              value={this.state.gpecProfessionalEvolution.former_level_id}
                              onChange={this.handleExternalSelect}
                              autoWidth={false}
                            />
                          </div>
                        </div>

                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <label htmlFor="current_level">
                              { this.state.gpecProfessionalEvolution.state === 'done' ? "Niveau atteint" : "Niveau visé" }
                            </label>
                          </div>
                          <div className="p-col-12">
                            <Dropdown
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="current_level"
                              options={this.state.current_level_list.map (l => ({label: l.name, value: l.id}))}
                              value={this.state.gpecProfessionalEvolution.current_level_id}
                              onChange={this.handleExternalSelect}
                              autoWidth={false}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="p-grid p-col-12">
                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <label htmlFor="former_degree">Diplôme à l'entrée</label>
                          </div>
                          <div className="p-col-12">
                            <Dropdown
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="former_degree"
                              options={this.state.former_degree_list.map (l => ({label: l.name, value: l.id}))}
                              value={this.state.gpecProfessionalEvolution.former_degree_id}
                              onChange={this.handleExternalSelect}
                              autoWidth={false}
                            />
                          </div>
                        </div>

                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <label htmlFor="current_degree">
                              { this.state.gpecProfessionalEvolution.state === 'done' ? "Diplôme obtenu" : "Diplôme visé" }
                            </label>
                          </div>
                          <div className="p-col-12">
                            <Dropdown
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="current_degree"
                              options={this.state.current_degree_list.map (l => ({label: l.name, value: l.id}))}
                              value={this.state.gpecProfessionalEvolution.current_degree_id}
                              onChange={this.handleExternalSelect}
                              autoWidth={false}
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GpecProfessionalEvolutionForm
