import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {Display} from '../../components/Display'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputTextarea} from 'primereact/inputtextarea'
import {Dropdown} from 'primereact/dropdown'
import {Calendar} from 'primereact/calendar'
import '../../components/Forms.css'
import {StaticData} from './StaticData'

class GpecMeetingForm extends AuthenticatedForm {

  constructor (props) {
    super(props, 'gpecMeeting', '/GPEC/Entretiens', 'GpecEmployeesMeetings',
      {
        gpecMeeting: {
          id: props.match.params.id,
          employee_id: (props.match.params.employee_id ? props.match.params.employee_id : ""),
          employee: (props.match.params.employee_name ? props.match.params.employee_name : ""),
          type: "",
          goal_id: 0,
          goal: "",
          date: new Date (),
          action_plan: "",
          action_id: 0,
          date_goal: "",
          date_next: "",
        },

        mandatoryFields: {
          type: {test: FormContainer.checkNotEmpty, state: false},
        },

        goal_list : [],
        action_list : [],
        type : StaticData.meeting_types,
      }
    )

    this.handlePickAction = this.handlePickAction.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleTypeChange = this.handleTypeChange.bind(this)
  }


  /*
   * Get external values :
   *  - Position list
   */

  async getExternalValues () {
    const goals = await this.apiCall ('GpecLevels', 'list')
    const actions = await this.apiCall ('GpecActions', 'list')
    await this.setState (
      {
        goal_list: goals.values,
        action_list: actions.values,
      }
    )
  }

  /*
   * Adjust server values
   * - Dates are encoded as an ISO 8601 string then converted to date object
   * - Salaries are transmited as real numbers without any currency format
   */
  cleanUpValues (values) {
    if ( values.date ) {
      values.date = new Date (values.date)
    }
    if ( values.date_goal ) {
      values.date_goal = new Date (values.date_goal)
    }
    if ( values.date_next ) {
      values.date_next = new Date (values.date_next)
    }
  }

  /*
   * Pick an action from the predefined action list dropbox
   * then add it at the end of the action plan field
   */
  handlePickAction (e) {
    const value = e.target.value
    const action = this.state.action_list.find (s => s.id === value)
    if ( action ) {
      this.setState(
        prevState => {
          prevState.gpecMeeting.action_plan += '\n' + action.name
          return {
            gpecMeeting: prevState.gpecMeeting
          }
        }
      )
    }
  }

  handleDateChange (e) {
    const date = e.value
    this.setState (
      prevState => {
        const date_next = new Date(date.valueOf())
        const next = StaticData.meeting_next[prevState.gpecMeeting.type] ?? 1
        prevState.gpecMeeting.date = date
        date_next.setFullYear(date.getFullYear() + next)
        prevState.gpecMeeting.date_next = date_next
        return {gpecMeeting: prevState.gpecMeeting}
      }
    )
  }

  handleTypeChange (e) {
    this.handleSelect (e)
    this.setState (
      prevState => {
        const date_next = new Date(prevState.gpecMeeting.date.valueOf())
        const next = StaticData.meeting_next[prevState.gpecMeeting.type] ?? 1
        date_next.setFullYear(date_next.getFullYear() + next)
        prevState.gpecMeeting.date_next = date_next
        return {gpecMeeting: prevState.gpecMeeting}
      }
    )
  }

  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Entretien avec {this.state.gpecMeeting.employee}</h1>
                  <FormContainer
                    values={this.state.gpecMeeting}
                    mandatoryFields={this.state.mandatoryFields}
                    onEdit={this.handleEdit}
                    onUpdate={this.handleUpdate}
                    onExit={this.handleExit}
                    setValues={this.setValues}
                    getValues={this.getValues}
                    browser={this.state.browser}
                    onBrowse={this.handleBrowse}
                  >
                    <div className="p-grid">

                      <div className="p-grid p-col-12 p-md-4">
                        <div className="p-col-12">
                          <MandatoryLabel htmlFor="type" isMissing={this.state.mandatoryFields.type.state}>Type d'entretien</MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <Dropdown
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="type"
                            options={this.state.type}
                            value={this.state.gpecMeeting.type}
                            onChange={this.handleTypeChange}
                            autoWidth={false}
                          />
                        </div>
                      </div>

                      <div className="p-grid p-col-12 p-md-3">
                        <div className="p-col-12">
                          <label htmlFor="date">Date de l'entretien</label>
                        </div>
                        <div className="p-col-12">
                          <Calendar
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="date"
                            value={this.state.gpecMeeting.date}
                            onChange={this.handleDateChange}
                            placeHolder=""
                            showIcon={true}
                            showButtonBar={true}
                            clearButtonStyleClass="form-hidden"
                            readOnlyInput={true}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1900:2100"
                            dateFormat="dd/mm/yy"
                            locale={FormContainer.localDate('fr')}
                          ></Calendar>
                        </div>
                      </div>

                      <div className="p-grid p-col-12 p-md-3">
                        <div className="p-col-12">
                          <label htmlFor="date">Prochain entretien à prévoir le</label>
                        </div>
                        <div className="p-col-12">
                          <Calendar
                            className="form-input"
                            disabled={true}
                            id="date_next"
                            value={this.state.gpecMeeting.date_next}
                            onChange={this.handleCalendar}
                            placeHolder=""
                            showIcon={true}
                            showButtonBar={true}
                            clearButtonStyleClass="form-hidden"
                            readOnlyInput={true}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1900:2100"
                            dateFormat="dd/mm/yy"
                            locale={FormContainer.localDate('fr')}
                          ></Calendar>
                        </div>
                      </div>

                      <div className="p-grid p-col-12 p-md-5">
                        <div className="p-col-12">
                          <label htmlFor="goal">Objectif professionnel</label>
                        </div>
                        <div className="p-col-12">
                          <Dropdown
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="goal"
                            options={this.state.goal_list.map (g => ({label: g.name, value: g.id}))}
                            value={this.state.gpecMeeting.goal_id}
                            onChange={this.handleExternalSelect}
                            autoWidth={false}
                          />
                        </div>
                      </div>

                      <div className="p-grid p-col-12 p-md-3">
                        <div className="p-col-12">
                          <label htmlFor="date_goal">Date cible de l'objectif</label>
                        </div>
                        <div className="p-col-12">
                          <Calendar
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="date_goal"
                            value={this.state.gpecMeeting.date_goal}
                            onChange={this.handleCalendar}
                            placeHolder=""
                            showIcon={true}
                            showButtonBar={true}
                            clearButtonStyleClass="form-hidden"
                            readOnlyInput={true}
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="1900:2100"
                            dateFormat="dd/mm/yy"
                            locale={FormContainer.localDate('fr')}
                          ></Calendar>
                        </div>
                      </div>

                      <div className="form-new-line p-grid p-col-12 p-justify-between">
                        <div className="p-col-6">
                          <label htmlFor="action_plan">Actions pour la définition du projet professionnel</label>
                        </div>

                        <Display if={this.state.editMode}>
                            <div className="p-col-4">
                              <Dropdown
                                placeholder="Actions prédéfinies"
                                className="form-input"
                                disabled={!this.state.editMode}
                                id="action"
                                options={this.state.action_list.map (a => ({label: a.name, value: a.id}))}
                                value={this.state.gpecMeeting.action_id}
                                onChange={this.handlePickAction}
                                autoWidth={false}
                              />
                            </div>
                        </Display>

                        <div className="p-col-12">
                          <InputTextarea
                            className="form-input"
                            autoComplete="off"
                            disabled={!this.state.editMode}
                            id="action_plan"
                            rows={15}
                            value={this.state.gpecMeeting.action_plan}
                            onChange={this.handleInputText}
                          />
                        </div>
                      </div>

                    </div>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GpecMeetingForm
