import React from 'react'
import {Switch, Route} from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import {QueryBuilder} from '../../components/QueryBuilder.js'


export class QueryBuilders extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route path='/Requetteur/:service' component={QueryBuilder} />
      </Switch>
    )
  }
}
