import React, {Component} from 'react'
import {Dialog} from 'primereact/dialog'
import {DataTable} from 'primereact/datatable'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {Dropdown} from 'primereact/dropdown'
import {ListBox} from 'primereact/listbox'
import {MultiSelect} from 'primereact/multiselect'
import {AutoComplete} from 'primereact/autocomplete'
import {DateChooser} from './DateChooser'
import {CSVDownloader} from './CSVDownloader'
import {ProgressBar} from 'primereact/progressbar'
import './Tables.css'

export class LazyDataTable extends Component {

  constructor(props) {
    super(props)

    if ( this.props.maxRowPerPage !== undefined ) {
      this.defaultRowPerPageOptions = [this.props.maxRowPerPage]
    } else if (this.props.RowPerPageOptions !== undefined) {
      this.defaultRowPerPageOptions = this.props.RowPerPageOptions
    } else {
      this.defaultRowPerPageOptions = [20,30,50,100,500,1000]
    }

    this.wideActionsBarButtonClass = 'p-ml-2 p-mt-2' ;
    this.smallActionsBarButtonClass = 'p-col-12 table-vertical-gutter' ;

    this.state = {
      dataTableValue: [],
      actionMode: false,
      deleteConfirm: false,
      addItemSelector: false,
      selection: [],
      rowPerPageOptions: [],
      isLoading: true,
      version:0,
      filter: '',
      filters: {},
      fields: {},
      advancedFilterMode: false,
      sort: this.props.sort ? this.props.sort : { sortField: null, sortOrder: null },
      totalRecords: 0,
      first: 0,
      rows: this.defaultRowPerPageOptions[0],

      exportData: [],

      itemSelectror: {
        item: "",
        item_id: 0,
        suggestions: [],
        data: null,
      },

      enablers: {
        any: false,
        create: false,
        delete: false,
        add: false,
        remove: false,
        enable: false,
        disable: false,
        custom1: false,
        custom2: false,
        export: false,
        mailing: false,
        queryBuilder: false,
      },

      actionsBarButtonClass: this.wideActionsBarButtonClass,
    }

    this.selectedRows = {}

    this.loadValues = this.loadValues.bind(this)
    this.loadVersion = 0

    this.watch = null

    this.handlePaginator = this.handlePaginator.bind(this)

    this.handleSort = this.handleSort.bind(this)

    this.handleReorderRows = this.handleReorderRows.bind(this)

    this.filtersTimer = null
    this.filterTimeout = 500
    this.handleFilter = this.handleFilter.bind(this)
    this.handleAdvancedFilters = this.handleAdvancedFilters.bind(this)
    this.handleFilterMode = this.handleFilterMode.bind(this)

    this.handleSelection = this.handleSelection.bind(this)
    this.handleActionMode = this.handleActionMode.bind(this)

    /* Ugly workaround to handle rowDoubleClick event in a TouchPad compatible way
    this.handleSelect = this.handleSelect.bind(this)
    */


    this.handleCreate = this.handleCreate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleDisable = this.handleDisable.bind(this)
    this.handleEnable = this.handleEnable.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.handleMailing = this.handleMailing.bind(this)
    this.handleQueryBuilder = this.handleQueryBuilder.bind(this)

    this.setItemSelector = this.setItemSelector.bind(this)
    this.cleanUpItemSelector = this.cleanUpItemSelector.bind(this)
    this.suggestItems = this.suggestItems.bind (this)

    this.setRef = this.setRef.bind(this)
    this.setContext = this.setContext.bind(this)
    this.getContext = this.getContext.bind(this)

    this.handleResize = this.handleResize.bind(this)
  }

  async loadValues () {

    this.loadVersion++
    const myVersion = this.loadVersion
    this.setState({isLoading: true})

    let minRows = this.defaultRowPerPageOptions[0]
    if (this.state.rows > minRows) {
      minRows = this.state.rows
    }
    const items = await this.props.getValues (
      this.state.first,
      minRows,
      this.state.sort,
      this.state.filter,
      this.state.advancedFilterMode ? this.state.filters : null
    )
    // If parent is not ready to retrieve values, just do nothing
    if (items === null) {
        return this.setState({isLoading: false})
    }

    /* FIXME -- Setup child modified parameters ...
    const sort = items.args[2]
    const filter = items.args[3]
    const filters = items.args[4]
    */

    let rowPerPageOptions = []
    rowPerPageOptions = this.defaultRowPerPageOptions.filter ((v, i, a) => { return v < items.totalRecords })
    if ( ! rowPerPageOptions.length ) {
      rowPerPageOptions.push (Number (items.totalRecords))
      minRows = Number (items.totalRecords)
    } else if ( rowPerPageOptions.length < this.defaultRowPerPageOptions.length ) {
      rowPerPageOptions.push (items.totalRecords)
    }

    if ( this.loadVersion === myVersion ) {
      return this.setState (
        prevState => {
          return (
            {
              isLoading: false,
              selection: items.values.filter (v => prevState.selection.find (s => s.id === v.id)),
              rowPerPageOptions: rowPerPageOptions,
              totalRecords: Number (items.totalRecords),
              rows: minRows,
              /* FIXME -- Setup child modified parameters ...
              sort: sort,
              filter: filter,
              filters: filters,
              */
              dataTableValue: items.values
            }
          )
        },
        this.setEnablers
      )
    }
    return false
  }



  /*
   * Handlers
   */

  async handlePaginator (event) {
    await this.setState (
      {
        first: event.first,
        rows: event.rows
      }
    )
    return this.loadValues ()
  }

  async handleSort (event) {
    await this.setState (
      {
        sort : {
          sortField: event.sortField,
          sortOrder: event.sortOrder
        }
      }
    )
    return this.loadValues ()
  }

  async handleReorderRows (event) {
    // Don't move me just above myself
    if ( event.dragIndex !== event.dropIndex ) {
      const from = event.dragIndex
      // Adjust landing depending on the move direction
      const to = event.dragIndex > event.dropIndex ? event.dropIndex : event.dropIndex - 1
      // Don't move me just underneath myself
      if ( from !== to ) {
        // Get ids of movind and target items
        const fromId = this.state.dataTableValue[from].id
        const toId = this.state.dataTableValue[to].id
        // Call external funtion doing the move
        if (this.props.onRowReorder) {
          await this.props.onRowReorder (fromId, toId)
          return this.loadValues ()
        }
      }
    }
    return false
  }

  async handleFilterMode (event) {
    await this.setState(
      prevState => {
        return {
          advancedFilterMode: !prevState.advancedFilterMode
        }
      }
    )
    return this.loadValues ()
  }

  async handleFilter (event) {
    if (this.filtersTimer) {
      clearTimeout (this.filtersTimer)
      this.filtersTimer = null
    }
    await this.setState (
      {
        filter: event.target.value
      }
    )
    this.filtersTimer = setTimeout(
      () => {
        this.loadValues ()
      },
      this.filterTimeout
    )
  }

  async handleAdvancedFilters (event) {
    if (this.filtersTimer) {
      clearTimeout (this.filtersTimer)
      this.filtersTimer = null
    }
    await this.setState(
      {
        filters: event.filters
      }
    )
    this.filtersTimer = setTimeout(
      () => {
        this.loadValues ()
      },
      this.filterTimeout
    )
  }

  async handleSelection (event) {
    if (
         this.props.onExport
      || this.props.onMailing
      || this.props.onDelete
      || this.props.onRemove
      || this.props.onEnable
      || this.props.ondisable
    ) {
      await this.setState ({selection : event.value})
    }
    return this.setEnablers ()
  }

  handleDelete () {
    return this.setState ({deleteConfirm: true})
  }

  handleCreate () {
    return this.props.onCreate ()
  }

  async handleRemove () {
    await this.props.onRemove (this.state.selection.map (item => {return item.id}))
    await this.setState ({selection : []}, this.setEnablers)
    return this.loadValues ()
  }

  async handleAdd () {
    switch (this.props.addItemMode) {
      case "MultiSelect" :
        if (  this.props.addItemGet !== undefined ) {
          const items = await this.props.addItemGet ()

          return this.setState (
            {
              addItemSelector: true,
              itemSelectror: {
                item: [],
                item_id: [],
                suggestions: items.values,
                data: null,
              }
            }
          )
        }
        break
      default :
        return this.setState (
          {
            addItemSelector: true,
            itemSelectror: {
              item: "",
              item_id: 0,
              suggestions: [],
              data: null,
            }
          }
        )
    }
  }

  async handleDisable () {
    await this.props.onDisable (this.state.selection.map (item => {return item.id}))
    return this.loadValues ()
  }

  async handleEnable () {
    await this.props.onEnable (this.state.selection.map (item => {return item.id}))
    return this.loadValues ()
  }

  async handleExport () {
    // Copy filters by value one by one to prevent state.filters to be modified !
    const filters = {...this.state.filters}
    if ( this.state.selection.length ) {
      filters.exportIds = { value: this.state.selection.map (item => {return item.id})}
    } else if (this.props.exportPageOnly) {
      filters.exportIds = { value: this.state.dataTableValue.map (item => {return item.id}) }
    }
    const data = await this.props.onExport (this.state.sort, this.state.filter, filters)
    return this.setState ({exportData: data})
  }

  async handleMailing () {
    // Copy filters by value one by one to prevent state.filters to be modified !
    const filters = {...this.state.filters}
    if ( this.state.selection.length ) {
      filters.mailingIds = { value: this.state.selection.map (item => {return item.id}) }
    } else if (this.props.mailingToPageOnly) {
      filters.mailingIds = { value: this.state.dataTableValue.map (item => {return item.id}) }
    }
    return await this.props.onMailing (this.state.sort, this.state.filter, filters)
  }

  handleQueryBuilder () {
    return this.props.onQueryBuilder ()
  }

  setItemSelector (event) {
    return this.setState(
      prevState => {
        prevState.itemSelectror.item = event.value
        if ( this.props.addItemMode === "MultiSelect" ) {
          prevState.itemSelectror.item_id = prevState.itemSelectror.item.map (i => {return i.id})
        }
        return {
          itemSelectror: prevState.itemSelectror
        }
      }
    )
  }

  async cleanUpItemSelector (e) {
    let value = ""
    if ( e.originalEvent !== undefined ) {
      value = e.value
    } else {
      value = e.target.value
    }
    return await this.setState(
      prevState => {
        let fieldName = 'name'  // default value
        if ( this.props.addItemFieldName !== undefined ) {
          fieldName = this.props.addItemFieldName
        }
        const data = prevState.itemSelectror.data.values.filter (i => i[fieldName] === value)
        if ( data && data.length === 1 ) {
          prevState.itemSelectror.item_id = data[0].id
        } else {
          prevState.itemSelectror.item = ""
          prevState.itemSelectror.item_id = 0
        }
        return { itemSelectror: prevState.itemSelectror }

      }
    )
  }

  async suggestItems (event) {
    const maxValues = 5
    let fieldName = 'name'  // default value
    if ( this.props.addItemFieldName !== undefined ) {
      fieldName = this.props.addItemFieldName
    }
    let items = null
    if ( this.props.addItemGet !== undefined ) {
      items = await this.props.addItemGet (event.query, maxValues)
    } else {
      const filters = {
        [fieldName] : {value : event.query},
      }
      items = await this.props.getValues (0, maxValues, false, false, filters)
    }
    const suggestions = items.values.map (p => p[fieldName])
    if ( items.totalRecords > maxValues ) {
      suggestions.push ("Encore " + (items.totalRecords - maxValues) + " de plus ...")
    }
    return this.setState (
      prevState => {
        prevState.itemSelectror.suggestions = suggestions
        prevState.itemSelectror.data = items
        return {
          itemSelectror : prevState.itemSelectror
        }
      }
    )
  }

  /*
   * This is a dirty kludge to cope with touchscreen devices
   * As there is no onRowTouchWathever, we will detect double click by
   * measuring the time between two selection events ... Yes it is realy ugly
   * ... And as double tap is a very slow action, we need to detect touch devices
   * but there is no effective way to do this, so we use the same screen limit
   * used into the application to turn mobile mode on or off. Quite dirty too :-(

  handleSelect (event) {
    const now = Date.now()
    const duratoin = (window.innerWidth > 1024)?300:1000
    if ( this.selectedRows[event.data.id] !== undefined ) {
      if ( now - this.selectedRows[event.data.id] < duratoin ) {
        this.handleOpen (event)
      }
    }
    this.selectedRows[event.data.id] = now
  }
  */

  handleOpen (event) {
    const getValues = this.props.getValues
    const cleanOnExit = this.props.cleanOnExit
    const sort = this.state.sort
    const filter = this.state.filter
    const filters = this.state.advancedFilterMode ? this.state.filters : null
    function get (index) {
      return getValues (index, 1, sort, filter, filters)
    }
    function clean () {
      if ( cleanOnExit !== undefined ) {
        cleanOnExit ()
      }
    }
    const browser = {
      get:  get,
      clean: clean,
      current: this.state.first + event.index,
      number: this.state.totalRecords,
    }
    if ( this.props.onOpen && ! this.props.locked ) {
      return this.setState ( {selection:[]}, () => this.props.onOpen (event, browser))
    }
    return false
  }

  handleActionMode () {
    if (!this.state.actionMode) {
      this.setEnablers ()
    }
    return this.setState(
      prevState => {
        return {
          actionMode: !prevState.actionMode
        }
      }
    )
  }



  /*
   * Enablers
   */

  async setEnablers () {
    const enablers = {}
    enablers.create = await this.enableCreate ()
    enablers.delete = await this.enableDelete ()
    enablers.add = await this.enableAdd ()
    enablers.remove = await this.enableRemove ()
    enablers.enable = await this.enableEnable ()
    enablers.disable = await this.enableDisable ()
    enablers.custom1 = await this.enableCustom1 ()
    enablers.custom2 = await this.enableCustom2 ()
    enablers.export = await this.enableExport ()
    enablers.mailing = await this.enableMailing ()
    enablers.queryBuilder = await this.enableQueryBuilder ()
    enablers.any = enablers.create || enablers.delete
      || enablers.add || enablers.remove
      || enablers.enable || enablers.disable
      || enablers.custom1 || enablers.custom2
      || enablers.export || enablers.mailing
      || enablers.queryBuilder
    this.setState({enablers: enablers})
  }

  async enableCreate () {
    if (!this.state.selection.length && this.props.onCreate) {
      if (!this.props.createEnabler || await this.props.createEnabler ()) {
        return true
      }
    }
    return false
  }

  async enableDelete () {
    if (this.state.selection.length > 0 && this.props.onDelete) {
      //if (!this.props.deleteEnabler) {
      //}
      if (!this.props.deleteEnabler || await this.props.deleteEnabler (this.state.selection)) {
        return true
      }
    }
    return false
  }

  async enableAdd () {
    if (!this.state.selection.length && this.props.onAdd) {
      if (!this.props.addEnabler || await this.props.addEnabler ()) {
        return true
      }
    }
    return false
  }

  async enableRemove () {
    if (this.state.selection.length > 0 && this.props.onRemove) {
      if (!this.props.removeEnabler || await this.props.removeEnabler (this.state.selection)) {
        return true
      }
    }
    return false
  }

  async enableEnable () {
    if (this.state.selection.length > 0 && this.props.onEnable) {
      if (!this.props.enableEnabler || await this.props.enableEnabler (this.state.selection)) {
        return true
      }
    }
    return false
  }

  async enableDisable () {
    if (this.state.selection.length > 0 && this.props.onDisable) {
      if (!this.props.disableEnabler || await this.props.disableEnabler (this.state.selection)) {
        return true
      }
    }
    return false
  }

  async enableCustom1 () {
    if (this.state.selection.length > 0 && this.props.onCustom1) {
      if (!this.props.custom1Enabler || await this.props.custom1Enabler (this.state.selection)) {
        return true
      }
    }
    return false
  }

  async enableCustom2 () {
    if (this.props.onCustom2) {
      if (!this.props.custom2Enabler || await this.props.custom2Enabler ()) {
        return true
      }
    }
    return false
  }

  /*
   * Exports may apply to :
   *  - The currently selected items only
   *  - The currently displayed itmeps only (the current table page)
   *  - The whole data in the table range, in this case, the enabler will
   *    received not a list of id but the sort and filters parameter reprensenting
   *    the current data set. It is up to it to retrieve values to validate.
   */
  async enableExport () {
    if (this.props.onExport) {
      if (!this.props.exportEnabler) {
        return true
      }
      if (this.state.selection.length) {
        return await this.props.exportEnabler (this.state.selection)
      }
      if (this.props.exportPageOnly) {
        return await this.props.exportEnabler (this.state.dataTableValue)
      }
      return await this.props.exportEnabler ([], this.state.sort, this.state.filter, this.state.filters)
    }
    return false
  }

  /*
   * Mailings may apply to :
   *  - The currently selected items only
   *  - The currently displayed itmeps only (the current table page)
   *  - The whole data in the table range, in this case, the enabler will
   *    received not a list of id but the sort and filters parameter reprensenting
   *    the current data set. It is up to it to retrieve values to validate.
   */
  async enableMailing () {
    if (this.props.onMailing) {
      if (!this.props.mailingEnabler) {
        return true
      }
      if (this.state.selection.length) {
        return await this.props.mailingEnabler (this.state.selection)
      }
      if (this.props.mailingToPageOnly) {
        return await this.props.mailingEnabler (this.state.dataTableValue)
      }
      return await this.props.mailingEnabler (null, this.state.sort, this.state.filter, this.state.filters)
    }
    return false
  }

  async enableQueryBuilder () {
    if (this.props.onQueryBuilder) {
      if (!this.props.queryBuilderEnabler || await this.props.queryBuilderEnabler ()) {
        return true
      }
    }
    return false
  }

  /*
   * Global Table lifecycle event handlers
   */

  async componentDidMount() {
    window.onresize = this.handleResize
    await this.loadValues ()
  }

  componentWillUnmount() {
    if (this.filtersTimer) {
      clearTimeout (this.filtersTimer)
      this.filtersTimer = null
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // Reload Values on parent request
    const prevWatch = this.watch
    this.watch = JSON.stringify([this.props.watch])
    if ( prevWatch !== this.watch ) {
      await this.loadValues ()
    }
    // Reload values if out of range
    if (this.state.first > this.state.totalRecords) {
      await this.setState (
        {first: 0},
        async () => {
          this.loadValues ()
        }
      )
    }
    /* Set input on Search bar if no other input is already focused
     * ... Experimental stuff : do not use in production environement !
    const inputSearch = document.getElementById ('inputSearch')
    if ( ! this.props.locked && inputSearch && document.activeElement.nodeName !== 'INPUT') {
        inputSearch.focus ()
    }
    */
  }

  setRef (ref) {
    if ( this.ref === undefined ) {
      this.ref = ref
    }
  }

  getContext () {
    return {
      actionMode: this.state.actionMode,
      selection: this.state.selection,
      filter: this.state.filter,
      filters: this.state.filters,
      fields: this.state.fields,
      advancedFilterMode: this.state.advancedFilterMode,
      sort: this.state.sort,
      first: this.state.first,
      rows: this.state.rows,
      totalRecords: this.state.totalRecords,
    }
  }

  setContext (context) {
    if (context) {
      this.setState (
        prevState => {
          return (
            {
              actionMode: context.actionMode!==undefined?context.actionMode:prevState.actionMode,
              selection: context.selection!==undefined?context.selection:prevState.selection,
              filter: context.filter!==undefined?context.filter:prevState.filter,
              filters: context.filters!==undefined?context.filters:prevState.filters,
              fields: context.fields!==undefined?context.fields:prevState.fields,
              advancedFilterMode: context.advancedFilterMode!==undefined?context.advancedFilterMode:prevState.advancedFilterMode,
              sort: context.sort!==undefined?context.sort:prevState.sort,
              first: context.first!==undefined?context.first:prevState.first,
              rows: context.rows!==undefined?context.rows:prevState.rows,
              totalRecords: context.totalRecords!==undefined?context.totalRecords:prevState.totalRecords,
            }
          )
        },
        async () => {
          await this.loadValues ()
        }
      )
    }
  }

  /*
   *  Static class method provindig representation template for progression
   */
  static progressTemplate(rawData, column) {
    return <ProgressBar value={rawData[column.field]}/>
  }

  /*
   *  Static class method provindig representation template for progression
   */
  static percentTemplate(rawData, column, maxDigit=0) {
    if ( maxDigit ) {
        const formatter = new Intl.NumberFormat('fr-FR', {style: 'percent', minimumFractionDigits: maxDigit, maximumFractionDigits: maxDigit })
        return formatter.format(parseFloat(rawData[column.field]) / 100)
    } else {
      return parseFloat(rawData[column.field]).toFixed(0)+"%"
    }
  }

  /*
   *  Static class method provindig representation template for boolean input fields
   */
  static booleanTemplate(rawData, column) {
    if ( rawData[column.field] === true || rawData[column.field] === 't' ) {
      return <i className="pi pi-star"></i>
    }
    return ""
  }

  /*
   * Default set of boolean options
   */
  static booleanDefaultOptions = [
    { label : 'Oui', value : 'o' },
    { label : 'Non', value : 'n' },
    { label : 'Tous', value : '' },
  ]



  /*
   *  Static class method provindig representation template for Active / Trashed
   */
  static trashTemplate(rawData, column) {
    if ( rawData[column.field] ) {
      return <i className="pi pi-trash"></i>
    }
    return ""
  }

  /*
   * Default set of trash options
   */
  static trashDefaultOptions = [
    { label : 'Actif', value : '0' },
    { label : 'Effacé', value : '1' },
    { label : 'Tous', value : '' },
  ]


  /*
   *  Static class method provindig representation template for Active / Archived
   */
  static archiveTemplate(rawData, column) {
    if ( rawData[column.field] ) {
      return <i className="pi pi-save"></i>
    }
    return <i className="pi pi-check"></i>
  }

  /*
   * Default set of archive options
   */
  static archiveDefaultOptions = [
    { label : 'Actifs', value : '0' },
    { label : 'Archivé', value : '1' },
    { label : 'Tous', value : '' },
  ]


  /*
   *  Static class method provindig representation template for Active / Disabled
   */
  static disableTemplate(rawData, column) {
    if ( rawData[column.field] ) {
      return <i className="pi pi-minus-circle"></i>
    }
    return <i className="pi pi-check"></i>
  }

  /*
   *  Static class method provindig elipsis texte Template
   *  DEPRECATED -- See maxLinesTemplate
  static elipsisTemplate (maxLength=50) {
    return (rawData, column) => {
      let text = rawData[column.field]
      if ( text ) {
        text = rawData[column.field].replace(/(\n\r|\r\n|\n|\r)/gm,"  ")
        if ( text.length > maxLength ) {
          text = text.substring (0, maxLength - 4) + " ..."
        }
      }
      return text
    }
  }
  */

  /*
   *  Static class method provindig maxLines auto elipsis texte Template
   */
  static maxLinesTemplate (maxLine=2) {
    return (rawData, column) => {
      return (
        <span className="table-data-cell-max-lines" style={{WebkitLineClamp: maxLine}}>
          {rawData[column.field]}
        </span>
      )
    }
  }


  /*
   *  Static class method provindig Button representation template notes
   */
  static notesTemplate (rawData, column) {
    if (rawData[column.field]) {
      return (
        <span className="table-notes-cell-template">
          <span className="anchor">
            <i className="fas fa-file-alt"></i>
          </span>
          <span className="content">
            {rawData[column.field]}
          </span>
        </span>
      )
    }
    return ""
  }


  /*
   *  Static class method provindig Button representation template e-mail
   *  When clicked, tha callback function is called to really take action
   */

  static eMailButtonTemplate (callback) {
    return (rawData, column) => {
      function sendMail (event) {
        event.stopPropagation()
        const filters = {mailingIds: {value: [rawData['id']]}}
        let mailTo = ''
        if ( rawData['name'] ) {
          mailTo = rawData['name']
        } else {
          if ( rawData['firstName'] ) {
            mailTo = rawData['firstName']
          }
          if ( rawData['lastName'] ) {
            mailTo += mailTo ? (' ' + rawData['lastName']) : rawData['lastName']
          }
        }
        mailTo += mailTo ? (' <' + rawData[column.field] + '>') : rawData[column.field]
        callback (null, null, filters, mailTo)
      }
      if (rawData[column.field]) {
        return (
          <div style={{textAlign: 'center', width: '100%'}}>
            <Button
              type="button"
              tooltip={rawData[column.field].replace ('@', '@ ')}
              icon="fas fa-at"
              className="p-button"
              style={{textAlign: 'center', height: '2em'}}
              onClick={sendMail}
            >
            </Button>
          </div>
        )
      }
      return ""
    }
  }

  /*
   *  Static class method provindig representation template for boolean input fields
   */
  static massMailingTemplate(rawData, column) {
    if ( rawData[column.field] === '') {
      return <i className="pi pi-users"></i>
    }
    return <i className="pi pi-user"></i>
  }

  /*
   * Default set of boolean options
   */
  static massMailingDefaultOptions = [
    { label : 'Unitaire', value : 'uniqueRecipient' },
    { label : 'Mailing', value : 'massMailing' },
    { label : 'Tous', value : '' },
  ]

  /*
   *  Static class method provindig Button representation template e-mail
   *  When clicked, the default mailer of the host is called with href='mailto:'
   */
  static externalMailerButtonTemplate (rawData, column) {
    function sendMail (event) {
      event.stopPropagation()
      window.location.href='mailto:' + rawData[column.field]
    }
    if (rawData[column.field]) {
      return (
        <div style={{textAlign: 'center', width: '100%'}}>
          <Button
            type="button"
            tooltip={rawData[column.field].replace ('@', '@ ')}
            icon="fas fa-at"
            className="p-button"
            style={{textAlign: 'center', height: '2em'}}
            onClick={sendMail}
          >
          </Button>
        </div>
      )
    }
    return ""
  }

  /*
   *  Static class method provindig Button representation template linkedin
   */
  static socialLinkTemplate (icon="fas fa-share-alt-square") {
    return (rawData, column) => {
      function followSocialLink (event) {
        event.stopPropagation()
        window.open(rawData[column.field], "_blank");
      }
      if (rawData[column.field]) {
        return (
          <div style={{textAlign: 'center', width: '100%'}}>
            <Button
              type="button"
              tooltip={rawData[column.field]}
              icon={icon}
              className="p-button"
              style={{textAlign: 'center', height: '2em'}}
              onClick={followSocialLink}
            >
            </Button>
          </div>
        )
      }
      return ""
    }
  }

  /*
   *  Static class method provindig representation template for Euro input fields
   */
  static euroTemplate(rawData, column) {
    const formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' })
    const amount = parseFloat(rawData[column.field])
    return formatter.format(isNaN(amount)?0.0:amount)
  }

  /*
   *  Static class method provindig representation template for number input fields
   */
  static numberTemplate(rawData, column) {
    const formatter = new Intl.NumberFormat('fr-FR')
    const amount = parseFloat(rawData[column.field])
    return formatter.format(isNaN(amount)?0.0:amount)
  }

  /*
   *  Static class method provindig representation template for Date input fields
   */
  static dateTemplate(rawData, column) {
    if ( rawData[column.field] ) {
      return <center>{new Date (rawData[column.field]).toLocaleDateString ('fr-FR')}</center>
    }
    return ""
  }

  /*
   *  Static class method provindig representation template for List input fields
   */
  static listTemplate(rawData, column, list) {
    if ( rawData[column.field] ) {
      const label = list.reduce (
        (a,x) => {
          if (x.value === rawData[column.field]) {
            return a + x.label
          }
          return a
        },
        ""
      )
      return label
    }
    return ""
  }

  /*
   *  Static class method provindig representation template for Options and MultiOptions input fields
   *  To be use with database stored option having predefined fields
   *  filterParams={label, value, color, icon} must be set on the parent
   *   label : Texte to be displayed (usually the name)
   *   value : Unique value identifying the option (usually the id)
   *   color : optional text color
   *   icon : optionel text icon
   */
  static optionTemplate(rawData, column) {
    let style = {}
    let icon = ''
    if (column.filterParams !== undefined) {
      const field_id = column.field + '_id'
      const param = column.filterParams.reduce (
        (a,x) => {
          if (x.value === rawData[field_id]) {
            return x
          }
          return a
        },
        {}
      )
      if ( param['color'] !== undefined && param['color'] ) {
        style = {color: '#'+param['color']}
      }
      if ( param['icon'] !== undefined && param['icon'] ) {
        icon = <i className={param['icon']} style={{marginRight: '1em'}}/>
      }
    }
    return <div style={style}>{icon}{rawData[column.field]}</div>
  }

  /* Static class method managing not filterable fields
   *    Noe filder is displayed at all
   */
  static noFilter (field, options) {
    return (<div></div>)
  }

  /* Static class method managing Options filtering
   *    filterMethod={LazyDataTable.optionsFilter}
   *    filterParams={options}
   */
  static optionsFilter (field, options) {
    function setValue (event) {
      this.setState (
        prevState => {
          prevState.fields[field] = event.value
          return ({fields:prevState.fields})
        }
      )
      this.ref.filter(event.value, field, 'equals')
    }

    return (
      <Dropdown
        style={{width: '100%'}}
        className="ui-column-filter"
        value={this.state.fields[field]}
        options={options}
        onChange={setValue.bind(this)}
        appendTo={document.body}
        //showClear={true}
      />
    )
  }

  /* Static class method managing Multi Options filtering
   *    filterMethod={LazyDataTable.multiOptionsFilter}
   *    filterParams={options}
   */
  static multiOptionsFilter (field, options) {
    /* FIXME -- Set up default initial values ...
    if (this.state.fields[field] === undefined && this.state.filters[field] !== undefined) {
      this.setState (
        prevState => {
          prevState.fields[field] = prevState.filters[field].value
          return (
            {fields: prevState.fields}
          )
        }
      )
    }
    */
    function setValue (event) {
      this.setState (
        prevState => {
          prevState.fields[field] = event.value
          return ({fields:prevState.fields})
        }
      )
      this.ref.filter(event.value, field, 'in')
    }

    return (
      <MultiSelect
        style={{width: '100%'}}
        className="ui-column-filter"
        value={this.state.fields[field]}
        options={options}
        onChange={setValue.bind(this)}
        appendTo={document.body}
        selectedItemsLabel="{0} options sélectionnées"
      />
    )
  }

  /* Static class method date filtering
   * -> ex :
   *    filterMethod={LazyDataTable.dateFilter}
   */
  static dateFilter (field) {

    function setValue (mode, value) {
      this.setState (
        prevState => {
          prevState.fields[field] = {mode, value}
          return ({fields:prevState.fields})
        }
      )
      this.ref.filter(value, field, mode)
    }

    return (
      <DateChooser
        style={{width: '100%'}}
        className="ui-column-filter"
        value={this.state.fields[field]}
        onChange={setValue.bind(this)}
      />
    )
  }

  /* Static class method date filtering with TimeZone
   * -> ex :
   *    filterMethod={LazyDataTable.dateFilter}
   */
  static dateFilterTZ (field) {

    function setValue (mode, value) {
      this.setState (
        prevState => {
          prevState.fields[field] = {mode, value}
          return ({fields:prevState.fields})
        }
      )
      this.ref.filter(value, field, mode + Intl.DateTimeFormat().resolvedOptions().timeZone)
    }

    return (
      <DateChooser
        style={{width: '100%'}}
        className="ui-column-filter"
        value={this.state.fields[field]}
        onChange={setValue.bind(this)}
      />
    )
  }

  /*
   * Warning : Delete confirmation modal rendering
   */
  renderDeleteConfirmation () {
    let title = ''
    let body = null
    let footer = null
    if ( this.state.isLoading ) {
      title = "Opétarion en cours ..."
      body = <div>
        <ProgressBar mode="indeterminate" />
      </div>
      footer = <div>
        <Button
          label="Continuer en arrière plan"
          icon="pi pi-arrow-circle-right"
          className="p-button"
          onClick={
            async () => {
              await this.setState (
                {
                  deleteConfirm:false,
                  isLoading:false,
                  selection : []
                },
                this.setEnablers
              )
              this.loadValues ()
            }
          }
        />
      </div>
    } else {
      title = "Confirmez cette opération ..."
      body = <div>
        <p> {
        "Souhaitez-vous réellement " + (this.props.deleteTitle ? this.props.deleteTitle.toLowerCase () : "supprimer") + " "
        + this.state.selection.length
        + ((this.state.selection.length > 1) ? " elements ?" : " element ?")
        } </p>
        <p>Attention, cette opération est irréversible.</p>
      </div>
      footer = <div>
        <Button
          label="Oui"
          icon="pi pi-check"
          className="p-button-success"
          onClick={
            async () => {
              await this.setState({isLoading:true})
              await this.props.onDelete (this.state.selection.map (item => {return item.id}))
              if ( this.state.deleteConfirm ) {
                await this.setState ({selection : []}, this.setEnablers)
                await this.setState({deleteConfirm:false, isLoading:false})
                this.loadValues ()
              }
            }
          }
        />
        <Button
          label="Non"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={
            () => {
              this.setState({deleteConfirm:false})
            }
          }
        />
      </div>
    }
    return (
      <Dialog
        style={{width: "40%"}}
        header={title}
        visible={this.state.deleteConfirm}
        modal={true}
        footer={footer}
        closable={false}
        onHide={() => this.setState({deleteConfirm:false})}
      >
        {body}
      </Dialog>
    )
  }

  /*
   * New Item Selector panel
   */

  renderaddItemSelector() {
    switch (this.props.addItemMode) {
      case "MultiSelect" :
        return this.renderListMultyItemSelector ()
      default :
        return this.renderSearchOneItemSelector ()
    }
  }

  renderListMultyItemSelector() {
    let label = this.state.itemSelectror.suggestions.length ?
        this.props.addItemPrompt||"Choisissez les éléments à ajouter"
      : "Aucun élément disponible."
    const dialogFooter = (
      <div>
          <Button
            label="Ajouter"
            style={{display: this.state.itemSelectror.item.length?"inline-block":"none"}}
            icon="pi pi-check"
            className="p-button-success"
            onClick={
              async () => {
                this.setState({addItemSelector: false})
                if ( this.state.itemSelectror.item_id ) {
                  await this.props.onAdd (this.state.itemSelectror.item_id, this.state.selection)
                  await this.loadValues ()
                }
              }
            }
          />
          <Button
            label="Abandonner"
            icon="pi pi-times"
            className="p-button-danger"
            onClick={
              () => {
                this.setState({addItemSelector: false})
              }
            }
          />
      </div>
    )
    return (
      <Dialog
        className="list-multy-item-selector-panel"
        header={this.props.addItemTitle || "Ajouter des éléments"}
        visible={this.state.addItemSelector}
        modal={true}
        footer={dialogFooter}
        closable={false}
        onHide={() => this.setState({addItemSelector: false})}
        style={{width:'50%'}}
      >
        <div className="p-grid p-col-12 p-md-8">
          <div className="p-col-12">
            <label htmlFor="item">{label}</label>
          </div>
          <div className="p-col-12">
            <ListBox
              value={this.state.itemSelectror.item}
              options={this.state.itemSelectror.suggestions}
              onChange={this.setItemSelector}
              multiple={true}
              optionLabel="name"
            />
          </div>
        </div>
      </Dialog>
    )
  }

  renderSearchOneItemSelector() {
    const dialogFooter = (
      <div>
          <Button
            label="Ajouter"
            style={{display: this.state.itemSelectror.item_id?"inline-block":"none"}}
            icon="pi pi-check"
            className="p-button-success"
            onClick={
              async () => {
                this.setState({addItemSelector: false})
                if ( this.state.itemSelectror.item_id ) {
                  await this.props.onAdd (this.state.itemSelectror.item_id, this.state.selection)
                  await this.loadValues ()
                }
              }
            }
          />
          <Button
            label="Abandonner"
            icon="pi pi-times"
            className="p-button-danger"
            onClick={
              () => {
                this.setState({addItemSelector: false})
              }
            }
          />
      </div>
    )
    return (
      <Dialog
        header={this.props.addItemTitle || "Ajouter un élément"}
        visible={this.state.addItemSelector}
        modal={true}
        footer={dialogFooter}
        closable={false}
        onHide={() => this.setState({addItemSelector: false})}
        style={{width:'50%'}}
      >
        <div className="p-grid p-col-12 p-md-8">
          <div className="p-col-12">
            <label htmlFor="item">{this.props.addItemPrompt || "Choisissez l'élément à ajouter"}</label>
          </div>
          <div className="p-col-12" style={{height:'50vh'}}>
            <AutoComplete
              className="form-input"
              autoComplete="off"
              inputId="item"
              id="item"
              autoFocus={true}
              value={this.state.itemSelectror.item}
              onChange={this.setItemSelector}
              suggestions={this.state.itemSelectror.suggestions}
              completeMethod={this.suggestItems}
              onBlur={this.cleanUpItemSelector}
              onSelect={this.cleanUpItemSelector}
            />
          </div>
        </div>
      </Dialog>
    )
  }

  handleResize () {
    if ( window.innerWidth < 768 ) {
      // small
      this.setState ( {actionsBarButtonClass: this.smallActionsBarButtonClass} )
    } else {
      // wide (at least MD)
      this.setState ( {actionsBarButtonClass: this.wideActionsBarButtonClass} )
    }
  }

  renderActionsBar () {
    if (this.state.actionMode || (this.props.noSearch && this.state.advancedFilterMode) ) {
      return (
        <div className="p-grid p-col-12 p-nogutter">
          {this.state.enablers.create &&
          <div className={this.state.actionsBarButtonClass}>
            <Button
              className="p-button"
              label={this.props.createTitle || "Créer"}
              icon={this.props.createIcon || "pi pi-plus"}
              onClick={this.handleCreate}
            />
          </div>
          }
          {this.state.enablers.delete &&
            <div className={this.state.actionsBarButtonClass}>
              <Button
                className="p-button-danger"
                label={this.props.deleteTitle || "Supprimer"}
                icon={this.props.deleteIcon || "pi pi-trash"}
                onClick={this.handleDelete}
              />
            </div>
          }
          {this.state.enablers.add &&
            <div className={this.state.actionsBarButtonClass}>
              <Button
                className="p-button"
                label={this.props.addTitle || "Ajouter"}
                icon={this.props.addIcon || "pi pi-plus-circle"}
                onClick={this.handleAdd}
              />
            </div>
          }
          {this.state.enablers.remove &&
            <div className={this.state.actionsBarButtonClass}>
              <Button
                className="p-button-danger"
                label={this.props.removeTitle || "Retirer"}
                icon={this.props.removeIcon || "pi pi-times-circle"}
                onClick={this.handleRemove}
              />
            </div>
          }
          {this.state.enablers.enable &&
            <div className={this.state.actionsBarButtonClass}>
              <Button
                className="p-button"
                label={this.props.enableTitle || "Activer"}
                icon={this.props.enableIcon || "pi pi-check-circle"}
                onClick={this.handleEnable}
              />
            </div>
          }
          {this.state.enablers.disable &&
            <div className={this.state.actionsBarButtonClass}>
              <Button
                className="p-button-danger"
                label={this.props.disableTitle || "Désactiver"}
                icon={this.props.disableIcon || "pi pi-minus-circle"}
                onClick={this.handleDisable}
              />
            </div>
          }
          { // Binding this to custom1Template allow us to call internal methods into this external template
            this.state.enablers.custom1 &&
            <div className={this.state.actionsBarButtonClass}>
              {this.props.custom1Template.bind(this) (this.state.selection, this.props.onCustom1)}
            </div>
          }
          { // Binding this to custom2Template allow us to call internal methods into this external template
            this.state.enablers.custom2 &&
            <div className={this.state.actionsBarButtonClass}>
              {this.props.custom2Template.bind(this) (this.props.onCustom2)}
            </div>
          }
          { // See comments into CSVDownloader for full explanation
            // CSVDownloader starts CSV export when state.exportData changes
            this.state.enablers.export &&
            <div className={this.state.actionsBarButtonClass}>
              <Button
                className="p-button"
                label={this.props.exportTitle || "Exporter"}
                icon={this.props.exportIcon || "pi pi-upload"}
                onClick={this.handleExport}
              />
              <CSVDownloader
                data={this.state.exportData}
                filename={this.props.exportFilename || 'export.csv'}
                separator={this.props.exportSeparator || ";"}
                enclosingCharacter={this.props.exportEnclosingCharacter || '"'}
                headers={this.props.exportHeaders}
              />
            </div>
          }
          {this.state.enablers.mailing &&
            <div className={this.state.actionsBarButtonClass}>
              <Button
                className="p-button"
                label={this.props.mailingTitle || "Envoyer un courriel"}
                icon={this.props.mailingIcon || "pi pi-envelope"}
                onClick={this.handleMailing}
              />
            </div>
          }
          {this.state.enablers.queryBuilder &&
            <div className={this.state.actionsBarButtonClass}>
              <Button
                className="p-button"
                label={this.props.queryBuilderTitle || "Extraire des données"}
                icon={this.props.queryBuilderIcon || "pi pi-th-large"}
                onClick={this.handleQueryBuilder}
              />
            </div>
          }
          {!this.state.enablers.any &&
            <div className={this.state.actionsBarButtonClass}>
              <Button className="p-button" label="P" style={{visibility:'hidden'}} />
            </div>
          }
          {/* more comming soon ... */}
        </div>
      )
    } else {
      return (null)
    }
  }

  renderSearchBar () {
    if (!this.props.locked && this.state.enablers.any) {
      const actionIcon = this.state.actionMode?"pi pi-chevron-up":"pi pi-chevron-right"
      return (
        <div className="p-grid p-col-12">
          <div className="p-col-12 p-md-10">
            <InputText
              autoFocus={!this.props.locked && !this.props.noAutoFocus && !this.state.selection.length}
              type="search"
              value={this.state.filter}
              onChange={this.handleFilter}
              placeholder="Recherche"
              size="50"
            />
          </div>
          <div className="p-col-12 p-md-2">
            <Button className="p-button" label="Actions" icon={actionIcon} onClick={this.handleActionMode}/>
          </div>
          {this.renderActionsBar()}
        </div>
      )
    } else {
      return (
        <div className="p-grid p-col-12">
          <InputText
            autoFocus={!this.props.locked && !this.props.noAutoFocus && !this.state.selection.length}
            type="search"
            value={this.state.filter}
            onChange={this.handleFilter}
            placeholder="Recherche"
            size="50"
          />
        </div>
      )
    }
  }

  renderExtendedSearch () {
    if (! this.props.noExtendeSearch) {
      if ( this.props.noSearch ) {
        return (
          <span style ={{float: 'left'}}>
            <i
              className="pi tables-cursor-pointer pi-bars"
              style={{margin:'4px 4px 0 0'}}
              onClick={this.handleFilterMode}
            />
          </span>
        )
      } else {
        return (
          <p style ={{float: 'left'}}>
            <i
              className="pi tables-cursor-pointer pi-search"
              style={{margin:'4px 4px 0 0'}}
              onClick={this.handleFilterMode}
            />
          </p>
        )
      }
    }
  }

  renderHeader () {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          {this.renderExtendedSearch ()}
          { this.props.noSearch &&
            <span style ={{float: 'right'}}>{this.state.first + 1} - {this.state.first + this.state.dataTableValue.length} / {this.state.totalRecords}</span>
          }
          { !this.props.noSearch &&
            <p style ={{float: 'right'}}>{this.state.first + 1} - {this.state.first + this.state.dataTableValue.length} / {this.state.totalRecords}</p>
          }
        </div>
        {! this.props.noSearch && this.renderSearchBar ()}
        {this.props.noSearch && this.renderActionsBar()}
      </div>
    )
  }


  render() {
    return (
      <div>
        {this.renderDeleteConfirmation()}
        {this.renderaddItemSelector()}
        <DataTable
          ref={this.setRef}

          header={this.renderHeader()}
          autoLayout={true}
          emptyMessage='Aucune entrée trouvée.'

          value={this.state.dataTableValue}

          paginator={true}
          rowsPerPageOptions={this.state.rowPerPageOptions}
          totalRecords={this.state.totalRecords}
          className={this.props.maxRowPerPage?"table-data-no-raw-selector":""}
          alwaysShowPaginator={this.props.maxRowPerPage?false:true}
          paginatorPosition={this.props.maxRowPerPage?'top':'both'}

          first={this.state.first}
          rows={this.state.rows}

          lazy={true}
          onPage={this.handlePaginator}
          loading={this.state.isLoading}

          reorderableRows={this.props.reorderableRows}
          onRowReorder={this.handleReorderRows}

          onSort={this.handleSort}
          sortField={this.state.sort.sortField}
          sortOrder={this.state.sort.sortOrder}

          filters={this.state.filters}
          onFilter={this.handleAdvancedFilters}

          selectionMode='multiple'
          selection={this.state.selection}
          onSelectionChange={this.handleSelection}

          onRowDoubleClick={this.handleOpen}
          /* Ugly workaround to handle rowDoubleClick event in a TouchPad compatible way
          onRowSelect={this.handleSelect}
          onRowUnSelect={this.handleSelect}
          */
        >
          {
            React.Children.map(
              this.props.children,
              child => {
                // Conditional comumn display (Added props if={condtion})
                if ( child && (child.props.if === undefined || child.props.if) ) {
                  /*
                   * Setup filterElement derived from custom
                   * filterMethod and filterParams props
                   */
                  let filterElement = child.props.filterElement
                  if (child.props.filterMethod) {
                    const filterMethod = child.props.filterMethod.bind (this)
                    filterElement = filterMethod (child.props.field, child.props.filterParams)
                  }
                  const sortable = !this.props.reorderableRows && child.props.sortable
                  return React.cloneElement(
                    child,
                    {
                      sortable: sortable,
                      filter : this.state.advancedFilterMode,
                      filterElement : filterElement
                    }
                  )
                }
              }
            )
          }
        </DataTable>
      </div>
    )
  }
}
