import React from 'react';
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'
import {StaticData} from './StaticData'

export class GpecMeetingTable extends AuthenticatedTable {

  constructor(props) {
    super(props, 'gpecMeeting', '/GPEC/Entretiens', 'GpecEmployeesMeetings',
      {
        goal_list: [],
        type : StaticData.meeting_types,
      }
    )
  }

  async getExternalValues () {
    const goals = await this.getOptionList (null, 'GpecLevels')

    this.setState (
      {
        goal_list: goals.values.map (g => {return {label: g.name, value: g.id, ...g}}),
      }
    )
  }

  // Overrinding getValue to handle employeeId props
  getValues (first=0, rows=0, sort=null, filter=null, filters=null) {
    if ( this.props.employeeId ) {
      filters = this.defaultFilters (filters, {employee_id: {value : this.props.employeeId}})
    }
    return super.getValues (first, rows, sort, filter, filters)
  }


  // Overrinding handleCreate to handle employeeId employeeName props
  handleCreate () {
    super.handleCreate (this.addParameters (this.props.employeeId, this.props.employeeName))
  }



  doRender () {

    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        maxRowPerPage={3}
        noSearch={true}
        onOpen={this.handleOpen}
        //onDelete={this.handleDelete}
        onCreate={this.handleCreate}
        //onRemove={this.props.onRemove}
        //onAdd={this.props.onAdd}
        //addItemGet={this.props.addItemGet}
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column
          field="type"
          header="Entretien"
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.type}
          sortable={true}
          style={{width:'60%'}}
          body={(d,c) => LazyDataTable.listTemplate (d, c, this.state.type)}
        />
        <Column field="date"
          header="Réalisé le"
          filterMethod={LazyDataTable.dateFilterTZ}
          sortable={true}
          style={{width:'20%'}}
          body={LazyDataTable.dateTemplate}
        />
        <Column field="date_next"
          header="Prochain le"
          filterMethod={LazyDataTable.dateFilterTZ}
          sortable={true}
          style={{width:'20%'}}
          body={LazyDataTable.dateTemplate}
        />
      </LazyDataTable>
    )
  }

}

