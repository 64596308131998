import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'

import PartnerData from '../partners/PartnerData.js'
import GroupData from '../partners/GroupData.js'
import ContactData from '../partners/ContactData.js'
import ContactAdmin from '../partners/ContactAdmin.js'

import GpecEmployeeData from '../gpec/GpecEmployeeData.js'
import GpecPositionData from '../gpec/GpecPositionData.js'
import GpecPositionAdmin from '../gpec/GpecPositionAdmin.js'
import GpecTrainingData from '../gpec/GpecTrainingData.js'
import GpecTrainingAdmin from '../gpec/GpecTrainingAdmin.js'
import GpecLevelData from '../gpec/GpecLevelData.js'
import GpecLevelAdmin from '../gpec/GpecLevelAdmin.js'
import GpecDegreeData from '../gpec/GpecDegreeData.js'
import GpecDegreeAdmin from '../gpec/GpecDegreeAdmin.js'
import GpecActionData from '../gpec/GpecActionData.js'
import GpecActionAdmin from '../gpec/GpecActionAdmin.js'
import GpecAssistanceData from '../gpec/GpecAssistanceData.js'
import GpecAssistanceAdmin from '../gpec/GpecAssistanceAdmin.js'


export class Data extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>

        <Route exact path='/Données/Partenaires' component={PartnerData} />
        <Route path='/Données/Contacts/:id' component={ContactAdmin} />
        <Route exact path='/Données/Groupes' component={GroupData} />
        <Route exact path='/Données/Contacts' component={ContactData} />

        <Route exact path='/Données/Employés' component={GpecEmployeeData} />
        <Route exact path='/Données/Postes' component={GpecPositionData} />
        <Route path='/Données/Postes/:id' component={GpecPositionAdmin} />
        <Route exact path='/Données/Formations' component={GpecTrainingData} />
        <Route path='/Données/Formations/:id' component={GpecTrainingAdmin} />
        <Route exact path='/Données/Niveaux' component={GpecLevelData} />
        <Route path='/Données/Niveaux/:id' component={GpecLevelAdmin} />
        <Route exact path='/Données/Diplômes' component={GpecDegreeData} />
        <Route path='/Données/Diplômes/:id' component={GpecDegreeAdmin} />
        <Route exact path='/Données/Actions' component={GpecActionData} />
        <Route path='/Données/Actions/:id' component={GpecActionAdmin} />
        <Route exact path='/Données/Accompagnements' component={GpecAssistanceData} />
        <Route path='/Données/Accompagnements/:id' component={GpecAssistanceAdmin} />

      </Switch>
    )
  }
}
