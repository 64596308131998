import React, {Component} from 'react'


/*
 * Main conditional object display component.
 *
 *  usage is
 *    import {Display} from Display
 *    ...
 *    <Display if={this.state.variable === true}>
 *      <Component> ... </Component>
 *    </Display>
 *
 *  if this.props.if is true, it renders child
 *  if not, it returns null
 */

export class Display extends Component {

  render() {
    if ( this.props.if ) {
      return (this.props.children)
    } else {
      return null
    }
  }
}


/*
 * Specific component optionalisation exemple :
 *
 * TabPanel conditional display overriding TabView component
 *
 * This require to first import original component :
 *  import {TabView,TabPanel} from 'primereact/tabview'
 *
 * Then create a container coponent filtering the container children
 *  class DisplayTabView extends Component ....
 *
 *  Finaly re-export container and child coponents
 *   export { DisplayTabView as TabView, TabPanel }
 *
 *  usage is
 *  <TabView>
 *    <TabPanel ... if={condition}
 *  </TabView>
 *
 *  if condition is true or absent, ths TabPanel is displayed
 *  if condtion is false it is not displayed

  import {TabView,TabPanel} from 'primereact/tabview'
  class DisplayTabView extends Component {
    render() {
      return (
        <TabView {...this.props}>
          {
            React.Children.map (
              this.props.children,
              child => {
                if (child.props.if === undefined || child.props.if) {
                  return child
                }
              }
            )
          }
        </TabView>
      )
    }
  }
  export { DisplayTabView as TabView, TabPanel }
 */

/*
 * More generic implementation via surrounding the main container with
 * the Optional componenet
 *
 * Usage is (exemple uses TabView, TabPanel again)
 *   import {Optional} from Display
 *   ...
 *   <Optional>
 *     <TabView>
 *         <TabPanel ... if={condition}
 *     </TabView>
 *   </Optional>
 *
 *  if condition is true or absent, ths TabPanel is displayed
 *  if condtion is false it is not displayed
 */

export class Optional extends Component {
  render () {
    const container =  React.Children.only(this.props.children)
    const children = React.Children.map (
      container.props.children,
      child => {
        if (child.props.if === undefined || child.props.if) {
          return child
        }
      }
    )
    return React.cloneElement( container, container.props, [...children])
  }
}

