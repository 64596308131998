import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import PartnerList from './PartnerList.js'
import PartnerForm from './PartnerForm.js'
import GroupList from './GroupList.js'
import GroupForm from './GroupForm.js'
import ContactList from './ContactList.js'
import ContactForm from './ContactForm.js'


export class Partners extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Partenaires' component={PartnerList} />
        <Route path='/Partenaires/:id' component={PartnerForm} />
        <Route exact path='/Groupes' component={GroupList} />
        <Route path='/Groupes/:id' component={GroupForm} />
        <Route exact path='/Contacts' component={ContactList} />
        <Route path='/Contacts/:id/:partner_id?' component={ContactForm} />
      </Switch>
    )
  }
}
