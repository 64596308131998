import {AuthenticatedComponent} from './AuthenticatedComponent'
//import {LazyDataTable} from './LazyDataTable'

/*
 * Generic Table component
 */


export class AuthenticatedTable extends AuthenticatedComponent {

  /*
   * Constructor profile's default values
   *  objectName = The object state name eg: company
   *
   *  pathName = The URI path to the object eg: Compagnies
   *  ** This is not the container object but the children
   *  ** bobjects that would be passed to handlers loke
   *  ** handleOpen, handleCreate etc ...
   *
   *  serviceName = The Api service name eg: Companies
   *  state = The specific object state to be added to generic one
   */
  constructor(props, objectName, pathName, serviceName, state = {}) {

    super(props, objectName, pathName, serviceName)

    this.state = {
      ...this.state,              // First, set parent state
      ...state,                   // Last add child values (may be overwriting ours)
    }

    /*
     * Tables methods
     */
    this.getValues = this.getValues.bind(this)
    this.getAdminValues = this.getAdminValues.bind(this)
    this.getOptionList = this.getOptionList.bind(this)
    this.getAdminOptionList = this.getAdminOptionList.bind(this)
    this.getExportValues = this.getExportValues.bind (this)

    this.handleOpen = this.handleOpen.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleRestore = this.handleRestore.bind(this)
    this.handleReorderRows = this.handleReorderRows.bind(this)
    this.handleQueryBuilder = this.handleQueryBuilder.bind(this)

    /*
     * Contextual data management methods
     */
    this.setRef = this.setRef.bind(this)
    this.getContext = this.getContext.bind(this)
    this.setContext = this.setContext.bind(this)

  }

  /*
   * Tables methods
   */

  addParameters (...args) {
    let parameters = ''
    for (let i=0; i<args.length; i++) {
      if ( args[i] !== undefined ) {
        parameters += '/' + args[i]
      }
    }
    return parameters
  }


  handleOpen (event, browser=null, parameters='') {
    this.context.pushRoute (this.pathName + event.data.id + parameters, this.getContext(), {browser: browser})
  }

  handleCreate (parameters='') {
    this.context.pushRoute (this.pathName + '0'  + parameters, this.getContext())
  }

  handleDelete (selection) {
    return this.apiCall (this.serviceName, 'delete', selection)
  }

  handleReorderRows (fromId, toId) {
    return this.apiCall (this.serviceName, 'reorder', fromId, toId)
  }

  handleRestore (selection) {
    return this.apiCall (this.serviceName, 'restore', selection)
  }

  setCustomFilters (filters) {
    // PlaceHolder
    return filters
  }

  getOptionList (filters=null, service=null, deleted=false) {
    return this.apiCall (service ?? this.serviceName, 'list', 0, Number.MAX_SAFE_INTEGER, null, null, filters, deleted)
  }

  getAdminOptionList (filters=null, service=null) {
    return this.getOptionList (filters, service, true)
  }

  async getValues (first=0, rows=0, sort=null, filter=null, filters=null, deleted=false, query='list') {

    // Set custom filters
    filters = this.setCustomFilters (filters)

    // Set default empty values
    let values = this.defaultValues (first, rows, sort, filter, filters, deleted)

    // Do not call server if any profile is setup yet
    if ( this.state.profile.id ) {
      values = await this.apiCall (this.serviceName, query, first, rows, sort, filter, filters, deleted)
    }
    return values
  }

  getAdminValues (first=0, rows=0, sort=null, filter=null, filters=null) {
    return this.getValues (first, rows, sort, filter, filters, true, 'adminList' )
  }

  async getExportValues (...args) {
    const data = await this.apiCall (this.serviceName, 'export', 0, Number.MAX_SAFE_INTEGER, ...args)
    return data.values
  }

  handleQueryBuilder () {
    const path = this.context.config.queryBuilder.path
    this.context.pushRoute (path + this.serviceName, this.getContext())
  }



  /*
   * Contextual data management methods places holder
   */

  setRef (ref) {
    if ( this.dataTable === undefined ) {
      this.dataTable = ref
    }
  }

  getContext () {
    let context = {}
    if ( this.dataTable !== undefined && this.objectName !== undefined) {
      const objectTable = this.objectName + 'Table'
      context = { [objectTable]: this.dataTable.getContext () }
    }
    if ( this.props.getContext ) {
      const propsContext = this.props.getContext()
      context = { ...context, ...propsContext }
    }
    return context
  }

  async setContext (context) {
    if ( this.dataTable !== undefined && this.objectName !== undefined) {
      const objectTable = this.objectName + 'Table'
      return this.dataTable.setContext (context[objectTable])
    }
  }

}
