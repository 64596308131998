import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import '../../components/Forms.css'

class GpecPositionAdmin extends AuthenticatedForm {

  constructor (props) {
    super (props, 'GpecPositionAdmin', '/Données/Postes', 'GpecPositions',

      {
        GpecPositionAdmin: {
          id: props.match.params.id,
          name: "",
          comment: "",
        },

        mandatoryFields: {
          name: {test: FormContainer.checkNotEmpty, state: false},
        },
      }
    )
  }


  doRender () {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Fiche poste</h1>
                  <FormContainer
                    values={this.state.GpecPositionAdmin}
                    mandatoryFields={this.state.mandatoryFields}
                    onEdit={this.handleEdit}
                    onUpdate={this.handleUpdate}
                    onExit={this.handleExit}
                    setValues={this.setValues}
                    getValues={this.getValues}
                    browser={this.state.browser}
                    onBrowse={this.handleBrowse}
                  >

                  <div className="form-new-line p-grid">

                    <div className="p-grid p-col-12 p-md-6">
                      <div className="p-col-12">
                        <MandatoryLabel htmlFor="name" isMissing={this.state.mandatoryFields.name.state}>Désignation</MandatoryLabel>
                      </div>
                      <div className="p-col-12">
                        <InputText
                          className="form-input"
                          autoComplete="off"
                          disabled={!this.state.editMode}
                          id="name"
                          value={this.state.GpecPositionAdmin.name}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                    <div className="p-grid p-col-12">
                      <div className="p-col-12">
                        <label htmlFor="comment">Commentaire</label>
                      </div>
                      <div className="p-col-12">
                        <InputTextarea
                          className="form-input"
                          autoComplete="off"
                          disabled={!this.state.editMode}
                          id="comment"
                          value={this.state.GpecPositionAdmin.comment}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                  </div>

                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GpecPositionAdmin
