import React from 'react'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'

export class AppFooter extends AuthenticatedComponent {

  doRender() {
    return  (
      <div className="layout-footer">
        <span className="footer-text" style={{'marginRight': '5px'}}>Captivante Vision du Système d'Information !</span>
      </div>
    )
  }

}
