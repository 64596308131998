import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {AutoComplete} from 'primereact/autocomplete'
import {Dropdown} from 'primereact/dropdown'
import '../../components/Forms.css'
import {StaticData} from './StaticData'

class ContactForm extends AuthenticatedForm {

  constructor (props) {
    super (props, 'contact', '/Contacts', 'Contacts',

      {

        activeIndex: 0,

        contact: {
          id: props.match.params.id,
          partner_id: (props.match.params.partner_id ? props.match.params.partner_id : ""),
          partner: "",
          type: "",
          delivery: "",
          title: "",
          name: "",
          function: "",
          phone: "",
          mobile: "",
          fax: "",
          email: "",
          address: "",
          zip: "",
          city: "",
          country: "France",
          country_id: "74" ,
        },

        autoCompleteFields: {
          partner: {
            service: 'Partners',
            suggestions: null,
            data: null,
          }
        },

        mandatoryFields: {
          partner: {test: FormContainer.checkNotEmpty, state: false},
          type: {test: FormContainer.checkNotEmpty, state: false},
          name: {test: FormContainer.checkNotEmpty, state: false},
          phone: {test: t =>{return FormContainer.checkFrenchPhoneNumber (t, true)}, state: false},
          mobile: {test: t =>{return FormContainer.checkFrenchPhoneNumber (t, true)}, state: false},
          fax: {test: t =>{return FormContainer.checkFrenchPhoneNumber (t, true)}, state: false},
          email: {test: t =>{return FormContainer.checkEmail (t, true)}, state: false},
        },

        country_list : [],
        type : StaticData.contact_types,
        title : StaticData.contact_titles,
      }
    )

  }


  /*
   * Get external values :
   *  - Countries list
   *  - Optional partner is set
   */

  async getExternalValues () {
    const countries = await this.apiCall ('Countries', 'list')
    await this.setState ({country_list: countries.values})

    if ( this.state.contact.partner_id && ! this.state.contact.partner ) {
      const partner = await this.apiCall ('Partners', 'get', this.state.contact.partner_id)
      this.setState (
        prevState => {
          prevState.contact.partner = partner.name
          return {
            contact : prevState.contact
          }
        }
      )
    }
  }



  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Fiche contact</h1>
                  <FormContainer
                    values={this.state.contact}
                    mandatoryFields={this.state.mandatoryFields}
                    onEdit={this.handleEdit}
                    onUpdate={this.handleUpdate}
                    onExit={this.handleExit}
                    setValues={this.setValues}
                    getValues={this.getValues}
                    browser={this.state.browser}
                    onBrowse={this.handleBrowse}
                  >

                  <div className="form-new-line p-grid">


                    <div className="p-grid p-col-12">

                      <div className="p-grid p-col-12 p-md-6">
                        <div className="p-col-12">
                          <MandatoryLabel htmlFor="partner" isMissing={this.state.mandatoryFields.partner.state}>Partenaire</MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <AutoComplete
                            className="form-input"
                            placeholder="Entrez quelques lettre du nom partenaire que vous cherchez ..."
                            autoComplete="off"
                            disabled={!this.state.editMode || this.props.match.params.partner_id !== undefined}
                            inputId="partner"
                            id="partner"
                            value={this.state.contact.partner}
                            onChange={this.handleInputText}
                            suggestions={this.state.autoCompleteFields.partner.suggestions}
                            completeMethod={FormContainer.getSuggestions.bind (this, 'partner')}
                            onFocus={this.saveAutoComplete}
                            onBlur={this.cleanUpAutoComplete}
                            onSelect={this.cleanUpAutoComplete}
                          />
                        </div>
                      </div>

                      <div className="p-grid p-col-12 p-md-6">
                        <div className="p-col-12">
                          <MandatoryLabel htmlFor="type" isMissing={this.state.mandatoryFields.type.state}>Type d'adresse</MandatoryLabel>
                        </div>
                        <div className="p-col-4">
                          <Dropdown
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="type"
                            options={this.state.type}
                            value={this.state.contact.type}
                            onChange={this.handleSelect}
                            autoWidth={false}
                          />
                        </div>
                      </div>

                    </div>


                    <div className="p-grid p-col-12 p-justify-between">

                      <div className="p-grid p-col-12 p-md-2">
                        <div className="p-col-12">
                          <label htmlFor="title">Civilité</label>
                        </div>
                        <div className="p-col-12">
                          <Dropdown
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="title"
                            options={this.state.title}
                            value={this.state.contact.title}
                            onChange={this.handleSelect}
                            showClear={true}
                            autoWidth={false}
                          />
                        </div>
                      </div>

                      <div className="p-grid p-col-12 p-md-6">
                        <div className="p-col-12">
                          <MandatoryLabel htmlFor="name" isMissing={this.state.mandatoryFields.name.state}>Nom</MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            autoComplete="off"
                            disabled={!this.state.editMode}
                            id="name"
                            value={this.state.contact.name}
                            onChange={this.handleInputText}
                            //onBlur={this.cleanUpLastName}
                          />
                        </div>
                      </div>

                      <div className="p-grid p-col-12 p-md-4">
                        <div className="p-col-12">
                          <label htmlFor="function">Fonction</label>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            autoComplete="off"
                            disabled={!this.state.editMode}
                            id="function"
                            value={this.state.contact.function}
                            onChange={this.handleInputText}
                            //onBlur={this.cleanUpLastName}
                          />
                        </div>
                      </div>

                    </div>


                    <div className="p-grid p-col-12 p-justify-between">

                      <div className="p-grid p-col-12 p-md-2">
                        <div className="p-col-12">
                          <MandatoryLabel htmlFor="phone" reminder="" isMissing={this.state.mandatoryFields.phone.state}>Téléphone</MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="phone"
                            value={this.state.contact.phone}
                            onChange={this.handleInputText}
                            onBlur={this.cleanUpStrongFrenchPhoneNumber}
                          />
                        </div>
                      </div>

                      <div className="p-grid p-col-12 p-md-2">
                        <div className="p-col-12">
                          <MandatoryLabel htmlFor="mobile" reminder="" isMissing={this.state.mandatoryFields.mobile.state}>Mobile</MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="mobile"
                            value={this.state.contact.mobile}
                            onChange={this.handleInputText}
                            onBlur={this.cleanUpStrongFrenchPhoneNumber}
                          />
                        </div>
                      </div>

                      <div className="p-grid p-col-12 p-md-2">
                        <div className="p-col-12">
                          <MandatoryLabel htmlFor="fax" reminder="" isMissing={this.state.mandatoryFields.fax.state}>Fax </MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="fax"
                            value={this.state.contact.fax}
                            onChange={this.handleInputText}
                            onBlur={this.cleanUpStrongFrenchPhoneNumber}
                          />
                        </div>
                      </div>

                      <div className="p-grid p-col-12 p-md-6">
                        <div className="p-col-12">
                          <MandatoryLabel htmlFor="email" reminder="" isMissing={this.state.mandatoryFields.email.state}>Courriel</MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="email"
                            value={this.state.contact.email}
                            onChange={this.handleInputText}
                            onBlur={this.cleanUpEmail}
                          />
                        </div>
                      </div>

                    </div>


                    { /* Optional delivery time field */
                    this.state.contact.type === 'delivery' &&
                    <div className="p-grid p-col-12 p-justify-between">

                      <div className="p-grid p-col-12">
                        <div className="p-col-12">
                          <label htmlFor="delivery">Horaires de livraison</label>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            rows={3}
                            //cols={80}
                            //autoResize={true}
                            disabled={!this.state.editMode}
                            id="delivery"
                            value={this.state.contact.delivery}
                            onChange={this.handleInputText}
                          />
                        </div>
                      </div>

                    </div>
                    }


                    <div className="p-grid p-col-12 p-justify-between">

                      <div className="p-grid p-col-12">
                        <div className="p-col-12">
                          <label htmlFor="address">Addresse</label>
                        </div>
                        <div className="p-col-12">
                          <InputTextarea
                            className="form-input"
                            rows={3}
                            //cols={80}
                            autoResize={true}
                            disabled={!this.state.editMode}
                            id="address"
                            value={this.state.contact.address}
                            onChange={this.handleInputText}
                          />
                        </div>
                      </div>

                    </div>


                    <div className="p-grid p-col-12 p-justify-between">

                      <div className="p-grid p-col-12 p-md-2">
                        <div className="p-col-12">
                          <label htmlFor="zip">Code postal</label>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            autoComplete="off"
                            disabled={!this.state.editMode}
                            id="zip"
                            value={this.state.contact.zip}
                            onChange={this.handleInputText}
                          />
                        </div>
                      </div>

                      <div className="p-grid p-col-12 p-md-5">
                        <div className="p-col-12">
                          <label htmlFor="city">Ville</label>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            autoComplete="off"
                            disabled={!this.state.editMode}
                            id="city"
                            value={this.state.contact.city}
                            onChange={this.handleInputText}
                          />
                        </div>
                      </div>

                      <div className="p-grid p-col-12 p-md-5">
                        <div className="p-col-12">
                          <label htmlFor="country">Pays</label>
                        </div>
                        <div className="p-col-12">
                          <Dropdown
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="country"
                            options={this.state.country_list.map (s => ({label: s.name, value: s.id}))}
                            value={this.state.contact.country_id}
                            onChange={this.handleExternalSelect}
                            autoWidth={false}
                          />
                        </div>
                      </div>

                    </div>


                  </div>

                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactForm
